import React from "react";
import { List, Card } from "antd";
import { useTranslation } from "react-i18next";
import { ListWidgetProps } from "../../../types/widgetTypes";
import NoDataComponent from "../../../components/Common/dataTable/NoData";
import { formatToFrenchDate } from "../../../utils/dateUtils";

const ListWidget: React.FC<ListWidgetProps> = ({ title, data }) => {
  const { t } = useTranslation();
  return (
    <Card className="list-widget shadow-sm border-primary">
      <h4 className="mb-3 text-primary">{t(title)}</h4>
      <div className="event-wrapper event-scroll">
        {data.length > 0 ? (
          data.map((item) => (
            <div key={item.id} className="border-start border-primary border-3 shadow-sm p-3 mb-3">
              {/* Name or Topic with Date */}
              <div className="d-flex align-items-center mb-3 pb-3 border-bottom">
                <span className="avatar p-1 me-2 bg-primary-transparent flex-shrink-0">
                  <i className="ti ti-list-details fs-20" /> {/* Icon for List Item */}
                </span>
                <div className="flex-fill">
                <h6 className="mb-1">
                  {item.name || item.topic || (
                    item.student && (item.student.name || item.student.class)
                      ? `${item.student.name || ''}${item.student.name && item.student.class ? ' - ' : ''}${item.student.class || ''}`
                      : ""
                  )}
                </h6>
                  <p className="d-flex align-items-center">
                    <i className="ti ti-calendar me-1" /> {formatToFrenchDate(item.startDate || item.dueDate || item.dateTime || item.date) }
                  </p>
                </div>
              </div>

              {/* Subject */}
              <div className="d-flex align-items-center justify-content-between">
                <p className="mb-0 text-muted">
                  <i className="ti ti-book me-1" /> {t(item.subject)}
                </p>
              </div>
            </div>
          ))
        ) : (
          <NoDataComponent/>
        )}
      </div>
    </Card>
  );
};

export default ListWidget;
