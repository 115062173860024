import { useState, useEffect } from 'react';
import { fetchRecipients } from '../../../../services/messageService';
import { Recipient } from '../../../../types/userTypes';

interface UseRecipientsReturn {
  recipients: Recipient[];
  loading: boolean;
  error: string | null;
  setQuery: (query: string) => void;
  setPage: (page: number) => void;
  setLimit: (limit: number) => void;
}

export const useRecipients = (initialQuery = '', initialPage = 1, initialLimit = 20): UseRecipientsReturn => {
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [query, setQuery] = useState<string>(initialQuery);
  const [page, setPage] = useState<number>(initialPage);
  const [limit, setLimit] = useState<number>(initialLimit);

  useEffect(() => {
    const fetchData = async () => {
      if (query.length < 3) {
        setRecipients([]); // Optionally clear recipients
        return;
    }
        
        setLoading(true);
        try {
            const response = await fetchRecipients({ query, page, limit });
            setRecipients(response.data);
        } catch (err: any) {
            console.error('Error fetching recipients:', err);
            setError(err.message || 'Failed to fetch recipients.');
        } finally {
            setLoading(false);
        }
    };

    fetchData();
  }, [query, page, limit]);

  return { recipients, loading, error, setQuery, setPage, setLimit };
};
