import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setMobileSidebar, setExpandMenu, toggleMiniSidebar } from "../../../../redux/sidebarSlice";
import { RootState } from "../../../../redux/store";
import ImageWithBasePath from "../../imageWithBasePath";
import { all_routes } from "../../../../router/all_routes";

const Logo = () => {
  const routes = all_routes;
  const dispatch = useDispatch();
  const mobileSidebar = useSelector((state: RootState) => state.sidebarSlice.mobileSidebar);

  const handleSidebarToggle = () => {
    dispatch(setMobileSidebar(!mobileSidebar)); // For mobile
    dispatch(toggleMiniSidebar()); // For desktop
  };

  const onMouseEnter = () => {
    dispatch(setExpandMenu(true));
  };

  const onMouseLeave = () => {
    dispatch(setExpandMenu(false));
  };

  return (
    <div className="header-left active" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {/* Main Logo */}
      <Link to={routes.dashboard} className="logo logo-normal">
        <ImageWithBasePath src="assets/img/logo-1.webp" alt="Logo" />
      </Link>
      <Link to={routes.dashboard} className="logo-small">
        <ImageWithBasePath src="assets/img/logo-small.webp" alt="Logo" />
      </Link>
      <Link to={routes.dashboard} className="dark-logo">
        <ImageWithBasePath src="assets/img/logo-dark.webp" alt="Logo" />
      </Link>

      {/* Sidebar Toggle (Desktop & Mobile) */}
      <Link id="toggle_btn" to="#" className="sidebar-toggle d-none d-md-block" onClick={handleSidebarToggle}>
        <i className="ti ti-menu-deep" />
      </Link>
      <Link id="mobile_btn" className="mobile_btn d-md-none" to="#sidebar" onClick={handleSidebarToggle}>
        <span className="bar-icon">
          <span />
          <span />
          <span />
        </span>
      </Link>
    </div>
  );
};

export default Logo;
