import { useTranslation } from "react-i18next";
import { Empty } from "antd";

const NoDataComponent = () =>{
    const { t } = useTranslation();
    return(
        <div className="p-3" style={{ textAlign: "center"}}>
        <Empty description={t("no_data")}/>
        </div>

    )
}

export default NoDataComponent;