import { useTranslation } from "react-i18next";
import React from "react";
import { Card } from "antd";
import { MarkDetail } from "../../../../types/markTypes";
import CustomModal from "../../../../components/Common/CustomModal";

interface Props {
  selectedMark: MarkDetail | null;
  onClose: () => void;
}

const MarkDetailsModal: React.FC<Props> = ({ selectedMark, onClose }) => {
  const { t } = useTranslation();
  if (!selectedMark) return null;

  return (
    <CustomModal title={t("evaluation_details")} isOpen={!!selectedMark} onClose={onClose}>
      <Card className="shadow-sm border-0">
        <div className="p-3">
          {[
            { label: t("exam"), value: selectedMark?.title },
            { label: t("students_mark"), value: selectedMark?.studentMark },
            { label: t("class_average"), value: selectedMark?.classAverage },
            { label: t("min_mark"), value: selectedMark?.minMark },
            { label: t("max_mark"), value: selectedMark?.maxMark },
          ].map(({ label, value }, index) => (
            <div key={index} className="d-flex justify-content-between border-bottom pb-2 mb-2">
              <strong>{label}:</strong>
              <span>{value ?? "N/A"}</span>
            </div>
          ))}
        </div>
      </Card>
    </CustomModal>
  );
};

export default MarkDetailsModal;
