import { useState, useEffect } from "react";
import { FormInstance } from "antd";
import { PlanningClassData, UISchedule} from "../../../../types/planningTypes";
import dayjs from "dayjs";

interface UsePlanningModalProps {
  initialData?: Partial<UISchedule> | null;
  classes: PlanningClassData[];
  form: FormInstance;
}

export const usePlanningModal = ({ initialData, classes, form }: UsePlanningModalProps) => {
  const [filteredSubjects, setFilteredSubjects] = useState<
    { subjectId: string; subjectName: string; teacherName: string }[]
  >([]);

  // Pre-fill form and filter subjects when modal opens or initialData changes
  useEffect(() => {
    if (initialData) {
      const selectedClass = classes.find((cls) =>
        cls.id === initialData.classId ||
        cls.classSubject.some((cs) => cs.id === initialData.classSubjectId)
      );

      const selectedClassSubject = selectedClass?.classSubject.find(
        (cs) => cs.id === initialData.classSubjectId
      );

      form.setFieldsValue({
        classId: selectedClass?.id || undefined,
        subjectId: selectedClassSubject?.subject.id || undefined,
        dayOfTheWeek: initialData.dayOfTheWeek,
        startTime: initialData.startTime ? dayjs(initialData.startTime, "HH:mm:ss") : undefined,
        endTime: initialData.endTime ? dayjs(initialData.endTime, "HH:mm:ss") : undefined,
      });

      if (selectedClass) {
        setFilteredSubjects(
          selectedClass.classSubject.map((cs) => ({
            subjectId: cs.subject.id,
            subjectName: cs.subject.name,
            teacherName: `${cs.teacher.user.firstName} ${cs.teacher.user.lastName}`,
          }))
        );
      } else {
        setFilteredSubjects([]);
      }
    } else {
      form.resetFields();
      setFilteredSubjects([]);
    }
  }, [initialData, form, classes]);

  // Handle class change and update subjects accordingly
  const handleClassChange = (classId: string) => {
    const selectedClass = classes.find((cls) => cls.id === classId);

    if (selectedClass) {
      setFilteredSubjects(
        selectedClass.classSubject.map((cs) => ({
          subjectId: cs.subject.id,
          subjectName: cs.subject.name,
          teacherName: `${cs.teacher.user.firstName} ${cs.teacher.user.lastName}`,
        }))
      );
    } else {
      setFilteredSubjects([]);
    }

    form.setFieldsValue({ subjectId: undefined }); // Clear subject when class changes
  };

  return { filteredSubjects, handleClassChange };
};
