import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Form, DatePicker, Select, Spin, Input } from "antd";
import { formatToSQLDate, convertToDayjs } from "../../../../utils/dateUtils";
import { useExamSessions } from "../hooks/usePeadgogyExamSessions";
import { useSubjects } from "../../../../components/Academic/subject/hooks/useSubjects";
import { CreateExamSessionPayload, UpdateExamSessionPayload } from "../../../../types/markTypes";
import CustomModal from "../../../../components/Common/CustomModal";
import CommonMultiSelect from "../../../../components/Common/CommonMultiSelect";

const { Option } = Select;

interface ExamSessionModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (values: CreateExamSessionPayload | (UpdateExamSessionPayload & { id: string })) => void;
  isSubmitting: boolean;
  initialValues?: Partial<CreateExamSessionPayload & { id?: string }>;
  isEdit?: boolean;
}

const ExamSessionModal: React.FC<ExamSessionModalProps> = ({
  visible,
  onClose,
  onSubmit,
  isSubmitting,
  initialValues,
  isEdit = false,
}) => {
  const [form] = Form.useForm();
  const [subjectId, setSubjectId] = useState<string | undefined>(initialValues?.subjectId ?? '');

  const { classes, fetchClassesBySubject, isClassesLoading } = useExamSessions();
  const { subjects } = useSubjects();
  const { t } = useTranslation();

  // Fetch Classes when Subject changes
  useEffect(() => {
    if (subjectId) fetchClassesBySubject(subjectId);
  }, [subjectId, fetchClassesBySubject]);

  // Update subjectId whenever initialValues change
  useEffect(() => {
    setSubjectId(initialValues?.subjectId ?? '');
  }, [initialValues]);

  // Initialize Form Values
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        ...initialValues,
        // Use dateRange as a single field
        dateRange:
          initialValues.startDate && initialValues.endDate
            ? [convertToDayjs(initialValues.startDate), convertToDayjs(initialValues.endDate)]
            : undefined,
        classIds: initialValues.classIds || [],
      });
    } else {
      form.resetFields();
    }
  }, [initialValues, form]);

  // Handle Form Submission
  const handleFinish = (values: Record<string, any>) => {
    // Use the range picker values to format startDate and endDate
    const formattedValues = {
      ...values,
      startDate: values.dateRange ? formatToSQLDate(values.dateRange[0]) : undefined,
      endDate: values.dateRange ? formatToSQLDate(values.dateRange[1]) : undefined,
    };

    if (isEdit && initialValues?.id) {
      onSubmit({ id: initialValues.id, ...formattedValues } as UpdateExamSessionPayload & { id: string });
    } else {
      onSubmit(formattedValues as CreateExamSessionPayload);
    }
    onClose();
  };

  return (
    <CustomModal
      title={isEdit ? t("edit_exam_session") : t("create_exam_session")}
      isOpen={visible}
      onClose={onClose}
      onSave={async () => {
        await form.validateFields(); // Ensures validation before submission
        form.submit();
      }}
      saveText={isEdit ? t("update") : t("create")}
      loading={isSubmitting}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        {/* Session Name */}
        <Form.Item
          name="name"
          label={t("session_name")}
          rules={[{ required: true, message: t("enter_session_name") }]}
        >
          <Input placeholder={t("enter_session_name")} />
        </Form.Item>

        {/* Subject Dropdown */}
        <Form.Item
          name="subjectId"
          label={t("subject")}
          rules={[{ required: true, message: t("select_subject") }]}
        >
          <Select placeholder={t("select_subject")} onChange={setSubjectId}>
            {subjects?.map((subject: any) => (
              <Option key={subject.id} value={subject.id}>
                {subject.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* Class Dropdown */}
        <Form.Item
          name="classIds"
          label={t("classes")}
          rules={[{ required: true, message: t("select_classes") }]}
        >
          {isClassesLoading ? (
            <Spin size="large" />
          ) : (
          <CommonMultiSelect
              value={form.getFieldValue("classIds")}
              onChange={(selected) => form.setFieldsValue({ classIds: selected })}
              placeholder={t("select_classes")}
              options={classes.map((cls) => ({
                value: cls.id,
                label: cls.name,
              }))}
            />
          )}
        </Form.Item>

        {/* Semester */}
        <Form.Item
          name="semester"
          label={t("semester")}
          rules={[{ required: true, message: t("select_semester") }]}
        >
          <Select placeholder={t("select_semester")}>
            <Option value="S1">{t("semester_1")}</Option>
            <Option value="S2">{t("semester_2")}</Option>
          </Select>
        </Form.Item>

        {/* Date Range Picker */}
        <Form.Item
          name="dateRange"
          label={t("date_range")}
          rules={[{ required: true, message: t("enter_date_range") }]}
        >
          <DatePicker.RangePicker
            format="YYYY-MM-DD"
            placeholder={[t("start_date"), t("end_date")]}
          />
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

export default ExamSessionModal;
