import { useTranslation } from "react-i18next";
import React from 'react';
import { Select, Spin } from 'antd';
import { useRecipients } from '../hooks/useRecipients';

const RecipientDropdown: React.FC<{ value?: string[]; onChange?: (value: string[]) => void }> = ({ value, onChange }) => {
    const { recipients, loading, setQuery } = useRecipients();
    const { t } = useTranslation();

    return (
      <Select
        mode="multiple"
        showSearch
        placeholder={t("search_recipients")}
        value={value} // Bind to form value
        onChange={onChange} // Update form value
        onSearch={setQuery} // Fetch recipients dynamically
        notFoundContent={loading ? <Spin size="small" /> : 'No recipients found'}
        tagRender={({ label }) => (
            <span className="badge bg-outline-primary"> {label}</span> // Styled using Bootstrap badge classes
        )}
        filterOption={false}
        style={{ width: '100%' }}
      >
        {recipients?.map((recipient) => (
          <Select.Option 
          key={recipient.id}
          value={recipient.id}
          label={recipient.name}
          >
            {recipient.name}
          </Select.Option>
        ))}
      </Select>
    );
  };
  
export default RecipientDropdown;
