import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../../redux/store";
import { setLanguage } from "../../../../redux/slicers/languageSlice";
import i18n from "../../../../config/i18n";
import ImageWithBasePath from "../../imageWithBasePath";

const LanguageDropdown = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state: RootState) => state.language.language);

  const changeLanguage = (lng: string) => {
    dispatch(setLanguage(lng));
    i18n.changeLanguage(lng);
  };

  return (
    <div className="dropdown">
      <div className="pe-1 ms-1">

      <Link
        to="#"
        className="btn btn-outline-light bg-white btn-icon d-flex align-items-center me-1 p-2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <ImageWithBasePath
          src={`assets/img/flags/${currentLanguage === "fr" ? "fr" : "us"}.png`}
          alt="Language"
          className="img-fluid rounded-pill"
        />
      </Link>
      <div className="dropdown-menu dropdown-menu-right">
        <Link
          to="#"
          className={`dropdown-item d-flex align-items-center ${currentLanguage === "en" ? "active" : ""}`}
          onClick={() => changeLanguage("en")}
        >
          <ImageWithBasePath
            className="me-2 rounded-pill"
            src="assets/img/flags/us.png"
            alt="English"
            height={22}
            width={22}
          />
          {t("english")}
        </Link>
        <Link
          to="#"
          className={`dropdown-item d-flex align-items-center ${currentLanguage === "fr" ? "active" : ""}`}
          onClick={() => changeLanguage("fr")}
        >
          <ImageWithBasePath
            className="me-2 rounded-pill"
            src="assets/img/flags/fr.png"
            alt="Français"
            height={22}
            width={22}
          />
          {t("french")}
        </Link>
      </div>
      </div>
    </div>
  );
};

export default LanguageDropdown;
