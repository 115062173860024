import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Role } from '../../config/abilityContext';

interface User {
    id: number;
    email: string;
    role: Role ; // Add role to the auth state
    roleId: string; 
}

interface AuthState {
    user: User | null;
    accessToken: string | null;
    refreshToken: string | null;
}

const initialState: AuthState = {
    user: null,
    accessToken: null,
    refreshToken: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (
            state,
            action: PayloadAction<{ user: User; accessToken: string; refreshToken: string;}>
        ) => {
            const { user, accessToken, refreshToken } = action.payload;
            state.user = user;
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
        },
        logout: (state) => {
            state.user = null;
            state.accessToken = null;
            state.refreshToken = null;
        },
        updateAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
        },
        updateRefreshToken: (state, action: PayloadAction<string>) => {
            state.refreshToken = action.payload;
        },
    },
});

export const { login, logout, updateAccessToken, updateRefreshToken } = authSlice.actions;
export default authSlice.reducer;
