import React from "react";
import { Link } from "react-router-dom";

export type Action = {
    label: string;
    icon?: string;
    actionType: "link" | "modal"; // Restrict actionType to specific literals
    to?: string; // For navigation
    dataBsToggle?: string; // For modals
    dataBsTarget?: string; // For modals
    onClick?: () => void; // Optional onClick handler
}

interface ActionsDropdownProps {
  actions: Action[];
}

const ActionsDropdown: React.FC<ActionsDropdownProps> = ({ actions }) => {
  return (
    <div className="d-flex align-items-center">
      <div className="dropdown">
        <Link
          to="#"
          className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="ti ti-dots-vertical fs-14" />
        </Link>
        <ul className="dropdown-menu dropdown-menu-right p-3">
          {actions.map((action, index) => (
            <li key={index}>
              {action.actionType === "link" ? (
                <Link
                  className="dropdown-item rounded-1"
                  to={action.to || "#"}
                  onClick={action.onClick}
                >
                  {action.icon && <i className={`${action.icon} me-2`} />}
                  {action.label}
                </Link>
              ) : (
                <Link
                  className="dropdown-item rounded-1"
                  to="#"
                  // data-bs-toggle={action.dataBsToggle}
                  // data-bs-target={action.dataBsTarget} commonted bc of delete action in exmasession PD
                  onClick={action.onClick}
                >
                  {action.icon && <i className={`${action.icon} me-2`} />}
                  {action.label}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ActionsDropdown;
