import React from "react";
import { Card, Form } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Section {
  title: string;
  content: React.ReactNode;
}

interface CommonFormLayoutProps {
  title: string;
  form: any;
  onFinish: (values: any) => void;
  sections: Section[];
  cancelRoute: string;
  submitText?: string;
  isEdit?: boolean;
}

const CommonFormLayout: React.FC<CommonFormLayoutProps> = ({
  title,
  form,
  onFinish,
  sections,
  cancelRoute,
  submitText = "save",
  isEdit = false,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="page-wrapper">
      <div className="content content-two">
        <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
          <h1 className="mb-1">{t(title)}</h1>
        </div>

        <Form form={form} layout="vertical" onFinish={onFinish}>
          {/* ✅ Render Multiple Sections */}
          {sections.map((section, index) => (
            <Card key={index} title={<h3>{t(section.title)}</h3>} className="mb-4">
              {section.content}
            </Card>
          ))}

          {/* ✅ Submit & Cancel Buttons */}
          <div className="text-end mt-3">
            <button type="button" className="btn btn-light me-3" onClick={() => navigate(cancelRoute)}>
              {t("cancel")}
            </button>
            <button type="submit" className="btn btn-primary">
              {isEdit ? t("update") : t(submitText)}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CommonFormLayout;
