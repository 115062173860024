import axiosInstance from './axiosInstance';

export const getTeachers = async () => {
  return axiosInstance.get('/teachers');
};

export const getTeacher = async (id : string) => {
  return axiosInstance.get(`/teachers/${id}`);
};

export const addTeacher = async (teacherData: { name: string; level?: string; instanceId?: number }) => {
  return axiosInstance.post('/teachers', teacherData);
};

export const updateTeacher = async (id: string, teacherData: any) => {
  return axiosInstance.put(`/teachers/${id}`, teacherData);
};

export const deleteTeacher = async (id: string) => {
  return axiosInstance.delete(`/teachers/${id}`);
};