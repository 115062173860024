import { useTranslation } from "react-i18next";
import React, { useEffect }  from "react";
import { useNavigate , Route, Routes } from "react-router";
import Swal from 'sweetalert2';
import { authRoutes, publicRoutes } from "./router.link";
import Feature from "../feature-module/feature";
import AuthFeature from "../feature-module/authFeature";
import Login from "../feature-module/auth/login/login";
import axiosInstance from "../services/axiosInstance";
import { store } from "../redux/store";
import { logout, updateAccessToken, updateRefreshToken } from "../redux/slicers/authSlice";
import { refreshAccessToken } from "../services/authService";
import NoAccess from "./NoAccess";

const ALLRoutes: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => response,
          async (error) => {
            const originalRequest = error.config;

            if (error.response?.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    const { accessToken: newAccessToken, refreshToken: newRefreshToken } = await refreshAccessToken();

                    store.dispatch(updateAccessToken(newAccessToken));
                    store.dispatch(updateRefreshToken(newRefreshToken));

                    // Retry the original request with the new token
                    originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                    return axiosInstance(originalRequest);
                } catch (refreshError) {
                    // Handle failed token refresh
                    Swal.fire({
                        icon: "warning",
                        title: t("session_expired"),
                        text: "Please log in again to continue.",
                    });
                    store.dispatch(logout());
                    navigate('/login');
                    return Promise.reject(error instanceof Error ? error : new Error(error?.message || 'An unexpected error occurred'));

                }
            }

            // Display an error message for other response errors
            Swal.fire({
                icon: "error",
                title: t("request_failed"),
                text: error.response?.data?.message || "An unexpected error occurred.",
            });

            return Promise.reject(error instanceof Error ? error : new Error(error?.message || 'An unexpected error occurred'));
          }
      );

      return () => {
          axiosInstance.interceptors.response.eject(interceptor);
      };
  }, [navigate]);
  const generateRoutes = (
routes : any) =>
  routes.map((route : any, idx : any) => {
    const { path, element, children } = route;

    return (
      <Route path={path} element={element} key={idx}>
        {children && generateRoutes(children)} {/* Recursively render child routes */}
      </Route>
    );
  });
  return (
    <Routes>
      <Route path="/"  element={<Login/>} />
      <Route path ="/no-access" element={<NoAccess/>} />
      <Route element={<Feature />}>{generateRoutes(publicRoutes)}</Route>
    <Route element={<AuthFeature />}>{generateRoutes(authRoutes)}</Route>
    </Routes>
  );
};

export default ALLRoutes;
