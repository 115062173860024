import { useTranslation } from "react-i18next";
import React from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import PlanningModal from "./PlanningModal";
import CellContent from "./CellContent";
import { Schedule, PlanningClassData } from "../../../../../types/planningTypes";
import { days, timeSlots } from "../../../../../constants/planningContants";
import NoDataComponent from "../../../../../components/Common/dataTable/NoData";
import "./WeeklyPlanner.css";

interface WeeklyPlannerProps {
  classes: PlanningClassData[];
  schedules: Schedule[];
  modalState: { visible: boolean; type: "add" | "edit"; data: Partial<Schedule> | null };
  setModalState: React.Dispatch<
    React.SetStateAction<{ visible: boolean; type: "add" | "edit"; data: Partial<Schedule> | null }>
  >;
  handleSave: (schedule: Schedule & { classId?: string; subjectId?: string }) => Promise<void>;
  handleDelete: (planningIds: string[], classId?: string) => Promise<void>;
  loading: boolean;
}

const WeeklyPlanner: React.FC<WeeklyPlannerProps> = ({
  classes,
  schedules,
  modalState,
  setModalState,
  handleSave,
  handleDelete,
  loading,
}) => {
  const { t } = useTranslation();

  const handleCellClick = (data: {
    type: "add" | "edit";
    schedule?: Schedule;
    classId: string;
    dayOfTheWeek: string;
    startTime: string;
    endTime: string;
  }) => {
    setModalState({
      visible: true,
      type: data.type,
      data: data.type === "edit" ? data.schedule || {} : data,
    });
  };

  const translatedDays = days.map((day) => ({
    ...day,
    label: t(day.label, { defaultValue: day.label }),
  }));

  // Generate table columns
  const columns: ColumnsType<any> = [
    {
      title: t("classes"),
      dataIndex: "class",
      key: "class",
      fixed: "left",
      width: 150,
      render: (text: string) => <div className="class-header">{text}</div>,
    },
    ...translatedDays.map((day) => ({
      title: day.label,
      children: timeSlots.map((slot) => ({
        title: slot.label,
        dataIndex: `${day.value}-${slot.start}`,
        key: `${day.value}-${slot.start}`,
        render: (_: any, record: any) => (
          <CellContent
            cls={record.classData}
            day={day}
            slot={slot}
            schedules={schedules}
            handleCellClick={handleCellClick}
          />
        ),
      })),
    })),
  ];

  // Generate table data source
  const dataSource = classes.map((cls) => {
    const row: any = { key: cls.id, class: cls.name, classData: cls };
    days.forEach((day) => {
      timeSlots.forEach((slot) => {
        const cellKey = `${day.value}-${slot.start}`;
        row[cellKey] = { day, slot };
      });
    });
    return row;
  });

  return (
    <div className="weekly-planner">
      {loading ? (
        <p>{t("loading")}</p>
      ) : (
        <Table
          dataSource={dataSource}
          columns={columns}
          bordered
          components={{
            header: {
              cell: (props: any) => (
                <th
                  {...props}
                  style={{
                    ...props.style,
                    borderLeft: "0.2px solid #666",
                    borderTop: "0.2px solid #666",
                    textAlign: "center",
                    borderBottom: "0.2px solid #666",
                  }}
                />
              ),
            },
            body: {
              cell: (props: any) => (
                <td
                  {...props}
                  style={{
                    ...props.style,
                    borderLeft: "0.2px solid #666",
                  }}
                />
              ),
            },
          }}
          scroll={{ x: "max-content" }}
          pagination={false}
          locale={{
            emptyText: <NoDataComponent />,
          }}
        />
      )}
      <PlanningModal
        visible={modalState.visible}
        type={modalState.type}
        initialData={modalState.data}
        onSave={handleSave}
        onClose={() => setModalState({ visible: false, type: "add", data: null })}
        onDelete={handleDelete}
        classes={classes}
        days={days}
      />
    </div>
  );
};

export default WeeklyPlanner;
