import { Schedule } from "../../../../types/planningTypes";

export const timeToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};

export const minutesToTime = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}:00`;
};

export const splitScheduleIntoChunks = (schedule: Schedule): Schedule[] => {
  const startMinutes = timeToMinutes(schedule.startTime);
  const endMinutes = timeToMinutes(schedule.endTime);

  const chunks: Schedule[] = [];
  for (let chunkStart = startMinutes; chunkStart < endMinutes; chunkStart += 60) {
    const chunkEnd = Math.min(chunkStart + 60, endMinutes);
    chunks.push({
      ...schedule,
      startTime: minutesToTime(chunkStart),
      endTime: minutesToTime(chunkEnd),
    });
  }
  return chunks;
};
