import axios from "./axiosInstance";
import { Absence, AbsenceFilters, AbsenceResponse } from "../types/absenceTypes";
import { Schedule } from "../types/planningTypes";

// Fetch the daily schedule for a teacher
export const getDailySchedule = async (
    teacherId: string
  ): Promise<{ data: Schedule[]; code: string; statusCode: number }> => {
    const response = await axios.get("/absences/daily-schedule", {
      params: { teacherId },
    });
  
    // Return the full response object
    return response.data;
  };

// Record attendance for a session
export const recordAttendance = async (
  planningId: string,
  absences: Absence[]
): Promise<number> => {
  const response = await axios.post<number>("/absences/attendance", {
    planningId,
    absences,
  });
  return response.data;
};

// Fetch absences based on filters
export const getAbsences = async (
  filters: AbsenceFilters
): Promise<AbsenceResponse> => {
  const response = await axios.get<AbsenceResponse>("/absences", { params: filters });
  return response.data;
};

// Update the status of an absence
export const updateAbsence = async (
  absenceId: string,
  updateFields: Partial<Absence>
): Promise<void> => {
  const response = await axios.patch(`/absences/${absenceId}`, updateFields);
  return response.data;
};

// Soft delete an absence
export const deleteAbsence = async (absenceId: string): Promise<void> => {
  const response = await axios.delete(`/absences/${absenceId}`);
  return response.data;
};

// Fetch absences for a specific student
export const getStudentAbsences = async (
  studentId: string,
  filters?: AbsenceFilters
): Promise<AbsenceResponse> => {
  const response = await axios.get<AbsenceResponse>(
    `/absences/student/${studentId}`,
    { params: filters }
  );
  return response.data;
};
