import React from 'react';
import { Table, TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';
import { Message, Pagination } from '../../../../types/messageTypes';
import { formatDateTime } from '../../../../utils/dateUtils';

interface MessagesTableProps {
  messages: Message[];
  pagination: Pagination;
  onPageChange: (page: number, pageSize: number) => void;
  onMessageSelect: (message: Message) => void;
}

const MessagesTable: React.FC<MessagesTableProps> = ({
  messages,
  pagination,
  onPageChange,
  onMessageSelect,
}) => {
  const { t } = useTranslation(); // Translation hook

  const columns: TableColumnsType<Message> = [
    {
      title: t('sender'), // Translated title
      dataIndex: ['sender', 'name'],
      key: 'sender',
      width: '20%',
      align: 'center',
      ellipsis: true,
    },
    {
      title: t('message_subject'),
      dataIndex: 'subject',
      key: 'subject',
      width: '50%',
      align: 'center',
      ellipsis: true,
    },
    {
      title: t('date'),
      dataIndex: 'timestamp',
      key: 'timestamp',
      width: '20%',
      align: 'center',
      ellipsis: true,
      render: (timestamp: string) => formatDateTime(timestamp),
    },
  ];

  const onRow = (record: Message) => ({
    onClick: () => onMessageSelect(record),
  });

  return (
    <div className="table-responsive">
      <Table
        columns={columns}
        dataSource={messages}
        rowKey="messageId"
        onRow={onRow}
        rowClassName={(record) => (record.read ? '' : 'fw-bold')}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          total: pagination.total,
          onChange: onPageChange,
        }}
      />
    </div>
  );
};

export default MessagesTable;
