import { useTranslation } from "react-i18next";
import React from "react";
import { Table } from "antd";
import { ClassTask } from "../../../../types/classTaskTypes";
import ActionsDropdown, { Action } from "../../../../components/Common/ActionsDropdown";
import { formatToFrenchDate } from "../../../../utils/dateUtils";

interface TaskListProps {
  tasks: ClassTask[];
  onEdit?: (taskId: string) => void;
  onDelete?: (taskId: string) => void;
  onTaskClick?: (task: ClassTask) => void;  // Added for modal support
}

const TaskList: React.FC<TaskListProps> = ({ tasks, onEdit, onDelete, onTaskClick }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("date"),
      dataIndex: "date",
      key: "date",
      render: (date: string)=> formatToFrenchDate(date)
    },
    {
      title: t("type"),
      dataIndex: "type",
      key: "type",
      render: (type: string) => t(type)
    },
    {
      title: t("class"),
      dataIndex: "className",
      key: "className",
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("topic"),
      dataIndex: "topic",
      key: "topic",
      render: (topic: string | undefined) => topic,
    },
    {
      title: t("due_date"),
      dataIndex: "dueDate",
      key: "dueDate",
      render: (dueDate: string | undefined) => formatToFrenchDate(dueDate),
    },
    ...(onEdit || onDelete
      ? [
          {
            title: t("actions"),
            key: "actions",
            render: (_: any, record: ClassTask) => {
              const actions: Action[] = [
                ...(onEdit
                  ? [
                      {
                        label: t("edit"),
                        icon: "ti ti-edit-circle",
                        actionType: "link" as const,
                        to: "#",
                        onClick: () => onEdit(record.id!),
                      },
                    ]
                  : []),
                ...(onDelete
                  ? [
                      {
                        label: t("delete"),
                        icon: "ti ti-trash-x",
                        actionType: "modal" as const,
                        dataBsToggle: "modal",
                        dataBsTarget: "#delete-modal",
                        onClick: () => onDelete(record.id!),
                      },
                    ]
                  : []),
              ];

              return <ActionsDropdown actions={actions} />;
            },
          },
        ]
      : []),
  ];

  return (
    <Table
      dataSource={tasks.map((task) => ({ ...task, key: task.id }))}
      columns={columns}
      pagination={{ pageSize: 10 }}
      onRow={(record) => ({
        onClick: () => onTaskClick?.(record), // Open modal on row click
        style: { cursor: "pointer" }, // Make it visually clear that rows are clickable
      })}
    />
  );
};

export default TaskList;
