import axios from "./axiosInstance";
import { ApiResponse } from "../types/apiTypes";
import { ExamSession, CreateExamSessionPayload, UpdateExamSessionPayload, MarkPayload, MarkResponse, StudentMarksResponse } from "../types/markTypes";

export const getExamSessions = async(): Promise<ApiResponse<ExamSession[]>> => {
  const response = await axios.get<ApiResponse<ExamSession[]>>("/exam-sessions");
  return response.data;
};

export const createExamSession = async(
  payload: CreateExamSessionPayload
): Promise<ApiResponse<ExamSession>> => {
  const response = await axios.post<ApiResponse<ExamSession>>("/exam-sessions", payload);
  return response.data;
};

export const updateExamSession = async (
  { id, ...payload }: UpdateExamSessionPayload
): Promise<ApiResponse<ExamSession>> => {
  const response = await axios.put<ApiResponse<ExamSession>>(
    `/exam-sessions/${id}`,
    payload
  );
  return response.data;
};

export const getExamSessionById = async(
  id: string
): Promise<ApiResponse<ExamSession>> => {
  const response = await axios.get<ApiResponse<ExamSession>>(`/exam-sessions/${id}`);
  return response.data;
};

export const deleteExamSession = async (id: string): Promise<ApiResponse<void>> => {
  const response = await axios.delete<ApiResponse<void>>(`/exam-sessions/${id}`);
  return response.data;
};

/**
 * Fetches exam sessions for a specific teacher
 * @param teacherId - The ID of the teacher
 * @returns Promise<ApiResponse<ExamSession[]>>
 */
export const getTeacherExamSessions = async (teacherId: string): Promise<ApiResponse<ExamSession[]>> => {
  try {
    const response = await axios.get<ApiResponse<ExamSession[]>>(
      `/exam-sessions/teacher/${teacherId}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch teacher's exam sessions");
  }
};

/**
 * Posts marks for multiple students in an exam session.
 * @param marksData - Array of marks data
 * @returns Promise resolving to API response
 */
export const creatMarks = async (marksData: MarkPayload[]): Promise<ApiResponse<MarkResponse[]>> => {
  const response = await axios.post<ApiResponse<MarkResponse[]>>(`/marks`, marksData);
  return response.data;
};

export const publishMarksByExamSession = async (examSessionId: string): Promise<void> => {
  const response = await axios.post("/marks/publish", { examSessionId });
  return response.data;
};

export const getStudentMarks = async (studentId: string): Promise<ApiResponse<StudentMarksResponse>> => {
  try {
    const response = await axios.get<ApiResponse<StudentMarksResponse>>(`/marks/student/${studentId}`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch student marks");
  }
};