import { Navigate, Route } from "react-router";
import { all_routes } from "./all_routes";
import ComingSoon from "../feature-module/pages/comingSoon";
import Login from "../feature-module/auth/login/login";
import Error404 from "../feature-module/pages/error/error-404";
import Error500 from "../feature-module/pages/error/error-500";
import UnderMaintenance from "../feature-module/pages/underMaintenance";

import StudentGrid from "../components/Peoples/Student/student-grid";
import StudentForm from "../components/Peoples/Student/StudentFrom";
import StudentList from "../components/Peoples/Student/StudentList";
import StudentLibrary from "../components/Peoples/Student/student-details/studentLibrary";
import StudentDetails from "../components/Peoples/Student/student-details/studentDetails";
import StudentResult from "../components/Peoples/Student/student-details/studentResult";
import StudentTimeTable from "../components/Peoples/Student/student-details/studentTimeTable";
import Classes from "../feature-module/academic/classes/pages/ClassComponent";
import TeacherGrid from "../components/Peoples/Teacher/teacher-grid";
import TeacherForm from "../components/Peoples/Teacher/TeacherForm";
import TeacherList from "../components/Peoples/Teacher/TeacherList";
import TeacherDetails from "../components/Peoples/Teacher/teacher-details/teacherDetails";
import TeacherLibrary from "../components/Peoples/Teacher/teacher-details/teacherLibrary";
import PlanningManager from "../feature-module/academic/planning/WeeklyPlannerPage";
import ProtectedRoute from "./ProtectedRoute";
import TeacherDetailsLayout from "../components/Peoples/Teacher/teacher-details/TeacherDetailsLayout";
import TeacherRoutine from "../components/Peoples/Teacher/teacher-details/teacherRoutine";
import SchedulePage from "../feature-module/academic/absence/pages/DailySchedulePage";
import AbsencePage from "../feature-module/academic/absence/pages/AbsencesPage";
import MyAbsencesPage from "../feature-module/academic/absence/pages/MyAbsencesPage";
import TeacherTaskPage from "../feature-module/academic/class-task/pages/TeacherTaskPage";
import StudentTaskPage from "../feature-module/academic/class-task/pages/StudentTaskPage";
import MessagesPage from "../feature-module/academic/message/Pages/MessagesPage";
import ExamSessionsPage from "../feature-module/academic/mark/pages/PedagogyExamSessionsPage";
import TeacherExamSessionList from "../feature-module/academic/mark/pages/TeacherExamsessionsPage";
import ExamSessionClassPage from "../feature-module/academic/mark/pages/ExamSessionClassPage";
import StudentMarksPage from "../feature-module/academic/mark/pages/StudentMarksPage";
import SubjectList from "../components/Academic/subject/SubjectList";
import DashboardPage from "../feature-module/dashboard/pages/DashboardPage";

const routes = all_routes;

export const publicRoutes = [
  // {
  //   path: "/",
  //   name: "Root",
  //   element: <Navigate to="/login" />,
  //   route: Route,
  // },
  {
    path: routes.messages,
    element: <MessagesPage />,
    route: Route,
  },
  // Peoples Module
  {
    path: routes.studentGrid,
    element: <StudentGrid />,
  },
  {
    path: routes.studentList,
    element: <StudentList />,
  },
  {
    path: routes.addStudent,
    element: <StudentForm />,
  },
  {
    path: routes.editStudent,
    element: <StudentForm />,
  },
  {
    path: routes.studentLibrary,
    element: <StudentLibrary />,
  },
  {
    path: routes.studentDetail,
    element: <StudentDetails />,
  },
  {
    path: routes.studentResult,
    element: <StudentResult />,
  },
  {
    path: routes.studentTimeTable,
    element: <StudentTimeTable />,
  },
  {
    path: routes.subject,
    element: (
      <ProtectedRoute
        action="manage"
        subject="Subject"
        element={<SubjectList />}
      />
    ),
    route: Route,
  },
  {
    path: routes.dashboard,
    element: (
      <ProtectedRoute
        action="read"
        subject="Dashboard"
        element={<DashboardPage />}
      />
    ),
    route: Route,
  },
  {
    path: routes.examSession,
    element: (
      <ProtectedRoute
        action="create"
        subject="ExamSession"
        element={<ExamSessionsPage />}
      />
    ),
    route: Route,
  },
  {
    path: routes.teacherExamSession,
    element: (
      <ProtectedRoute
        action="read"
        subject="ExamSession"
        element={<TeacherExamSessionList />}
      />
    ),
    route: Route,
  },
  {
    path: routes.ExamSessionClass,
    element: (
      <ProtectedRoute
        action="create"
        subject="Mark"
        element={<ExamSessionClassPage />}
      />
    ),
    route: Route,
  },
  {
    path: routes.StudentMarks,
    element: (
      <ProtectedRoute
        action="read"
        subject="Mark"
        element={<StudentMarksPage />}
      />
    ),
    route: Route,
  },
  {
    path: routes.attendanceDailySchedule,
    element: (
      <ProtectedRoute
        action="create"
        subject="Absence"
        element={<SchedulePage />}
      />
    ),
    route: Route,
  },
  {
    path: routes.absenceList,
    element: (
      <ProtectedRoute
        action="update"
        subject="Absence"
        element={<AbsencePage />}
      />
    ),
    route: Route,
  },
  {
    path: routes.absenceStudent,
    element: (
      <ProtectedRoute
        action="read"
        subject="Absence"
        element={<MyAbsencesPage />}
      />
    ),
    route: Route,
  },
  {
    path: routes.classes,
    element: <Classes />,
  },
  {
    path: routes.classTask,
    element: (
      <ProtectedRoute
        action="update"
        subject="Task"
        element={<TeacherTaskPage />}
      />
    ),
  },
  {
    path: routes.myTasks,
    element: (
      <ProtectedRoute
        action="read"
        subject="Task"
        element={<StudentTaskPage />}
      />
    ),
  },
  {
    path: routes.teacherGrid,
    element: <TeacherGrid />,
  },
  {
    path: routes.teacherList,
    element: (
      <ProtectedRoute
        action="read"
        subject="Teacher"
        element={<TeacherList />}
      />
    ),
    route: Route,
  },
  {
    path: routes.addTeacher,
    element: (
      <ProtectedRoute
        action="manage"
        subject="Teacher"
        element={<TeacherForm />}
      />
    ),
  },
  {
    path: routes.editTeacher,
    element: (
      <ProtectedRoute
        action="manage"
        subject="Teacher"
        element={<TeacherForm />}
      />
    ),
  },
  {
    path: routes.teacherDetails,
    element: <TeacherDetailsLayout />,
    children: [
      {
        path: "details",
        element: <TeacherDetails />,
      },
      {
        path: "routine",
        element: <TeacherRoutine/>,
      },
    ],
  },
  {
    path: routes.teacherLibrary,
    element: <TeacherLibrary />,
  },
  {
    path: routes.planning,
    element: (
      <ProtectedRoute
        action="manage"
        subject="Planner"
        element={<PlanningManager />}
      />
    ),
  },
];

export const authRoutes = [
  {
    path: routes.comingSoon,
    element: <ComingSoon />,
    route: Route,
  },
  {
    path: routes.login,
    element: <Login />,
    route: Route,
  },
  {
    path: routes.error404,
    element: <Error404 />,
    route: Route,
  },
  {
    path: routes.error500,
    element: <Error500 />,
    route: Route,
  },
  {
    path: routes.underMaintenance,
    element: <UnderMaintenance />,
    route: Route,
  },
];
