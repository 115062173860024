import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage
import themeSettingSlice from './themeSettingSlice';
import sidebarSlice from './sidebarSlice';
import authSlice from './slicers/authSlice';
import languageSlice from './slicers/languageSlice';

// Configure persistence specifically for the auth slice
const authPersistConfig = {
  key: 'auth',
  storage,
};

// Persist the auth slice
const persistedAuthReducer = persistReducer(authPersistConfig, authSlice);

// Configure the store with persisted and non-persisted reducers
const store = configureStore({
  reducer: {
    themeSetting: themeSettingSlice,
    sidebarSlice: sidebarSlice,
    authSlice: persistedAuthReducer, // Persist only auth slice
    language: languageSlice,
    
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'persist/PERSIST',
          'persist/REHYDRATE'
        ]
      }
    })
});

// Create a persistor for managing persistence
const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
