import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Button } from "antd";
import ExamSessionModal from "../components/ExamSessionModal";
import { useExamSessions } from "../hooks/usePeadgogyExamSessions";
import ExamSessionList from "../components/PedagogyExamSessionList";
import { CreateExamSessionPayload, ExamSession, UpdateExamSessionPayload } from "../../../../types/markTypes";
import TableFilter, { FilterField } from "../../../../components/Common/TableFilter/TableFilter";

const ExamSessionPage: React.FC = () => {
  const {
    examSessions,
    isSubmitting,
    isPublishing,
    handleCreateExamSession,
    handleUpdateExamSession,
    handleDeleteExamSession,
    handlePublishMarks,
  } = useExamSessions();

  const [modalVisible, setModalVisible] = useState(false);
  const [editingSession, setEditingSession] = useState<UpdateExamSessionPayload | null>(null);
  const { t } = useTranslation();

  // ✅ State to manage filters
  const [filters, setFilters] = useState<{ [key: string]: string | number | null }>({});

  // ✅ Define filter fields
  const filterFields: FilterField[] = [
    { key: "name", label: t("session_name"), type: "text" },
    { key: "subject", label: t("subject"), type: "text" },
    { key: "semester", label: t("semester"), type: "select", options: [
      { label: t("semester_1"), value: "S1" },
      { label: t("semester_2"), value: "S2" },
    ]},
    { key: "startDate", label: t("start_date"), type: "date" },
    { key: "endDate", label: t("end_date"), type: "date" }
  ];

  // ✅ Handle applying filters
  const handleApplyFilters = (newFilters: { [key: string]: string | number | null }) => {
    setFilters(newFilters);
  };

  // ✅ Handle resetting filters
  const handleResetFilters = () => {
    setFilters({});
  };

  const handleOpenCreate = () => {
    setEditingSession(null);
    setModalVisible(true);
  };

  const handleOpenEdit = (session: any) => {
    setEditingSession(session);
    setModalVisible(true);
  };

  const handleSubmit = (values: CreateExamSessionPayload | UpdateExamSessionPayload) => {
    if (editingSession) {
      handleUpdateExamSession(values as UpdateExamSessionPayload);
    } else {
      handleCreateExamSession(values as CreateExamSessionPayload);
    }
    setModalVisible(false);
  };

  // ✅ Filter exam sessions based on selected filters
  const filteredExamSessions = examSessions.filter((session) => {
    return Object.entries(filters).every(([key, value]) => {
      if (!value) return true;
  
      const typedKey = key as keyof ExamSession; // ✅ Explicitly cast key
  
      if (typedKey === "startDate" || typedKey === "endDate") {
        return session[typedKey] === value; // Match exact date
      }
  
      return String(session[typedKey]).toLowerCase().includes(String(value).toLowerCase());
    });
  });

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
          <h1 className="page-title mb-1">{t("exam_sessions")}</h1>
          <div className="d-flex">
            {/* ✅ Include TableFilter */}
            

            {/* ✅ Create Exam Session Button */}
            <Button 
              className="btn btn-primary d-flex align-items-center ms-3"
              type="primary" 
              onClick={handleOpenCreate}>
              <i className="ti ti-square-rounded-plus me-2" /> {t("create_new_exam_session")}
            </Button>
          </div>
        </div>
        <div className="card">
          <TableFilter 
              fields={filterFields} 
              filters={filters} 
              onApply={handleApplyFilters} 
              onReset={handleResetFilters} 
            />
          
          <div className="card-body p-0">
          <ExamSessionList 
          examSessions={filteredExamSessions} 
          onEdit={handleOpenEdit} 
          onDelete={handleDeleteExamSession}
          isPublishing={isPublishing}
          onPublishMarks={handlePublishMarks}
        />
          </div>
        </div>

        {/* ✅ Modal for Create or Edit */}
        <ExamSessionModal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          initialValues={editingSession ?? undefined}
          isEdit={!!editingSession}
        />
      </div>
    </div>
  );
};

export default ExamSessionPage;
