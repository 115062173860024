import { useTranslation } from "react-i18next";
import React from "react";

interface MessageSidebarProps {
  selectedType: "inbox" | "sent";
  onTypeChange: (type: "inbox" | "sent") => void;
}

const MessageSidebar: React.FC<MessageSidebarProps> = ({ selectedType, onTypeChange }) => {
  const { t } = useTranslation();

  return (
    <ul className="list-group">
      <li
        className={`list-group-item d-flex align-items-center ${
          selectedType === "inbox" ? "bg-primary text-white fw-bold" : "text-primary"
        }`}
        style={{ cursor: "pointer" }}
        onClick={() => onTypeChange("inbox")}
      >
        <i className="ti ti-download me-2" /> {t("inbox")}
      </li>
      <li
        className={`list-group-item d-flex align-items-center ${
          selectedType === "sent" ? "bg-primary text-white fw-bold" : "text-primary"
        }`}
        style={{ cursor: "pointer" }}
        onClick={() => onTypeChange("sent")}
      >
        <i className="ti ti-send me-2" /> {t("sent_mail")}
      </li>
    </ul>
  );
};

export default MessageSidebar;
