import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ImageWithBasePath from "../../../components/Common/imageWithBasePath";
import { loginService } from "../../../services/authService";

type PasswordField = "password";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
  });

  useEffect(() => {
    localStorage.setItem("menuOpened", "Dashboard");
  }, []);

  const togglePasswordVisibility = (field: PasswordField) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await loginService(email, password);
      if (response) {
        navigate("/dashboard"); // Adjust as needed
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-5 mx-auto">
          <form onSubmit={handleLogin}>
            <div className="d-flex flex-column vh-100">
              {/* Logo Section */}
              <div className="mx-auto mb-5 p-4 text-center">
                <ImageWithBasePath
                  src="assets/img/authentication/logo-1.webp"
                  className="w-80"
                  alt="Logo"
                />
              </div>

              {/* Login Form */}
              <div className="card">
                <div className="card-body p-4">
                  <div className="mb-4 text-center">
                    <h2 className="mb-2">{t("welcome")}</h2>
                    <p className="mb-0">{t("enter_details_to_sign_in")}</p>
                  </div>

                  {/* Email & Password Fields */}
                  <div className="mb-3">
                    <label className="form-label">{t("email_address")}</label>
                    <div className="input-icon mb-3 position-relative">
                      <span className="input-icon-addon">
                        <i className="ti ti-mail" />
                      </span>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        required
                      />
                    </div>

                    <label className="form-label">{t("password")}</label>
                    <div className="pass-group">
                      <input
                        type={passwordVisibility.password ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="pass-input form-control"
                        required
                      />
                      <span
                        className={`ti toggle-passwords ${passwordVisibility.password ? "ti-eye" : "ti-eye-off"}`}
                        onClick={() => togglePasswordVisibility("password")}
                      ></span>
                    </div>
                  </div>

                  {/* Submit Button */}
                  <div className="mb-3">
                    <button type="submit" className="btn btn-primary w-100">
                      {t("sign_in")}
                    </button>
                  </div>
                </div>
              </div>

              {/* Footer */}
              <div className="mt-auto p-4 text-center">
                <p className="mb-0">
                  {t("copyright")} © {new Date().getFullYear()} - Schoolation
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
