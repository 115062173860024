import { useTranslation } from "react-i18next";
import React from "react";
import { useAttendanceData } from "../hooks/useAttendanceData";
import DailySchedule from "../components/DailySchedule";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Common/loader";

const DailySchedulePage: React.FC = () => {
  const { t } = useTranslation();

  const teacherId = useSelector((state: any) => state.authSlice.user?.roleId);
  const { sessions, loading, refresh } = useAttendanceData(teacherId ?? "");

  return (
    <div className="page-wrapper">
      <div className="schedule-page content">
        <h1>{t("todays_schedule")}</h1>
        {loading && <Loader/>}
        {!loading && !sessions.length && (
          <p>{t("no_sessions_scheduled_for_today")}</p>
        )}
        {!loading && sessions.length > 0 && (
          <DailySchedule sessions={sessions} refreshSessions= {refresh}/>
        )}
      </div>
    </div>
  );
};

export default DailySchedulePage;
