import { useState } from "react";

export interface FilterState {
  [key: string]: string | number | null;
}

const useTableFilters = (initialFilters: FilterState = {}) => {
  const [filters, setFilters] = useState<FilterState>(initialFilters);

  // Update filter state dynamically
  const handleFilterChange = (name: string, value: string | number | null) => {
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Reset all filters
  const resetFilters = () => {
    setFilters(initialFilters);
  };

  return { filters, handleFilterChange, resetFilters, setFilters };
};

export default useTableFilters;
