import { useState, useEffect, useCallback } from "react";
import { message } from "antd";
import { StudentData } from "../../../../types/classTypes";
import { ExamSession, MarkPayload } from "../../../../types/markTypes";
import { getAssignedStudents } from "../../../../services/classService";
import { creatMarks, getExamSessionById } from "../../../../services/markService";
import { useNavigate } from "react-router";

export const useClassMarks = (classId: string, examSessionId: string) => {
  const [students, setStudents] = useState<StudentData[]>([]);
  const [marks, setMarks] = useState<Record<string, number | null>>({});
  const [examSession, setExamSession] = useState<ExamSession | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const navigate = useNavigate()
  /**
   * 🔹 Fetch students assigned to the class
   */
  const fetchStudents = useCallback(async () => {
    setLoading(true);
    try {
        const [studentsData, examSessionData] = await Promise.all([
            getAssignedStudents(classId),
            getExamSessionById(examSessionId),
          ]);
          setStudents(studentsData);
          setExamSession(examSessionData.data);
    } catch (error) {
      message.error("Failed to fetch students.");
    } finally {
      setLoading(false);
    }
  }, [classId]);

  /**
   * 🔹 Initialize marks for students on first load
   */
  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  /**
   * 🔹 Handle input change for marks
   */
  const handleMarkChange = (studentId: string, value: string) => {
    setMarks((prev) => ({
      ...prev,
      [studentId]: value ? parseFloat(value) || 0 : null,
    }));
  };

  /**
   * 🔹 Submit marks for all students
   */
  const handleSubmitMarks = async () => {
    if (!examSessionId || !classId) {
      message.error("Invalid class or exam session.");
      return;
    }

    // ✅ Filter out students without marks (optional, depends on requirement)
    const payload: MarkPayload[] = students
      .filter((student) => marks[student.id] !== undefined && marks[student.id] !== null)
      .map((student) => ({
        studentId: student.id,
        examSessionId,
        mark: marks[student.id]!,
      }));

    if (payload.length === 0) {
      message.warning("No marks entered to submit.");
      return;
    }

    setSubmitting(true);
    try {
      await creatMarks(payload);
      message.success("Marks submitted successfully.");
      navigate(-1)
    } catch (error) {
      message.error("Failed to submit marks.");
    } finally {
      setSubmitting(false);
    }
  };

  return {
    students,
    marks,
    examSession,
    loading,
    submitting,
    handleMarkChange,
    handleSubmitMarks,
  };
};
