import axiosInstance from './axiosInstance';

export const getStudents = async () => {
  return axiosInstance.get('/students');
};

export const getStudent = async (id: string) => {
  return axiosInstance.get(`/students/${id}`);
};

export const addStudent = async (studentData: { 
  firstName: string; 
  lastName: string; 
  classId?: string; 
  status?: string;
}) => {
  return axiosInstance.post('/students', studentData);
};

export const updateStudent = async (id: string, studentData: any) => {
  return axiosInstance.put(`/students/${id}`, studentData);
};

export const deleteStudent = async (studentId: string) => {
    return axiosInstance.delete(`/students/${studentId}`);
};