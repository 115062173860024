import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useMemo } from "react";
import Select from "react-select";
import { StudentData } from "../../../../types/classTypes";
import { message } from "antd";
import CustomModal from "../../../../components/Common/CustomModal"; // ✅ Import reusable modal

interface AssignStudentsModalProps {
  classId: string | null;
  isOpen: boolean;
  allStudents: StudentData[];
  assignedStudents: StudentData[];
  onClose: () => void;
  onSave: (classId: string, selectedStudents: StudentData[]) => Promise<void>;
}

const AssignStudentsModal: React.FC<AssignStudentsModalProps> = ({
  classId,
  isOpen,
  allStudents,
  assignedStudents,
  onClose,
  onSave,
}) => {
  const [currentSelectedStudents, setCurrentSelectedStudents] = useState<StudentData[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentSelectedStudents(assignedStudents);
  }, [assignedStudents, classId]);

  // Compute available student options
  const studentOptions = useMemo(
    () =>
      allStudents
        .filter((student) => !assignedStudents.some((assigned) => assigned.id === student.id))
        .map((student) => ({
          value: student.id,
          label: `${student.user.firstName} ${student.user.lastName}`,
        })),
    [allStudents, assignedStudents]
  );

  // Convert selected students into Select options
  const selectedOptions = useMemo(
    () =>
      currentSelectedStudents.map((student) => ({
        value: student.id,
        label: `${student.user.firstName} ${student.user.lastName}`,
      })),
    [currentSelectedStudents]
  );

  const handleSave = async () => {
    if (!classId) {
      message.error(t("invalid_class_id_please_try_again"));
      return;
    }
    try {
      await onSave(classId, currentSelectedStudents);
      message.success(t("students_assigned_successfully"));
      onClose();
    } catch (error) {
      message.error(t("failed_to_assign_students"));
    }
  };

  return (
    <CustomModal
      title={t("assign_students_to_class")}
      isOpen={isOpen}
      onClose={onClose}
      onSave={handleSave}
      saveText={t("assign_students")}
    >
      <Select
        isMulti
        className="mb-4"
        options={studentOptions}
        value={selectedOptions}
        onChange={(options) =>
          setCurrentSelectedStudents(options.map((option) => allStudents.find((s) => s.id === option.value)!))
        }
        placeholder={t("select_students")}
      />
    </CustomModal>
  );
};

export default AssignStudentsModal;
