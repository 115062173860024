import { Select, Spin } from "antd";

const { Option } = Select;

interface CommonMultiSelectProps<T> {
  value?: T[]; // Selected values
  onChange: (value: T[]) => void; // Handle selection changes
  onSearch?: (query: string) => void; // Handle search input
  placeholder?: string; // Placeholder text
  loading?: boolean; // Show loading spinner
  options: { value: T; label: string }[]; // Data list
}

const CommonMultiSelect = <T extends string | number>({
  value,
  onChange,
  onSearch,
  placeholder = "Select items",
  loading = false,
  options,
}: CommonMultiSelectProps<T>) => {
  return (
    <Select
      mode="multiple"
      showSearch
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onSearch={onSearch}
      notFoundContent={loading ? <Spin size="small" /> : "No options found"}
      filterOption={false}
      style={{ width: "100%" }}
      tagRender={({ label }) => (
        <span className="badge bg-outline-primary">{label}</span> // ✅ Styled as Bootstrap badge
      )}
    >
      {options.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default CommonMultiSelect;
