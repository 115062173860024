import { useTranslation } from "react-i18next";
import React from "react";
import AbsenceList from "../components/AbsenceList";

const AbsencePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="page-wrapper">
        <div className="content">
        <h1 className=" mb-3">{t("absences_list")}</h1>
            <AbsenceList role="pedagogy-director" />
        </div>
    </div>
  );
};

export default AbsencePage;
