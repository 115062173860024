export const days = [
    { label: "monday", value: "0" },
    { label: "tuesday", value: "1" },
    { label: "wednesday", value: "2" },
    { label: "thursday", value: "3" },
    { label: "friday", value: "4" },
];

export const timeSlots = [
    { label: "08-10", start: "08:00:00", end: "10:00:00" },
    { label: "10-12", start: "10:00:00", end: "12:00:00" },
    { label: "14-16", start: "14:00:00", end: "16:00:00" },
    { label: "16-18", start: "16:00:00", end: "18:00:00" },
];

export const levels = [
    { value: "preschool", label: "preschool" },
    { value: "primary", label: "primary_school" },
    { value: "secondary", label: "secondary_school" },
    { value: "highschool", label: "high_school" },
];