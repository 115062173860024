// planningUtils.ts

import { PlanningClassData } from "../../../../types/planningTypes";

/**
 * Find the `classSubjectId` based on selected `classId` and `subjectId`.
 * @param classes List of planning classes.
 * @param classId Selected class ID.
 * @param subjectId Selected subject ID.
 * @returns The matching `classSubjectId` or null if not found.
 */
export const findClassSubjectId = (
  classes: PlanningClassData[],
  classId: string,
  subjectId: string
): string | null => {
  const selectedClass = classes.find((cls) => cls.id === classId);
  return selectedClass?.classSubject.find((cs) => cs.subject.id === subjectId)?.id ?? null;
};
