import { useTranslation } from "react-i18next";
import { useState, useEffect, useCallback } from "react";
import { getSubjects } from "../../../../services/subjectService";
import { Subject } from "../../../../types/subjectTypes";
import { message } from "antd";

export const useSubjects = (
  ) => {
  const { t } = useTranslation();
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchSubjects = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getSubjects();
      setSubjects(response);
    } catch (err) {
      setError(err as Error);
      message.error(t("failed_to_fetch_subjects"));
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSubjects();
  }, [fetchSubjects]);

  return { subjects, isLoading, error, fetchSubjects };
};
