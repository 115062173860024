import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { getStudents, deleteStudent } from "../../../../services/studentService";
import { message } from "antd";
import { StudentType } from "../../../../types/studentTypes";

const useStudentManagement = (
  ) => {
  const { t } = useTranslation();
  const [students, setStudents] = useState<StudentType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    setIsLoading(true);
    try {
      const response = await getStudents();
      setStudents(response.data);
    } catch (error) {
      message.error(t("failed_to_fetch_students"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteStudent = async (studentId: string) => {
    setIsDeleting(true);
    try {
      await deleteStudent(studentId);
      message.success(t("student_deleted_successfully"));
      setStudents((prevStudents) => prevStudents.filter((s) => s.id !== studentId));
    } catch (error) {
      message.error(t("failed_to_delete_student"));
    } finally {
      setIsDeleting(false);
    }
  };

  return {
    students,
    isLoading,
    isDeleting,
    handleDeleteStudent,
  };
};

export default useStudentManagement;
