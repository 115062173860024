import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { AbilityContext, createAbility } from '../config/abilityContext';
import { RootState } from '../redux/store';

interface AppWrapperProps {
    children: ReactNode;
}

const AppWrapper: React.FC<AppWrapperProps> = ({ children }) => {
  const role = useSelector((state: RootState) => state.authSlice.user?.role || 'guest');
  const ability = createAbility(role);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

export default AppWrapper;
