import React, { useMemo, useState, useEffect } from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { StudentMarksResponse, MarkDetail } from "../../../../types/markTypes";
import { getEnsuredSemesters, getAllSubjects } from "../utils/studentReportsUtils";
import SubjectsTable from "./StudentMarksTable";
import MarkDetailsModal from "./MarkDetailsModal";

interface Props {
  marksData: StudentMarksResponse;
}

const StudentReport: React.FC<Props> = ({ marksData }) => {
  const ensuredSemesters = useMemo(() => getEnsuredSemesters(marksData), [marksData]);
  const allSubjects = useMemo(() => getAllSubjects(ensuredSemesters), [ensuredSemesters]);

  const semesterKeys = ["S1", "S2"] as const;
  const [activeSemester, setActiveSemester] = useState<"S1" | "S2">("S1");
  const [selectedMark, setSelectedMark] = useState<MarkDetail | null>(null);

  const { t } = useTranslation();
  useEffect(() => {
    setActiveSemester("S1");
  }, []);

  const tableData = useMemo(() => {
    const semesterSubjects = ensuredSemesters[activeSemester]?.subjects || [];

    return allSubjects.map((sub) => {
      const subjectData = semesterSubjects.find((s) => s.id === sub.key) || null;
      const marksArray = subjectData?.marks ?? [];

      const studentAverage =
        marksArray.length > 0
          ? (marksArray.map((mark) => mark.studentMark ?? 0).reduce((a, b) => a + b, 0) / marksArray.length).toFixed(2)
          : null;

      return {
        key: sub.key,
        subject: sub.subject,
        teacher: sub.teacher,
        weight: sub.weight,
        average: studentAverage,
        marks: marksArray.length > 0 ? marksArray : null,
      };
    });
  }, [activeSemester, ensuredSemesters, allSubjects]);

  return (
    <div className="mt-4 container-fluid">
      <Tabs
        activeKey={activeSemester}
        onChange={(key) => setActiveSemester(key as "S1" | "S2")}
        type="card"
        items={semesterKeys.map((sem) => ({
          key: sem,
          label: <span className="text-primary">{t("semester")} {sem.replace("S", "")}</span>,
          children: (
            <SubjectsTable
              tableData={tableData}
              setSelectedMark={setSelectedMark}
              weightedTotalAverage={marksData.semesters[activeSemester]?.weightedTotalAverage ?? "-"}
            />
          ),
        }))}
      />
      <MarkDetailsModal selectedMark={selectedMark} onClose={() => setSelectedMark(null)} />
    </div>
  );
};

export default StudentReport;
