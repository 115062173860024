import { useState, useEffect, useCallback } from "react";
import { getAbsences, getStudentAbsences } from "../../../../services/absenceService";
import { Absence } from "../../../../types/absenceTypes";
import { message } from "antd";

interface UseAbsencesParams {
  role: "pedagogy-director" | "student" | "parent";
  studentId?: string;
  filters?: any;
}

export const useAbsences = ({ role, studentId, filters }: UseAbsencesParams) => {
  const [absences, setAbsences] = useState<Absence[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchAbsences = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      let response;

      if (role === "pedagogy-director") {
        response = await getAbsences(filters); // Fetch all absences with filters
      } else {
        response = await getStudentAbsences(studentId ?? ""); // Fetch absences for a specific student
      }

      setAbsences(response.data || []);
    } catch (err: any) {
      setError(err.message || "Failed to fetch absences.");
      message.error("Failed to fetch absences.");
    } finally {
      setLoading(false);
    }
  }, [role, studentId, filters]);

  useEffect(() => {
    fetchAbsences();
  }, [fetchAbsences]);

  return { absences, loading, error, refresh: fetchAbsences };
};
