import { useTranslation } from "react-i18next";
import React from "react";
import { Calendar } from "antd";

interface CalendarCardProps {
  onDateSelect: (date: any) => void;
}

const CalendarCard: React.FC<CalendarCardProps> = ({ onDateSelect }) => {
    const { t } = useTranslation();
  return (
    <div className="col-md-4 mb-3">
      <div className="card shadow-sm border-0">
        <div className="card-body">
          <h4 className="mb-3 text-primary">{t("select_a_date")}</h4>
          <Calendar fullscreen={false} onSelect={onDateSelect} />
        </div>
      </div>
    </div>
  );
};

export default CalendarCard;
