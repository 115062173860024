import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { all_routes } from "../../../../router/all_routes";

const QuickAddDropdown = () => {
  const { t } = useTranslation();
  const routes = all_routes;

  return (
    <div className="dropdown pe-1">
      {/* Dropdown Toggle Button */}
      <Link 
        to="#" 
        className="btn btn-outline-light bg-white btn-icon me-1" 
        data-bs-toggle="dropdown" 
        aria-expanded="false"
      >
        <i className="ti ti-square-rounded-plus" />
      </Link>

      {/* Dropdown Menu */}
      <div className="dropdown-menu dropdown-menu-end border shadow-sm dropdown-md">
        <div className="p-3 border-bottom">
          <h5>{t("add")}</h5>
        </div>
        <div className="p-3 pb-0">
          <div className="row gx-2">
            {/* Add Student */}
            <div className="col-6">
              <Link to={routes.addStudent} className="d-block bg-primary-transparent rounded p-2 text-center mb-3 class-hover">
                <div className="avatar avatar-lg mb-2">
                  <span className="d-inline-flex align-items-center justify-content-center w-100 h-100 bg-primary rounded-circle">
                    <i className="ti ti-school" />
                  </span>
                </div>
                <p className="text-dark">{t("student")}</p>
              </Link>
            </div>

            {/* Add Teacher */}
            <div className="col-6">
              <Link to={routes.addTeacher} className="d-block bg-success-transparent rounded p-2 text-center mb-3 class-hover">
                <div className="avatar avatar-lg mb-2">
                  <span className="d-inline-flex align-items-center justify-content-center w-100 h-100 bg-success rounded-circle">
                    <i className="ti ti-users" />
                  </span>
                </div>
                <p className="text-dark">{t("teacher")}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickAddDropdown;
