import { NavLink, Outlet, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { all_routes } from "../../../../router/all_routes";
import TeacherBreadcrumb from "./teacherBreadcrumb";

const TeacherDetailsLayout = () => {
  const { t } = useTranslation();
  const routes = all_routes;
  const { id } = useParams<{ id: string }>();

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          {/* Page Header */}
          <TeacherBreadcrumb />
          <div className="col-xxl-10 col-xl-8">
            <div className="row">
              <div className="col-md-12">
                {/* Tabs */}
                <ul className="nav nav-tabs nav-tabs-bottom mb-4">
                  {/* 
                  <li>
                    <NavLink
                      to={id ? `${routes.teacherDetails.replace(':id', id)}${routes.teacherDetailsTabs.details}` : '#'}
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }
                    >
                      <i className="ti ti-school me-2" /> {t("teacher_details")}
                    </NavLink>
                  </li> 
                  */}
                  <li>
                    <NavLink
                      to={id ? `${routes.teacherDetails.replace(':id', id)}${routes.teacherDetailsTabs.routine}` : '#'}
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }
                    >
                      <i className="ti ti-table-options me-2" /> {t("routine")}
                    </NavLink>
                  </li>
                </ul>
                {/* Tabs */}
              </div>
            </div>
            {/* Render Child Components */}
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherDetailsLayout;
