import { useTranslation } from "react-i18next";
import React, { useState } from 'react';
import { Form, Input, Button, notification, Card } from 'antd';
import { useMessages } from '../hooks/useMessages';
import RecipientDropDown from './RecipientDropDown';

const { TextArea } = Input;

interface ComposeEmailProps {
  onBack: () => void;
}

const ComposeEmail: React.FC<ComposeEmailProps> = ({ onBack }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { sendMessage } = useMessages('inbox'); // Access sendMessage from the hook
  const { t } = useTranslation();

  const handleSend = async (values: { recipientIds: [string]; subject: string; body: string }) => {
    setLoading(true);
    try {
      await sendMessage(values); // Call the sendMessage function from the hook
      notification.success({
        message: t("email_sent"),
        description: t("email_sent_success"),
      });
      form.resetFields(); // Reset the form after successful submission
    } catch (error: any) {
      notification.error({
        message: t("error_sending_email"),
        description: error.message || t("email_send_error"),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      className="card"
      title={
        <div>
          <button className="btn btn-primary" onClick={onBack}>{t("back")}</button>
        </div>
      }
    >
      <div className="card-body">
        <h4 className="mb-4">{t("compose_email")}</h4>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSend}
          className="row"
        >
          {/* Recipients */}
          <Form.Item
            name="recipientIds"
            label={t("recipients")}
            rules={[
              { required: true, message: t("select_at_least_one_recipient") },
            ]}
            className="col-md-12"
          >
            <RecipientDropDown
              value={form.getFieldValue('recipientIds')}
              onChange={(value) => form.setFieldsValue({ recipientIds: value })}
            />
          </Form.Item>

          {/* Subject */}
          <Form.Item
            name="subject"
            label={t("message_subject")}
            rules={[
              { required: true, message: t("enter_the_subject") },
            ]}
            className="col-md-12"
          >
            <Input placeholder={t("enter_the_subject")} />
          </Form.Item>

          {/* Body */}
          <Form.Item
            name="body"
            label={t("body")}
            rules={[
              { required: true, message: t("enter_your_message") },
            ]}
            className="col-md-12"
          >
            <TextArea rows={6} placeholder={t("enter_your_message")} />
          </Form.Item>

          {/* Submit Button */}
          <Form.Item className="col-md-12 text-end">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className=" btn-primary"
            >
              {t("send")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Card>
  );
};

export default ComposeEmail;
