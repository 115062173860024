import { useAbility } from "@casl/react";
import { AbilityContext } from "../../../config/abilityContext";
import Logo from "./components/Logo";
import SearchBar from "./components/SearchBar";
import LanguageDropdown from "./components/LanguageDropdown";
import NotificationDropdown from "./components/NotificationDropdown";
import FullscreenToggle from "./components/FullscreenToggle";
import UserDropdown from "./components/UserDropdown";
import QuickAddDropdown from "./components/QuickAddDropdown";

const Header = () => {
  const ability = useAbility(AbilityContext);

  return (
    <>
      {/* Header */}
      <div className="header">
        {/* Left Section: Logo & Sidebar Toggle */}
        <Logo />

        {/* Mobile Sidebar Toggle */}
        <div className="d-md-none">
          <Logo /> {/* Includes Sidebar Toggle inside */}
        </div>

        {/* Right Section: Icons & Dropdowns */}
        <div className="header-user">
          <div className="nav user-menu d-flex align-items-center">
            <SearchBar />
            <LanguageDropdown />
            
            {/* Restrict QuickAddDropdown to users who can "manage" "subjects" or "teachers" */}
            {ability.can("manage", "Subject") || ability.can("manage", "Teacher") ? (
              <QuickAddDropdown />
            ) : null}

            <NotificationDropdown />
            <FullscreenToggle />
            <UserDropdown />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
