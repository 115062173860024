import { useState, useEffect } from "react";
import { getSchedules } from "../../../../services/planningService";
import { formatTimeToHHmm } from "../../../../utils/dateUtils";

export interface PlannerData {
  day: string;
  startTime: string;
  endTime: string;
  subjectName?: string; // For students/parents
  teacherName?: string; // For students/parents
  className?: string;   // For teachers
}

interface UseGenericPlannerParams {
  role: string;          // e.g., "teacher", "student", "parent"
  classIds?: string[];   // For students/parents
  teacherIds?: string[]; // For teachers
}

const useGenericPlanner = ({ role, classIds, teacherIds }: UseGenericPlannerParams) => {
  const [data, setData] = useState<PlannerData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlannerData = async () => {
      try {
        setIsLoading(true);
        setError(null);

        // Fetch data from the planning service
        const schedules = await getSchedules(classIds, teacherIds);
        // Normalize the data
        const normalizedData: PlannerData[] = schedules.map((entry: any) => ({
          day: entry.dayOfTheWeek,
          startTime: formatTimeToHHmm(entry.startTime) || "",
          endTime: formatTimeToHHmm(entry.endTime) || "",
          ...(role === "student" ? {
                subjectName: 'jijijijij',
                teacherName: `${entry.classSubject?.teacher?.user?.firstName} ${entry.classSubject?.teacher?.user?.lastName}`,
              }
            : 
            role === "teacher"
            ? { className: entry.classSubject?.class?.name }
            : {}),
        }));
        setData(normalizedData);

      } catch (err: any) {
        setError(err.message || "An error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlannerData();
  }, [role, classIds, teacherIds]);

  return { data, isLoading, error };
};

export default useGenericPlanner;
