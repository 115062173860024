import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { all_routes } from "../../../router/all_routes";
import Table from "../../Common/dataTable/index";
import ActionsDropdown, { Action } from "../../Common/ActionsDropdown";
import TeacherModal from "./teacherModal";
import TableFilter, { FilterField } from "../../Common/TableFilter/TableFilter";
import { getTeachers, deleteTeacher } from "../../../services/teacherService";
import Swal from "sweetalert2";

const { confirm } = Modal;

const TeacherList = () => {
  const { t } = useTranslation();
  const routes = all_routes;
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState<{ [key: string]: string | number | null }>({});

  const fetchTeachers = async () => {
    try {
      const response = await getTeachers();
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      Swal.fire("Error", t("failed_to_load_teachers"), "error");
    }
  };

  useEffect(() => {
    fetchTeachers();
  }, []);

  // ✅ Handle Delete Confirmation
  const showDeleteConfirm = (teacherId: string) => {
    confirm({
      title: t("are_you_sure_you_want_to_delete_this_teacher?"),
      icon: <ExclamationCircleOutlined />,
      content: t("this_action_cannot_be_undone"),
      okText: t("delete"),
      okType: "danger",
      cancelText: t("cancel"),
      onOk: async () => {
        try {
          await deleteTeacher(teacherId);
          message.success(t("teacher_deleted_successfully"));
          fetchTeachers(); // Refresh list
        } catch (error) {
          message.error(t("failed_to_delete_teacher"));
        }
      },
    });
  };

  const handleFilterApply = (newFilters: { [key: string]: string | number | null }) => {
    setFilters(newFilters);
    let filtered = data;

    if (newFilters.firstName) {
      filtered = filtered.filter((teacher: any) =>
        teacher.user.firstName.toLowerCase().includes((newFilters.firstName as string).toLowerCase())
      );
    }
    if (newFilters.lastName) {
      filtered = filtered.filter((teacher: any) =>
        teacher.user.lastName.toLowerCase().includes((newFilters.lastName as string).toLowerCase())
      );
    }
    if (newFilters.email) {
      filtered = filtered.filter((teacher: any) =>
        teacher.user.email.toLowerCase().includes((newFilters.email as string).toLowerCase())
      );
    }
    if (newFilters.status) {
      filtered = filtered.filter((teacher: any) => teacher.status === newFilters.status);
    }

    setFilteredData(filtered);
  };

  const handleFilterReset = () => {
    setFilters({});
    setFilteredData(data);
  };

  const filterFields: FilterField[] = [
    { key: "firstName", label: t("first_name"), type: "text" },
    { key: "lastName", label: t("last_name"), type: "text" },
    { key: "email", label: t("email"), type: "text" },
    { 
      key: "status", 
      label: t("status"), 
      type: "select", 
      options: [
        { label: t("active"), value: "Active" },
        { label: t("inactive"), value: "Inactive" }
      ] 
    },
  ];

  const columns = [
    {
      title: t("first_name"),
      dataIndex: ["user", "firstName"],
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: t("last_name"),
      dataIndex: ["user", "lastName"],
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: t("email"),
      dataIndex: ["user", "email"],
      render: (_: any, record: any) => (
        <Link to={routes.editTeacher.replace(":id", record.id)} className="link-primary">
          {record.user.email}
        </Link>
      ),
      sorter: (a: any, b: any) => a.user.email.localeCompare(b.user.email),
    },
    {
      title: t("created_at"),
      dataIndex: "createdAt",
      render: (text: string) => new Date(text).toLocaleDateString(), 
      sorter: (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (text: string) => (
        <span className={`badge ${text === "Active" ? "badge-soft-danger" : "badge-soft-success"}`}>
          <i className="ti ti-circle-filled fs-5 me-1"></i>{text}
        </span>
      ),
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
    },
    {
      title: t("action"),
      dataIndex: "action",
      render: (_: any, record: any) => {
        const actions: Action[] = [
          {
            label: t("view_routine"),
            icon: "ti ti-menu",
            actionType: "link",
            to: `${routes.teacherDetails.replace(':id', record.id)}${routes.teacherDetailsTabs.routine}`
          },
          {
            label: t("edit"),
            icon: "ti ti-edit-circle",
            actionType: "link",
            to: routes.editTeacher.replace(':id', record.id),
          },
          {
            label: t("delete"),
            icon: "ti ti-trash-x",
            actionType: "modal",
            onClick: () => showDeleteConfirm(record.id),
          },
        ];
        return <ActionsDropdown actions={actions} />;
      }
    }
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
          <h1 className="page-title mb-1">{t("teachers_list")}</h1>
          <Link to={routes.addTeacher} className="btn btn-primary">
            <i className="ti ti-square-rounded-plus me-2" /> {t("add_teacher")}
          </Link>
        </div>

        {/* Filters */}
        <div className="card">
          <div className="card-body p-0 py-0">
            <TableFilter fields={filterFields} filters={filters} onApply={handleFilterApply} onReset={handleFilterReset} />
            <Table dataSource={filteredData} columns={columns} pagination={true}/>
          </div>
        </div>
      </div>

      {/* Modal for Teacher Details */}
      <TeacherModal />
    </div>
  );
};

export default TeacherList;
