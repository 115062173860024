import { useState, useEffect } from "react";
import {
  getClassTasks,
  deleteClassTask,
  createClassTask,
  updateClassTask,
} from "../../../../services/classTaskService";
import { ClassTask, ClassTaskFilters } from "../../../../types/classTaskTypes";
import { message } from "antd";
import { fetchClassSubjects } from "../../../../services/classSubjectService";
import { ClassForTeacher } from "../../../../types/planningTypes";

export const useTasks = (initialFilters: ClassTaskFilters) => {
  const [tasks, setTasks] = useState<ClassTask[]>([]);
  const [classes, setClasses] = useState<{ value: string; label: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [filters, setFilters] = useState<ClassTaskFilters>(initialFilters);

  // Fetch tasks based on current filters
  const fetchTasks = async (overrideFilters?: Partial<ClassTaskFilters>) => {
    setLoading(true);
    setError(null);
    try {
      const activeFilters = { ...filters, ...overrideFilters }; // Merge filters
      const response = await getClassTasks(activeFilters);
      setTasks(response.data || []);
    } catch (err) {
      console.error("Error fetching tasks:", err);
      setError("Failed to fetch tasks.");
      message.error("Failed to fetch tasks.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch tasks whenever filters change
  useEffect(() => {
    fetchTasks();
  }, [filters]);

  // Function to delete a task
  const deleteTask = async (taskId: string) => {
    try {
      await deleteClassTask(taskId);
      setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
      message.success("Task deleted successfully.");
    } catch (err) {
      message.error("Failed to delete task.");
    }
  };

  // Function to create a task
  const createTask = async (newTask: ClassTask) => {
    try {
      const createdTask = await createClassTask(newTask);
      setTasks((prevTasks) => [...prevTasks, createdTask]);
      message.success("Task created successfully.");
    } catch (err) {
      message.error("Failed to create task.");
    }
  };

  // Function to update a task
  const updateTask = async (taskId: string, updatedFields: Partial<ClassTask>) => {
    try {
      await updateClassTask(taskId, updatedFields);
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId ? { ...task, ...updatedFields } : task
        )
      );
      message.success("Task updated successfully.");
    } catch (err) {
      message.error("Failed to update task.");
    }
  };

  // Fetch classes for a teacher
  const fetchClasses = async (teacherId: string) => {
    try {
      const response = await fetchClassSubjects({ teacherIds: [teacherId] });

      if (Array.isArray(response) && response.length > 0 && "teacherId" in response[0]) {
        const typedResponse = response as ClassForTeacher[];
        const transformedClasses = typedResponse[0].classes.map((cls) => ({
          value: cls.classId,
          label: `${cls.className}`,
        }));
        setClasses(transformedClasses);
      } else {
        throw new Error("Unexpected response format.");
      }
    } catch (err) {
      console.error("Error fetching classes:", err);
      message.error("Failed to fetch classes.");
    }
  };

  return {
    tasks,
    classes,
    loading,
    error,
    filters,
    setFilters, // Allow dynamic filter updates
    fetchTasks,
    setTasks, // Expose setTasks for manual updates if necessary
    fetchClasses,
    deleteTask,
    createTask,
    updateTask,
  };
};
