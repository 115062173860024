import React from "react";
import GenericPlannerGrid from "./GenericPlannerGrid";
import "./GenericPlanner.css"
import { PlannerData } from "../../hooks/useGenericPlanner";

interface GenericPlannerProps {
  role: string;
  data: PlannerData[];
}

const GenericPlanner: React.FC<GenericPlannerProps> = ({ role, data }) => {
  return (
    <div>
      <GenericPlannerGrid role={role} data={data} />
    </div>
  );
};

export default GenericPlanner;
