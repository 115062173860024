import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { all_routes } from "../../../router/all_routes";
import Table from "../../Common/dataTable/index";
import ActionsDropdown, { Action } from "../../Common/ActionsDropdown";
import useStudentManagement from "./hooks/useStudentManagement";
import { StudentType } from "../../../types/studentTypes";
import { GENDER_OPTIONS } from "../../../constants/userConstants";
import TableFilter, { FilterField } from "../../Common/TableFilter/TableFilter";

const { confirm } = Modal;

const StudentList = () => {
  const { t } = useTranslation();
  const routes = all_routes;
  const { students, isLoading, isDeleting, handleDeleteStudent } = useStudentManagement();
  const translatedGender = GENDER_OPTIONS.map(gender => ({
    ...gender,
    label: t(gender.label) // Translate dynamically
  }));
  // ✅ State to manage applied filters
  const [filters, setFilters] = useState<{ [key: string]: string | number | null }>({});

  // ✅ Define filter fields
  const filterFields: FilterField[] = [
    { key: "firstName", label: t("first_name"), type: "text" },
    { key: "lastName", label: t("last_name"), type: "text" },
    { key: "email", label: t("email"), type: "text" },
    { key: "class", label: t("class"), type: "text" },
    { key: "gender", label: t("gender"), type: "select", options: translatedGender },
  ];

  // ✅ Function to apply filters
  const handleFilterApply = (newFilters: { [key: string]: string | number | null }) => {
    setFilters(newFilters);
  };

  // ✅ Function to reset filters
  const handleFilterReset = () => {
    setFilters({});
  };

  // ✅ Filter students based on selected filters
  const filteredStudents = students.filter((student) => {
    return Object.entries(filters).every(([key, value]) => {
      if (!value) return true; // Ignore empty filters
      if (key === "gender") return student.user.gender === value;
      if (key === "class") return student.class?.name?.toLowerCase().includes((value as string).toLowerCase());
      return (student.user as Record<string, string>)[key]?.toLowerCase().includes((value as string).toLowerCase());
    });
  });

  const showDeleteConfirm = (studentId: string) => {
    confirm({
      title: t("are_you_sure_you_want_to_delete_this_student?"),
      icon: <ExclamationCircleOutlined />,
      content: t("this_action_cannot_be_undone"),
      okText: t("delete"),
      okType: "danger",
      cancelText: t("cancel"),
      okButtonProps: { disabled: isDeleting },
      onOk: () => handleDeleteStudent(studentId),
    });
  };

  const columns = [
    {
      title: t("first_name"),
      dataIndex: ["user", "firstName"],
      sorter: (a: StudentType, b: StudentType) => a.user.firstName.localeCompare(b.user.firstName),
    },
    {
      title: t("last_name"),
      dataIndex: ["user", "lastName"],
      sorter: (a: StudentType, b: StudentType) => a.user.lastName.localeCompare(b.user.lastName),
    },
    {
      title: t("email"),
      dataIndex: ["user", "email"],
      render: (_: any, record: StudentType) => (
        <Link to={routes.editStudent.replace(":id", record.id)} className="link-primary">
          {record.user.email}
        </Link>
      ),
      sorter: (a: StudentType, b: StudentType) => a.user.email.localeCompare(b.user.email),
    },
    {
      title: t("class"),
      dataIndex: ["class", "name"],
      render: (_: string, record: StudentType) => record.class?.name || "-",
      sorter: (a: StudentType, b: StudentType) => (a.class?.name || "").localeCompare(b.class?.name || ""),
    },
    {
      title: t("gender"),
      dataIndex: "gender",
      render: (_: string, record: StudentType) => {
        return translatedGender.find(option => option.value === record.user?.gender)?.label || "-";
      },
      sorter: (a: StudentType, b: StudentType) => a.user.gender.localeCompare(b.user.gender),
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (text: string) => (
        <span className={`badge ${text === "active" ? "badge-soft-success" : "badge-soft-danger"}`}>
          <i className="ti ti-circle-filled fs-5 me-1"></i>{text}
        </span>
      ),
      sorter: (a: StudentType, b: StudentType) => a.status.localeCompare(b.status),
    },
    {
      title: t("action"),
      dataIndex: "action",
      render: (_: string, record: StudentType) => {
        const actions: Action[] = [
          {
            label: t("edit"),
            icon: "ti ti-edit-circle",
            actionType: "link",
            to: routes.editStudent.replace(":id", record.id),
          },
          {
            label: t("delete"),
            icon: "ti ti-trash-x",
            actionType: "modal",
            onClick: () => showDeleteConfirm(record.id),
          },
        ];
        return <ActionsDropdown actions={actions} />;
      },
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
          <h1 className="page-title mb-1">{t("students_list")}</h1>
          <Link to={routes.addStudent} className="btn btn-primary">
            <i className="ti ti-square-rounded-plus me-2" />
            {t("add_student")}
          </Link>
        </div>

        {/* ✅ Filter Component */}

        <div className="card">
          <div className="card-body p-0 py-0">
        <TableFilter fields={filterFields} filters={filters} onApply={handleFilterApply} onReset={handleFilterReset} />
            {isLoading ? (
              <Spin size="large" className="d-flex justify-content-center my-5" />
            ) : (
              <Table dataSource={filteredStudents} columns={columns} pagination={true} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentList;
