import React from "react";
import { Navigate } from "react-router-dom";
import { useAbility } from "../config/abilityContext";
import { Actions, Modules } from "../config/permissions";

interface ProtectedRouteProps {
  element: React.ReactElement;
  action: Actions;
  subject: Modules;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, action, subject }) => {
  const ability = useAbility();

  if (ability.can(action, subject)) {
    return element;
  }

  return <Navigate to="/no-access" />;
};

export default ProtectedRoute;
