import { useTranslation } from "react-i18next";
import React from "react";
import { Table, Typography } from "antd";
import { MarkDetail } from "../../../../types/markTypes";

const { Text } = Typography;

interface Props {
  tableData: any[];
  setSelectedMark: (mark: MarkDetail | null) => void;
  weightedTotalAverage: string | null;
}

const StudentMarksTable: React.FC<Props> = ({ tableData, setSelectedMark, weightedTotalAverage }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("disciplines"),
      dataIndex: "subject",
      key: "subject",
      width: "40%",
      render: (text: string, record: { teacher: string }) => (
        <div>
          <strong>{text}</strong>
          <br />
          <Text type="secondary" className="text-muted small">{record.teacher}</Text>
        </div>
      ),
    },
    {
      title: t("weight"),
      dataIndex: "weight",
      key: "weight",
      width: "10%",
    },
    {
      title: t("average"),
      dataIndex: "average",
      key: "average",
      width: "20%",
      render: (avg: string | null) => <strong>{avg || "-"}</strong>,
    },
    {
      title: t("evaluations"),
      dataIndex: "marks",
      key: "marks",
      width: "30%",
      render: (marks: MarkDetail[] | null) =>
        marks && marks.length > 0 ? (
          <>
            {marks.map((mark, index) => (
              <span key={index} onClick={() => setSelectedMark(mark)} className="text-primary fw-bold text-decoration-underline me-2" style={{ cursor: "pointer" }}>
                {mark.studentMark ?? "-"}
              </span>
            ))}
          </>
        ) : "-",
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
      bordered
      className="table table-striped"
      summary={() => (
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={2}>
            <strong>{t("total_average")}</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} colSpan={2} align="center">
            <strong>{weightedTotalAverage ?? "-"}</strong>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      )}
    />
  );
};

export default StudentMarksTable;
