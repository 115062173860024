import React from "react";
import { Schedule, PlanningClassData } from "../../../../../types/planningTypes";
import { timeToMinutes, minutesToTime } from "../../utils/timeUtils";

interface CellContentProps {
  cls: PlanningClassData;
  day: { label: string; value: string };
  slot: { start: string; end: string };
  schedules: Schedule[];
  handleCellClick: (data: {
    type: "add" | "edit";
    schedule?: Schedule;
    classId: string;
    dayOfTheWeek: string;
    startTime: string;
    endTime: string;
  }) => void;
}

const CellContent: React.FC<CellContentProps> = ({ cls, day, slot, schedules, handleCellClick }) => {
  const totalSlotMinutes = timeToMinutes(slot.end) - timeToMinutes(slot.start);
  const halfSlotMinutes = totalSlotMinutes / 2;

  const leftHalfStart = slot.start;
  const leftHalfEnd = minutesToTime(timeToMinutes(slot.start) + halfSlotMinutes);
  const rightHalfStart = leftHalfEnd;
  const rightHalfEnd = slot.end;

  // Helper to get subject and teacher by classSubjectId
  const getSubjectAndTeacher = (classSubjectId: string) => {
    const classSubject = cls.classSubject.find((cs) => cs.id === classSubjectId);
    if (classSubject) {
      return {
        subjectName: classSubject.subject?.name || "Unknown Subject",
        teacherName: classSubject.teacher?.user?.firstName || "Unknown Teacher",
      };
    }
    return { subjectName: "Unknown Subject", teacherName: "Unknown Teacher" };
  };

  const leftSchedule = schedules.find(
    (sched) =>
      sched.classSubjectId &&
      cls.classSubject.some((cs) => cs.id === sched.classSubjectId) &&
      sched.dayOfTheWeek === day.value &&
      sched.startTime < leftHalfEnd &&
      sched.endTime > leftHalfStart
  );

  const rightSchedule = schedules.find(
    (sched) =>
      sched.classSubjectId &&
      cls.classSubject.some((cs) => cs.id === sched.classSubjectId) &&
      sched.dayOfTheWeek === day.value &&
      sched.startTime < rightHalfEnd &&
      sched.endTime > rightHalfStart
  );

  // Handle merged schedules (spanning both halves of the slot)
  if (leftSchedule && rightSchedule && leftSchedule.id === rightSchedule.id) {
    const { subjectName, teacherName } = getSubjectAndTeacher(leftSchedule.classSubjectId);
    return (
      <div className="time-slot-container">
        <div
          className="schedule merged"
          onClick={() =>
            handleCellClick({
              type: "edit",
              schedule: leftSchedule,
              classId: cls.id,
              dayOfTheWeek: day.value,
              startTime: leftSchedule.startTime,
              endTime: leftSchedule.endTime,
            })
          }
        >
          {`${subjectName}`}
        </div>
      </div>
    );
  }

  return (
    <div className="time-slot-container">
      <div
        className={`half-slot ${leftSchedule ? "occupied" : "empty"}`}
        onClick={() =>
          handleCellClick({
            type: leftSchedule ? "edit" : "add",
            schedule: leftSchedule || undefined,
            classId: cls.id,
            dayOfTheWeek: day.value,
            startTime: leftHalfStart,
            endTime: leftHalfEnd,
          })
        }
      >
        {leftSchedule
          ? getSubjectAndTeacher(leftSchedule.classSubjectId).subjectName
          : "+"}
      </div>
      <div
        className={`half-slot ${rightSchedule ? "occupied" : "empty"}`}
        onClick={() =>
          handleCellClick({
            type: rightSchedule ? "edit" : "add",
            schedule: rightSchedule || undefined,
            classId: cls.id,
            dayOfTheWeek: day.value,
            startTime: rightHalfStart,
            endTime: rightHalfEnd,
          })
        }
      >
        {rightSchedule
          ? getSubjectAndTeacher(rightSchedule.classSubjectId).subjectName
          : "+"}
      </div>
    </div>
  );
};

export default CellContent;
