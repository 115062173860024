import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Select, DatePicker, Button, Dropdown } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { formatToSQLDate, parseSQLDate } from "../../../utils/dateUtils"; // Use utility functions

export interface FilterField {
  key: string;
  label: string;
  type: "text" | "select" | "date";
  options?: { label: string; value: string | number }[]; // For select fields
}

interface TableFilterProps {
  fields: FilterField[];
  filters: { [key: string]: string | number | null };
  onApply: (newFilters: { [key: string | number]: string | number | null }) => void;
  onReset: () => void;
}

const TableFilter: React.FC<TableFilterProps> = ({ fields, filters, onApply, onReset }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false); // Dropdown state
  const [localFilters, setLocalFilters] = useState({ ...filters });

  // ✅ Update local state when input changes
  const handleFilterChange = (key: string, value: string | number | null) => {
    setLocalFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  // ✅ Reset local filters when Reset is clicked (Dropdown remains open)
  const handleReset = () => {
    setLocalFilters({});
    setVisible(!visible);
    onReset();
  };

  // ✅ Apply local filters to global filters when Apply is clicked (Dropdown remains open)
  const handleApply = () => {
    onApply(localFilters);
    setVisible(!visible);
  };

  // ✅ Dropdown content (Filters)
  const dropdownContent = (
    <div className="filter-dropdown bg-white shadow-sm p-3" style={{ width: 320 }}>
      <h4 className="mb-3">{t("filter")}</h4>

      {fields.map((field) => (
        <div key={field.key} className="mb-3">
          <label className="form-label">{t(field.label)}</label>

          {field.type === "text" && (
            <Input
              value={localFilters[field.key] || ""}
              onChange={(e) => handleFilterChange(field.key, e.target.value)}
              placeholder={t("search_placeholder", { label: t(field.label) })}
            />
          )}

          {field.type === "select" && field.options && (
            <Select
              value={localFilters[field.key] || undefined}
              onChange={(value) => handleFilterChange(field.key, value)}
              allowClear
              style={{ width: "100%" }}
              placeholder={t("select_placeholder", { label: t(field.label) })}
              options={field.options} // Uses `options` prop instead of deprecated `Select.Option`
            />
          )}

          {field.type === "date" && (
            <DatePicker
              value={parseSQLDate(localFilters[field.key] as string)} // Ensure valid `dayjs` object
              onChange={(date) => handleFilterChange(field.key, formatToSQLDate(date))}
              placeholder={t("pick_placeholder", { label: t(field.label) })}
              style={{ width: "100%" }}
            />
          )}
        </div>
      ))}

      {/* Buttons */}
      <div className="d-flex justify-content-between mt-3">
        <Button className="btn-secondary" onClick={handleReset}>
          {t("reset")}
        </Button>
        <Button className="btn-primary" type="primary" onClick={handleApply}>
          {t("apply")}
        </Button>
      </div>
    </div>
  );

  return (
    <div className="card-header d-flex align-items-center justify-content-end">
      <Dropdown
        open={visible}
        trigger={["click"]}
        onOpenChange={setVisible}
        placement="bottomLeft"
        dropdownRender={() => dropdownContent}
      >
        <Button className="btn-primary" type="primary" icon={<FilterOutlined />} onClick={() => setVisible(!visible)}>
          {t("filter")}
        </Button>
      </Dropdown>
    </div>
  );
};

export default TableFilter;
