import { useTranslation } from "react-i18next";
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { all_routes } from '../../../../router/all_routes'

const TeacherBreadcrumb = (
  ) => {
  const { t } = useTranslation();
    const routes = all_routes;
    const { id } = useParams<{ id: string }>();
  return (
    <div className="col-md-12">
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h2 className="page-title mb-1">{t('teacher_details')}</h2>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center  flex-wrap">
              {/* <Link
                to="#"
                className="btn btn-light me-2 mb-2"
                data-bs-toggle="modal"
                data-bs-target="#login_detail"
              >
                <i className="ti ti-lock me-2" />
                Login Details
              </Link> */}
              <Link
                to={id ? `${routes.editTeacher.replace(':id', id)}` : '#'}
                className="btn btn-primary d-flex align-items-center mb-2"
              >
                <i className="ti ti-edit-circle me-2" />{t('edit_teacher')}
              </Link>
            </div>
          </div>
        </div>
  )
}

export default TeacherBreadcrumb