import { useState, useEffect } from 'react';
import { Message, MessageResponse, Pagination } from '../../../../types/messageTypes';
import { SendMessagePayload, fetchInboxMessages, fetchSentMessages, sendMessage } from '../../../../services/messageService';

type MessageType = 'inbox' | 'sent';

interface UseMessagesReturn {
  messages: Message[];
  pagination: Pagination;
  loading: boolean;
  error: string | null;
  setPage: (page: number) => void;
  setLimit: (limit: number) => void;
  sendMessage: (payload: SendMessagePayload) => Promise<void>; // Corrected type
}

export const useMessages = (type: MessageType, initialPage = 1, initialLimit = 10): UseMessagesReturn => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    total: 0,
    page: initialPage,
    limit: initialLimit,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchMessages = async (page: number, limit: number) => {
    setLoading(true);
    setError(null);

    try {
      const response: MessageResponse =
        type === 'inbox'
          ? await fetchInboxMessages({ page, limit })
          : await fetchSentMessages({ page, limit });

      setMessages(response.data.messages);
      setPagination({ ...pagination, ...response.pagination });
    } catch (err: any) {
      console.error('Failed to fetch messages:', err);
      setError(err.message || 'Failed to fetch messages.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch messages when `type`, `page`, or `limit` changes
  useEffect(() => {
    fetchMessages(pagination.page, pagination.limit);
  }, [type, pagination.page, pagination.limit]);

  const setPage = (page: number) => setPagination((prev) => ({ ...prev, page }));
  const setLimit = (limit: number) => setPagination((prev) => ({ ...prev, limit }));

  const handleSendMessage = async (payload: SendMessagePayload): Promise<void> => {
    try {
      setLoading(true);
      await sendMessage(payload); // Call the service to send the message
    } catch (err: any) {
      console.error('Error sending message:', err);
      throw new Error(err.response?.data?.message || 'Failed to send message.');
    } finally {
      setLoading(false);
    }
  };

  return {
    messages,
    pagination,
    loading,
    error,
    setPage,
    setLimit,
    sendMessage: handleSendMessage, // Return the corrected function
  };
};
