import axiosInstance from "./axiosInstance";
import { ClassForTeacher, TeachersForClass } from "../types/planningTypes";
import { ApiResponse } from "../types/apiTypes";

/**
 * Fetch class subjects based on classIds or teacherIds.
 * @param classIds - Array of class IDs to filter by (optional).
 * @param teacherIds - Array of teacher IDs to filter by (optional).
 * @returns Promise<ClassSubjectResponse>
 */
export const fetchClassSubjects = async (params: { teacherIds?: string[]; classIds?: string[] }) => {
  if (params.teacherIds) {
    const response: ApiResponse<ClassForTeacher[]> = await axiosInstance.get(`/class-subjects`, { params });
    return response.data;
  } else if (params.classIds) {
    const response: ApiResponse<TeachersForClass[]> = await axiosInstance.get(`/class-subjects`, { params });
    return response.data;
  }
};