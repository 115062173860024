import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Form, Input, Select, message } from "antd";
import { ClassData } from "../../../../types/classTypes";
import { levels } from "../../../../constants/classConstants";
import CustomModal from "../../../../components/Common/CustomModal";

interface AddEditClassModalProps {
  classData?: Partial<ClassData> | null;
  onSave: (classData: Partial<ClassData>) => Promise<void>;
  onClose: () => void;
  isOpen: boolean;
}

const AddEditClassModal: React.FC<AddEditClassModalProps> = ({ classData, onSave, onClose, isOpen }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        name: classData?.name || "",
        level: classData?.level || undefined,
      });
    }
  }, [classData, isOpen, form]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      await onSave({ ...classData, ...values });
      message.success(classData?.id ? "Class updated successfully!" : "Class added successfully!");
      onClose();
    } catch (error) {
      message.error(t("please_fill_all_required_fields_correctly"));
    } finally {
      setLoading(false);
    }
  };
  const translatedLevels = levels.map(level => ({
    ...level,
    label: t(level.label) // Translate dynamically
  }));
  return (
    <CustomModal
      title={classData?.id ? t("edit_class") : t("add_class")}
      isOpen={isOpen}
      onClose={onClose}
      onSave={handleSave}
      loading={loading}
      saveText={classData?.id ? t("save_changes") : t("add_class")}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t("class_name")}
          name="name"
          rules={[{ required: true, message: t("please_enter_a_class_name") }]}
        >
          <Input placeholder={t("enter_class_name")} />
        </Form.Item>

        <Form.Item
          label={t("class_level")}
          name="level"
          rules={[{ required: true, message:t("please_enter_select_a_class_level")}]}
        >
          <Select placeholder={t("select_a_level")}>
            {translatedLevels.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

export default AddEditClassModal;
