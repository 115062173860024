import { StudentMarksResponse, SubjectMarks, SemesterData } from "../../../../types/markTypes";

export const getEnsuredSemesters = (marksData: StudentMarksResponse): Record<"S1" | "S2" | "S0", SemesterData> => {
  return {
    S1: marksData.semesters["S1"] || { subjects: [] },
    S2: marksData.semesters["S2"] || { subjects: [] },
    S0: marksData.semesters["S0"] || { subjects: [] }, // Contains all subjects
  };
};

export const getAllSubjects = (semesters: Record<"S1" | "S2" | "S0", SemesterData>) => {
  return semesters.S0.subjects.map(({ id, name, teacher, weight }: SubjectMarks) => ({
    key: id,
    subject: name,
    teacher: teacher?.name ?? "Unknown",
    weight: weight ?? 1, // Default weight to 1 if not provided
  }));
};
