// services/axiosInstance.ts
import axios from "axios";
import {store} from "../redux/store";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Define routes to exclude from adding the Authorization header
const excludedRoutes = ["/auth/login", "/auth/register"];

// Request Interceptor to Conditionally Add Authorization Header
axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getState().authSlice.accessToken;

    // Conditionally add the token only if the request URL is not in the excluded routes
    if (token && !excludedRoutes.some(route => config.url?.includes(route))) {
      config.headers.Authorization = `Bearer ${token}`; // Set Authorization header
    }

    return config;
  },
  (error) => {
    return Promise.reject(error instanceof Error ? error : new Error(error?.message ?? 'An unexpected error occurred'));
  }
);

/* // Response Interceptor to Handle 401 and Refresh Tokens
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const {accessToken: newAccessToken, refreshToken: newRefreshToken} = await refreshAccessToken();
        store.dispatch(updateRefreshToken(newRefreshToken));
        store.dispatch(updateAccessToken(newAccessToken));
        
        // Retry the original request with the new token in the Authorization header
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        Swal.fire({
          icon: "warning",
          title: "Session Expired",
          text: "Please log in again to continue.",
        });
        // await logoutService(navigate);
        return Promise.reject(refreshError);
      }
    }

    Swal.fire({
      icon: "error",
      title: "Request Failed",
      text: error.response?.data?.message || "An unexpected error occurred.",
    });

    return Promise.reject(error);
  }
);
 */
export default axiosInstance;
