import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotificationDropdown = () => {
  const { t } = useTranslation();

  return (
    <div className="dropdown pe-1">
      <Link 
        to="#" 
        className="btn btn-outline-light bg-white btn-icon position-relative me-1" 
        id="notification_popup"
        data-bs-toggle="dropdown" 
        aria-expanded="false"
      >
        <i className="ti ti-bell" />
      </Link>

      <div className="dropdown-menu dropdown-menu-end notification-dropdown p-4">
        <div className="d-flex align-items-center justify-content-between border-bottom p-0 pb-3 mb-3">
          <h4 className="notification-title">{t("notifications")}</h4>
        </div>
        <div className="noti-content text-center py-3">
          <p className="text-muted">{t("no_notifications")}</p>
        </div>
      </div>
    </div>
  );
};

export default NotificationDropdown;
