import { useTranslation } from "react-i18next";
import React from "react";
import Table from "../../../../components/Common/dataTable/index";

import { Button, Tooltip } from "antd";
import { ExamSession, UpdateExamSessionPayload } from "../../../../types/markTypes";
import ActionsDropdown, { Action } from "../../../../components/Common/ActionsDropdown";

interface ExamSessionListProps {
  examSessions: ExamSession[];
  onEdit?: (examSession: UpdateExamSessionPayload) => void;
  onDelete?: (id: string) => void;
  onPublishMarks?: (examSessionId: string) => void; // ✅ New prop to trigger publish
  isPublishing?: boolean;
}

const getSubmissionIcon = (
status: string) => {
  switch (status) {
    case "published":
      return <i className="ti ti-lock text-success" />;
    case "pending":
      return <i className="ti ti-check text-primary" />;
    case "incomplete":
      return <i className="ti ti-alert-circle text-warning" />;
    default:
      return <i className="ti ti-x text-danger" />;
  }
};

const ExamSessionList: React.FC<ExamSessionListProps> = ({ examSessions, onEdit, onDelete, onPublishMarks, isPublishing }) => {
const { t } = useTranslation();

  const columns = [
    { title: t("session"), dataIndex: "name", key: "name" },
    { title: t("semester"), dataIndex: "semester", key: "semester" },
    { title: t("start_date"), dataIndex: "startDate", key: "startDate" },
    { title: t("end_date"), dataIndex: "endDate", key: "endDate" },
    {
      title: t("subject"),
      key: "subjectName",
      render: (_: any, record: ExamSession) =>
        record?.subject ? record?.subject.name : record?.subSubject ? record?.subSubject.name : "N/A",
    },
    {
      title: t("classes_&_status"),
      key: "classes",
      render: (_: any, record: ExamSession) => (
        <ul className="list-unstyled mb-0">
          {record?.classes?.length ? (
            record.classes.map((cls) => (
              <li key={cls.id} className="d-flex align-items-center gap-2">
                <Tooltip title={`Status: ${cls.submissionStatus}`}>
                  {getSubmissionIcon(cls.submissionStatus)}
                </Tooltip>
                <span>{cls.name}</span>
              </li>
            ))
          ) : (
            <span className="text-muted">{t("no_classes")}</span>
          )}
        </ul>
      ),
    },
    {
      title: t("publish_marks"),
      key: "publish",
      render: (_: any, record: ExamSession) => {
        const allSubmitted = record.classes?.every(cls => cls.submissionStatus === "pending");
        const allPublished = record.classes?.every(cls => cls.submissionStatus === "published");

        return (
          <Tooltip title={allPublished ? "Already Published" : allSubmitted ? "Publish Marks" : "Marks Not Ready"}>
            <Button
              type="primary"
              className="btn btn-primary d-flex align-items-center me-3"
              onClick={() => onPublishMarks?.(record.id)}
              disabled={!allSubmitted || isPublishing}
              icon={<i className="ti ti-send" />}
            >
              {allPublished ? t("published") : t("publish")}
            </Button>
          </Tooltip>
        );
      },
    },
    {
      title: t("actions"),
      key: "actions",
      render: (_: any, record: ExamSession) => {
        const actions: Action[] = [
          {
            label: t("edit"),
            icon: "ti ti-pencil",
            actionType: "link",
            onClick: () => {
              const payload: UpdateExamSessionPayload = {
                id: record.id,
                name: record.name,
                semester: record.semester,
                startDate: record.startDate,
                endDate: record.endDate,
                subjectId: record.subject?.id ?? null,
                subSubjectId: record.subSubject?.id ?? null,
                classIds: record.classes?.map(cls => cls.id) ?? [],
              };
              onEdit?.(payload);
            },
          },
          {
            label: t("delete"),
            icon: "ti ti-trash",
            actionType: "modal",
            dataBsToggle: "modal",
            dataBsTarget: "",
            onClick: () => onDelete?.(record.id),
          },
        ];

        return <ActionsDropdown actions={actions} />;
      },
    },
  ];

  return (
    <Table
      dataSource={examSessions}
      columns={columns}
      bordered={true}
    />
  );
};

export default ExamSessionList;
