import { useTranslation } from "react-i18next";
import React from "react";
import StudentReport from "../components/StudentReport";
import { useStudentMarks } from "../hooks/useStudentMarks";
import { Spin, Alert, Card } from "antd";
import { useSelector } from "react-redux";

const StudentMarks: React.FC = () => {
  const studentId = useSelector((state: any) => state.authSlice.user?.roleId);
  const { t } = useTranslation();
  const { marksData, isLoading, error } = useStudentMarks(studentId);

  return (
    <div className="page-wrapper">
      <div className="content">

      <div className="py-4">
        {/* Page Title */}
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h1 className="fw-bold">{t("my_marks")}</h1>
        </div>

        {/* Loading & Error Handling */}
        {isLoading ? (
          <div className="d-flex justify-content-center my-5">
            <Spin size="large" />
          </div>
        ) : error ? (
          <Alert message="Error fetching marks" type="error" showIcon className="mb-3" />
        ) : (
          <Card className="shadow-sm p-3">
            {marksData ? (
              <StudentReport marksData={marksData} />
            ) : (
              <Alert message="No marks available" type="warning" showIcon />
            )}
          </Card>
        )}
      </div>
      </div>
    </div>
  );
};

export default StudentMarks;
