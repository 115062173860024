import React from "react";
import { Spin, Alert, Card, Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
import RoleWidgets from "../components/RoleWidgets";
import useDashboardData from "../hooks/useDashboarData";
import { useSelector } from "react-redux";

const { Title } = Typography;

const DashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const role = useSelector((state: any) => state.authSlice.user?.role);

  // Fetch dashboard data
  const { data, isLoading, error } = useDashboardData();

  return (
    <div className="page-wrapper">
        <div className="content">
          <div className="min-vh-100">
          {/* Dashboard Title */}
          <Row justify="space-between" align="middle" className="mb-3">
            <Col>
              <h1>{t("dashboard")}</h1>
            </Col>
          </Row>

          {/* Dashboard Widgets Section */}
          <Row justify="center">
            <Col xs={24} sm={24} md={20} lg={24}>
                {isLoading && <Spin size="large" className="d-block mx-auto my-3" />}
                {error && <Alert message={t("failed_to_load_dashboard")} type="error" showIcon className="my-3" />}
                {data && <RoleWidgets role={role} data={data} />}
            </Col>
          </Row>
      </div>
    </div>
    </div>
  );
};

export default DashboardPage;
