import { useTranslation } from "react-i18next";
import React from "react";
import TableFilter, { FilterField } from "../../../../components/Common/TableFilter/TableFilter";
import i18n from "../../../../config/i18n"; // ✅ Import i18n instance

interface AbsenceFiltersProps {
  filters: { [key: string]: string | number | null };
  onApply: (newFilters: any) => void;
  onReset: () => void;
}

const absenceFilterFields: FilterField[] = [
  { key: "studentName", label: i18n.t("student_name"), type: "text" },
  { key: "class", label: i18n.t("class"), type: "text" },
  { key: "date", label: i18n.t("date"), type: "date" },
  {
    key: "status",
    label: i18n.t("status"),
    type: "select",
    options: [
      { label: i18n.t("all"), value: "" },
      { label: i18n.t("pending"), value: "pending" },
      { label: i18n.t("excused"), value: "excused" },
    ],
  },
];

const AbsenceFilters: React.FC<AbsenceFiltersProps> = ({ filters, onApply, onReset }) => (
    <TableFilter fields={absenceFilterFields} filters={filters} onApply={onApply} onReset={onReset} />
);

export default AbsenceFilters;
