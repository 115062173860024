import { all_routes } from "../../../router/all_routes";
const routes = all_routes;

export const SidebarData = [
  {
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Main",
    submenuItems: [
      {
        label: "dashboard",
        icon: "ti ti-layout-dashboard",
        submenu: false,
        showSubRoute: false,
        action: "read", 
        subject: "Dashboard",
        link: routes.dashboard,
      },
    ],
  },
  {
    label: "peoples",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Peoples",

    submenuItems: [
      {
        label: "students",
        icon: "ti ti-school",
        link: routes.studentList, 
        action: "manage", 
        subject: "Student",
        submenu: false,
        showSubRoute: false,
      },
      {
        label: "parents",
        icon: "ti ti-user-bolt",
        showSubRoute: false,
        submenu: false,
        link: routes.parentList,
        subject: "Parent",
        action: "manage"
      },
      {
        label: "Guardians",
        icon: "ti ti-user-shield",
        showSubRoute: false,
        submenu: true,
        link: routes.guardiansList,
      },
      {
        label: "teachers",
        icon: "ti ti-users",
        link: routes.teacherList,
        submenu: false,
        showSubRoute: false,
        action: "manage", 
        subject: "Teacher"
      },
    ],
  },
  {
    label: "academic",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Academic",

    submenuItems: [
      {
        label: "classes",
        icon: "ti ti-school-bell",
        action: "manage",
        subject: "Class",
        submenu: false,
        showSubRoute: false,
        link: routes.classes,
      },
      {
        label: "attendance",
        link: routes.attendanceDailySchedule,
        icon: "ti ti-calendar-share",
        action: "create", 
        subject: "Absence",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "absences",
        link: routes.absenceList,
        icon: "ti ti-calendar-share",
        action: "update", 
        subject: "Absence",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "absences",
        link: routes.absenceStudent,
        icon: "ti ti-calendar-share",
        action: "read", 
        subject: "Absence",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "subjetcs",
        link: routes.subject,
        icon: "ti ti-book",
        subject: "Subject",
        action: "manage",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "planning",
        link: routes.planning,
        icon: "ti ti-table",
        action: "manage", 
        subject: "Planner",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "home_work",
        link: routes.classTask,
        action: "update" ,
        subject: "Task",
        icon: "ti ti-license",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "homework_diary",
        link: routes.myTasks,
        action: "read" ,
        subject: "Task",
        icon: "ti ti-license",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "messaging",
        link: routes.messages,
        action: "manage" ,
        subject: "Message",
        icon: "ti ti-message",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "examinations",
        icon: "ti ti-hexagonal-prism-plus",
        submenu: true,
        showSubRoute: false,
        submenuItems: [
          { label: "Exam", link: routes.exam },
          { label: "exam_sessions", link: routes.examSession, action: "create", subject: "ExamSession"},
          { label: "my_marks", link: routes.StudentMarks, action: "read", subject: "Mark"},
          { label: "exam_sessions", link: routes.teacherExamSession, action: "read", subject: "ExamSession" },
        ],
      },
    ],
  },
];
