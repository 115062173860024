import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { Card, Spin, Row, Col, Typography } from "antd";
import CommonSelect from "../../../components/Common/CommonSelect";
import { useWeeklyPlanner } from "./hooks/useWeeklyPlanner";
import WeeklyPlanner from "./components/WeeklyPlanner/WeeklyPlanner";
import { levels } from "../../../constants/planningContants";

const { Title, Text } = Typography;

const WeeklyPlannerPage = () => {
  const { t } = useTranslation();
  const {
    schedules,
    classes,
    fetchClassesByLevel,
    fetchSchedulesByClassIds,
    loading,
    modalState,
    setModalState,
    handleSave,
    handleDelete,
  } = useWeeklyPlanner();

  const [selectedLevel, setSelectedLevel] = useState<string | undefined>();

  // Fetch classes filtered by level when level changes
  useEffect(() => {
    if (selectedLevel) {
      fetchClassesByLevel(selectedLevel);
    }
  }, [selectedLevel]);

  // Fetch schedules when classes are fetched
  useEffect(() => {
    if (classes.length > 0) {
      const classIds = classes.map((cls) => cls.id);
      fetchSchedulesByClassIds(classIds);
    }
  }, [classes]);

  const handleLevelChange = (option: { value: string; label: string } | null) => {
    setSelectedLevel(option?.value);
  };

  const translatedLevels = levels.map((level) => ({
    ...level,
    label: t(level.label),
  }));

  return (
    <div className="page-wrapper">
      <div className="content">
        <Col>
          <Title level={2}>{t("planning_manager")}</Title>
        </Col>
        <Card className="shadow-sm p-4">
          {/* Level Selector */}
          <Row justify="start" align="middle" className="mb-3">
            <Col xs={24} sm={12} md={8} lg={6}>
              <Text className="d-block mb-2">{t("select_level")}</Text>
              <CommonSelect
                className="z-3 w-100"
                options={translatedLevels}
                onChange={handleLevelChange}
                defaultValue={translatedLevels.find((option) => option.value === selectedLevel)}
              />
            </Col>
          </Row>

          {loading ? (
            <Row justify="center" className="mt-4">
              <Spin size="large" />
            </Row>
          ) : (
            classes?.length > 0 && (
              <WeeklyPlanner
                classes={classes}
                schedules={schedules}
                modalState={modalState}
                setModalState={setModalState}
                handleSave={handleSave}
                handleDelete={handleDelete}
                loading={loading}
              />
            )
          )}
        </Card>
      </div>
    </div>
  );
};

export default WeeklyPlannerPage;
