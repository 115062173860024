import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Select from "react-select";

// Define option type
export type Option = {
  value: string;
  label: string;
};

// Extend SelectProps to accept onChange as a prop
export interface SelectProps {
  options: Option[];
  defaultValue?: Option;
  className?: string;
  styles?: any; // Optional custom styles if needed
  onChange?: (value: Option | null) => void; // New prop to handle changes externally
}

const CommonSelect: React.FC<SelectProps> = ({
  options,
  defaultValue,
  className,
  onChange, // onChange prop from parent
}) => {
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(defaultValue);

  const { t } = useTranslation();
  const handleChange = (
option: Option | null) => {
    setSelectedOption(option || undefined); // Update local state
    if (onChange) onChange(option); // Call parent onChange if provided
  };

  // Set initial value based on defaultValue
  useEffect(() => {
    setSelectedOption(defaultValue || undefined);
  }, [defaultValue]);

  return (
    <Select
      classNamePrefix="react-select"
      className={className}
      options={options}
      value={selectedOption}
      onChange={handleChange} // Call handleChange on selection
      placeholder={t("select")}
    />
  );
};

export default CommonSelect;
