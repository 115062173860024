import { useTranslation } from "react-i18next";
// index.tsx
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { DatatableProps } from "../../../core/data/interface"; // Ensure correct path
import NoDataComponent from "./NoData";


const Datatable: React.FC<DatatableProps> = ({ columns, dataSource , Selection, bordered, pagination }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [Selections, setSelections] = useState<any>(true);
  const [filteredDataSource, setFilteredDataSource] = useState(dataSource);

  const { t } = useTranslation();
  const onSelectChange = (
newSelectedRowKeys: any[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  useEffect(() => {
    setFilteredDataSource(dataSource);
  }, [dataSource]);

  const handleSearch = (value: string) => {
    setSearchText(value);
    const filteredData = dataSource.filter((record) =>
      Object.values(record).some((field) =>
        String(field).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredDataSource(filteredData);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  
  useEffect(() => {
    return setSelections(Selection);
  }, [Selection])
  
  
  return (
    <>
     <div className="table-top-data d-flex px-3 justify-content-between">
     </div>
     {!Selections ?
      <Table
      className="table datanew dataTable no-footer"
      columns={columns}
      rowHoverable={false}
      rowKey="id"
      dataSource={filteredDataSource}
      locale={{ emptyText: <NoDataComponent/>}} // Used for translation no data
      pagination={pagination ? {
        defaultPageSize: 10,
         showTotal: (total, range) => `${t("showing")} ${range[0]}-${range[1]} ${t("of")} ${total}`, 
      }: false}
    /> : 
    <Table
        className="table datanew dataTable no-footer"
        rowSelection={rowSelection}
        columns={columns}
        rowKey="id"
        rowHoverable={false}
        bordered={bordered}
        dataSource={filteredDataSource}
        locale={{ emptyText: <NoDataComponent/>}} // Used for translation no data
        pagination={pagination ? {
          defaultPageSize: 10,
        }: false}
      />}
      
    </>
  );
};

export default Datatable;
