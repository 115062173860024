import axiosInstance from './axiosInstance';

export const getSubjects = async () => {
  const response = await axiosInstance.get('/subjects');
  return response.data
};

export const getSubject = async (id: string) => {
  const response = await axiosInstance.get(`/subjects/${id}`);
  return response.data
};

export const addSubject = async (subjectData: { name: string; description?: string; instanceId?: number }) => {
  const response = await axiosInstance.post('/subjects', subjectData);
  return response.data
};

export const updateSubject = async (id: string, subjectData: any) => {
  const response = await axiosInstance.put(`/subjects/${id}`, subjectData);
  return response.data
};

export const deleteSubject = async (id: string) => {
  const response = await axiosInstance.delete(`/subjects/${id}`);
  return response.data
};

export const getClassesBySubject = async (subjectId: string) => {
  const response = await axiosInstance.get(`/subjects/${subjectId}/classes`);
  return response.data;
};