import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Table, Switch, Button, message, Spin } from "antd";
import { getAssignedStudents } from "../../../../services/classService";
import { StudentData } from "../../../../types/classTypes";
import { Schedule } from "../../../../types/planningTypes";
import CustomModal from "../../../../components/Common/CustomModal";

interface AttendanceModalProps {
  session: Schedule;
  visible: boolean;
  onClose: () => void;
  onSubmit: (attendanceData: any[]) => void; // Callback for submitting attendance
}

const AttendanceModal: React.FC<AttendanceModalProps> = ({ session, visible, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [students, setStudents] = useState<StudentData[]>([]);
  const [attendanceData, setAttendanceData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (session && visible) {
      const loadStudents = async () => {
        try {
          setLoading(true);
          const studentsData = await getAssignedStudents(session.classSubject?.class.id ?? "0");
          setStudents(studentsData);

          setAttendanceData(
            studentsData.map((student) => ({
              studentId: student.id,
              firstName: student.user.firstName,
              lastName: student.user.lastName,
              status: "absent", // Default to "absent"
              absenceReason: "unexcused",
              dateTime: `${new Date().toISOString().split("T")[0]}T${session.startTime}Z`,
            }))
          );
        } catch (err) {
          console.error("Error fetching students:", err);
          message.error(t("failed_to_load_students"));
        } finally {
          setLoading(false);
        }
      };

      loadStudents();
    }
  }, [session, visible]);

  const handleAttendanceChange = (studentId: string, isPresent: boolean) => {
    setAttendanceData((prev) =>
      prev.map((entry) =>
        entry.studentId === studentId
          ? { ...entry, status: isPresent ? "present" : "absent" }
          : entry
      )
    );
  };

  const columns = [
    {
      title: t("student_name"),
      key: "name",
      render: (record: StudentData) =>
        `${record.user.firstName} ${record.user.lastName}`,
    },
    {
      title: t("attendance"),
      key: "status",
      render: (_: any, record: StudentData) => {
        const attendance = attendanceData.find(
          (entry) => entry.studentId === record.id
        );

        return (
          <Switch
            checked={attendance?.status === "present"}
            onChange={(checked) => handleAttendanceChange(record.id, checked)}
            checkedChildren={t("present")}
            unCheckedChildren={t("absent")}
          />
        );
      },
    },
  ];

  return (
    <CustomModal
      title={`${t("mark_attendance")} - ${session?.classSubject?.class?.name ?? ""}`}
      isOpen={visible}
      onClose={onClose}
      saveText ={t("submit")}
      onSave={ async () => onSubmit(attendanceData)}
    >
      {loading ? (
        <div className="d-flex justify-content-center py-4">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          dataSource={students}
          columns={columns}
          rowKey="id"
          pagination={false}
        />
      )}
    </CustomModal>
  );
};

export default AttendanceModal;
