import { useTranslation } from "react-i18next";
import React from "react";
import TaskList from "../components/TaskList";
import Loader from "../../../../components/Common/loader";

interface TaskListCardProps {
  selectedDate: string | null;
  tasks: any[];
  loading: boolean;
  error: string | null;
  onTaskClick: (task: any) => void;
}

const TaskListCard: React.FC<TaskListCardProps> = ({ selectedDate, tasks, loading, error, onTaskClick }) => {
  const { t } = useTranslation();
  return (
    <div className="col-md-8 mb-3">
      <div className="card shadow-sm border-0">
        <div className="card-body">
          <h4 className="mb-3 text-primary">
            {selectedDate ? `${t("tasks_for")} ${selectedDate}` : t("all_my_tasks")}
          </h4>
          {loading && <Loader />}
          {error && <p className="text-danger">{error}</p>}
          {!loading && !error && (
            tasks.length > 0 ? (
              <TaskList tasks={tasks} onTaskClick={onTaskClick} />
            ) : (
              <p className="text-muted">{t("no_tasks_available_for_the_selected_date")}</p>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskListCard;
