import { useState, useEffect } from "react";
import { fetchDashboardData } from "../../../services/dashboardService";

// Define the data structure for the dashboard response
export interface DashboardData {
  counts: {
    students: number;
    classes: number;
    teachers?: number; // Optional for roles that don't have teachers
  };
  todaysPlanning: any[];
  incomingHomework: any[];
  incomingExamSessions?: any[];
  absencesOverview?: any[];
  pendingAbsences?: any[];
  dailyAbsences?: any[];
  inboxMessages?: any[];
}

const useDashboardData = () => {
  // ✅ Explicitly type state as `DashboardData | null`
  const [data, setData] = useState<DashboardData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getDashboardData = async () => {
      try {
        setIsLoading(true);
        const response = await fetchDashboardData();

        const formattedData: DashboardData = {
          counts: {
            students: response.counts?.students ?? 0,
            classes: response.counts?.classes ?? 0,
            teachers: response.counts?.teachers ?? 0, // Only relevant for pedagogyDirector
          },
          todaysPlanning: response.todaysPlanning ?? [],
          incomingHomework: response.incomingHomework ?? [],
          incomingExamSessions: response.incomingExamSessions ?? [],
          absencesOverview: response.absencesOverview ?? [],
          pendingAbsences: response.pendingAbsences ?? [],
          dailyAbsences: response.todaysAbsences ?? [],
          inboxMessages: response.inboxMessages ?? [],
        };

        setData(formattedData);
      } catch (err) {
        setError("Failed to fetch dashboard data");
      } finally {
        setIsLoading(false);
      }
    };

    getDashboardData();
  }, []);

  return { data, isLoading, error };
};

export default useDashboardData;
