import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import TeacherExamSessionList from "../components/TeacherExamSessionList";
import { useTeacherExamSessions } from "../hooks/useTeacherExamSessions";
import { useSelector } from "react-redux";
import TableFilter, { FilterField } from "../../../../components/Common/TableFilter/TableFilter";

const TeacherExamSessionsPage: React.FC = () => {
  const teacherId = useSelector((state: any) => state.authSlice.user?.roleId);
  const { examSessions, isLoading } = useTeacherExamSessions(teacherId);
  const { t } = useTranslation();
  const [filters, setFilters] = useState<{ [key: string]: string | number | null }>({});

  // Define filter fields
  const filterFields: FilterField[] = [
    { key: "name", label: t("exam_session_name"), type: "text" },
    { key: "subject", label: t("subject"), type: "text" },
    { key: "semester", label: t("semester"), type: "select", options: [
        { label: t("semester_1"), value: "S1" },
        { label: t("semester_2"), value: "S2" },
      ]
    },
    { key: "startDate", label: t("start_date"), type: "date" },
  ];

  // Apply filters
  const handleApplyFilters = (newFilters: { [key: string]: string | number | null }) => {
    setFilters(newFilters);
  };

  // Reset filters
  const handleResetFilters = () => {
    setFilters({});
  };

  // Filter exam sessions dynamically
  const filteredExamSessions = examSessions.filter((session) =>
    Object.entries(filters).every(([key, value]) => {
      if (!value) return true;
      return String(session[key as keyof typeof session]).toLowerCase().includes(String(value).toLowerCase());
    })
  );

  return (
    <div className="page-wrapper">
      <div className="content">
        <h1 className="mb-4 d-flex align-items-center">{t("my_exam_sessions")}</h1>

        {/* ✅ Filter Table Component Added */}
        <div className="card shadow-sm">
            <TableFilter fields={filterFields} filters={filters} onApply={handleApplyFilters} onReset={handleResetFilters} />
          <div className="card-body">
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center py-5">
                <Spin size="large" />
              </div>
            ) : filteredExamSessions.length > 0 ? (
              <TeacherExamSessionList examSessions={filteredExamSessions} />
            ) : (
              <div className="alert alert-warning text-center" role="alert">
                {t("no_exam_sessions_available")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherExamSessionsPage;
