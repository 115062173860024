import { useTranslation } from "react-i18next";
import React from "react";
import { Form, Input, DatePicker, Select } from "antd";
import { convertToDayjs } from "../../../../utils/dateUtils";
import { ClassTask } from "../../../../types/classTaskTypes";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

interface TaskFormProps {
  form: any;
  initialValues?: Partial<ClassTask>;
  classes: { value: string; label: string }[];
  isEditing: boolean;
}

const TaskForm: React.FC<TaskFormProps> = ({ form, initialValues, classes, isEditing }) => {
  const { t } = useTranslation();
  return (
    <Form form={form} layout="vertical" initialValues={{
      ...initialValues,
      date: initialValues?.date ? convertToDayjs(initialValues.date) : null,
      dueDate: initialValues?.dueDate ? convertToDayjs(initialValues.dueDate) : null,
    }}>
      <Form.Item
        name="type"
        label={t("task_type")}
        rules={[{ required: true, message: t("select_a_task_type") }]}
      >
        <Select placeholder={t("select_a_task_type")} disabled={isEditing}>
          <Option value="LessonPlan">{t("lesson_plan")}</Option>
          <Option value="Homework">{t("homework")}</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="classId"
        label={t("class")}
        rules={[{ required: true, message: t("select_a_class") }]}
      >
        <Select placeholder={t("select_a_class")} disabled={isEditing}>
          {classes.map((cls) => (
            <Option key={cls.value} value={cls.value}>
              {cls.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="date"
        label={t("task_date")}
        rules={[{ required: true, message: t("select_a_date") }]}
      >
        <DatePicker style={{ width: "100%" }} disabled={isEditing} />
      </Form.Item>
      <Form.Item name="topic" label={t("task_topic")} rules={[{ required: true, message: t("enter_a_title") }]}>
        <Input placeholder={t("task_topic")} />
      </Form.Item>
      <Form.Item
        name="description"
        label={t("task_description")}
      >
        <TextArea 
          placeholder={t("task_description")} 
          maxLength={250}
          showCount
          autoSize={{ minRows: 3, maxRows: 6 }}
        />
      </Form.Item>
      <Form.Item 
        name="dueDate" 
        label={t("due_date")}
        dependencies={['date']}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const taskDate = getFieldValue("date");
              // Allow if there's no due date or task date is not set
              if (!value || !taskDate) {
                return Promise.resolve();
              }
              // Use isAfter from dayjs to compare the dates
              if (value.isAfter(taskDate)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("due_date_must_be_after_task_date")));
            },
          }),
        ]}
    >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};

export default TaskForm;
