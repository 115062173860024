import axios from './axiosInstance';
import { MessageResponse } from '../types/messageTypes';
import { ApiResponse } from '../types/apiTypes';
import { Recipient } from '../types/userTypes';

export interface MessageFilters {
  page?: number;
  limit?: number;
}

export interface SendMessagePayload {
  recipientIds: [string]; // ID of the recipient
  subject: string; // Subject of the message
  body: string; // Body of the message
}

export const fetchInboxMessages = async (filters?: MessageFilters): Promise<MessageResponse> => {
  const response = await axios.get<MessageResponse>('/messages/inbox', {
    params: filters, // Use filters for query parameters
  });
  return response.data;
};

export const fetchSentMessages = async (filters?: MessageFilters): Promise<MessageResponse> => {
  const response = await axios.get<MessageResponse>('/messages/sent', {
    params: filters, // Use filters for query parameters
  });
  return response.data;
};

export const sendMessage = async (payload: SendMessagePayload): Promise<void> => {
  const response = await axios.post('/messages', payload); // POST request to /messages
  return response.data;
};

export const fetchRecipients = async ({query, page = 1, limit = 20,}: {
  query?: string;
  page?: number;
  limit?: number;
}): Promise<ApiResponse<Recipient[]>> => {
  const response = await axios.get<ApiResponse<Recipient[]>>('/users/recipients', {
    params: { query, page, limit },
  });
  return response.data;
};