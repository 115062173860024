import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

/**
 * Formats a date-time string to "dd/MM/yyyy HH:mm".
 * @param dateTime - The ISO date-time string.
 * @returns The formatted date-time string.
 */
export const formatDateTime = (dateTime: string): string => {
  try {
    // Parse the ISO string and format it
    return dayjs(dateTime).format("DD/MM/YYYY HH:mm");
  } catch (error) {
    console.error("Error formatting date-time:", error);
    return dateTime; // Fallback to the raw dateTime string
  }
};

/**
 * Converts a given date input into a SQL-style date (YYYY-MM-DD).
 * @param date - The date input in any format (string, Date, or dayjs).
 * @returns The formatted date as "YYYY-MM-DD" or null if invalid.
 */
export const formatToSQLDate = (date: string | Date | dayjs.Dayjs | null | undefined): string | null => {
  if (!date) return null; // Handle null or undefined values
  const parsedDate = dayjs(date); // Ensure it's always a dayjs object
  return parsedDate.isValid() ? parsedDate.format("YYYY-MM-DD") : null;
};

/**
 * Converts a date input into a `dayjs` object.
 * @param date - The date input to be converted. Can be a string, Date, or dayjs object.
 * @returns A valid `dayjs` object, or null if the input is invalid or undefined.
 */
export const convertToDayjs = (date: string | Date | dayjs.Dayjs | undefined): dayjs.Dayjs | null => {
  if (!date) return null; // Handle null or undefined
  if (dayjs.isDayjs(date)) return date; // Already a dayjs object
  return dayjs(date); // Convert string or Date to dayjs
};

/**
 * Parses an SQL date string (YYYY-MM-DD) into a JavaScript Date object.
 * @param sqlDate - The SQL date string.
 * @returns A `Date` object or `null` if invalid.
 */
export const parseSQLDate = (sqlDate: string | null | undefined): dayjs.Dayjs | null => {
  if (!sqlDate) return null;
  const parsedDate = dayjs(sqlDate, "YYYY-MM-DD"); // Ensure correct format
  return parsedDate.isValid() ? parsedDate : null;
};

/**
 * Formats duration in minutes into a string "hh'h'mm" format.
 * Example: 105 minutes → "01h45", 60 minutes → "01h00", 45 minutes → "00h45".
 *
 * @param minutes - Duration in minutes
 * @returns Formatted string
 */
export const formatTimeDuration = (minutes: number | null): string => {
  if (!minutes || minutes <= 0) return "00h00";

  const formattedDuration = dayjs.duration(minutes, "minutes");
  const hours = formattedDuration.hours().toString().padStart(2, "0");
  const mins = formattedDuration.minutes().toString().padStart(2, "0");

  return `${hours}h${mins}`;
};

/**
 * Formats a time string from "HH:mm:ss" to "HH:mm".
 * @param time - The time string in "HH:mm:ss" format.
 * @returns The formatted time in "HH:mm" or the original value if invalid.
 */
export const formatTimeToHHmm = (time: string): string => {
  if (!time) return "";
  const parsedTime = dayjs(time, "HH:mm:ss");
  return parsedTime.isValid() ? parsedTime.format("HH:mm") : time;
};

/**
 * Converts a date object or string to French-style DD/MM/YYYY format.
 * @param {string | Date | null | undefined} date - The date input.
 * @returns {string} The formatted date or "Invalid Date" if the input is falsy.
 */
export const formatToFrenchDate = (date: string | Date | null | undefined) => {
  return date ? dayjs(date).format("DD-MM-YYYY") : "";
};
