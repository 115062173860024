import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, DatePicker, message } from "antd";
import { all_routes } from "../../../router/all_routes";
import { getSubjects } from "../../../services/subjectService";
import { getClasses } from "../../../services/classService";
import { addTeacher, updateTeacher, getTeacher } from "../../../services/teacherService";
import CommonSelect from "../../Common/CommonSelect";
import CommonMultiSelect from "../../Common/CommonMultiSelect";
import CommonFormLayout from "../../Common/CommonFormLayout";
import { parseSQLDate, formatToSQLDate } from "../../../utils/dateUtils";
import { GENDER_OPTIONS } from "../../../constants/userConstants";

const TeacherForm = () => {
  const { t } = useTranslation();
  const routes = all_routes;
  const { id: teacherId } = useParams<{ id: string }>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [subjects, setSubjects] = useState<{ value: string; label: string }[]>([]);
  const [classes, setClasses] = useState<{ value: string; label: string }[]>([]);
  const [selectedClasses, setSelectedClasses] = useState<string[]>([]);
  const navigate = useNavigate();

  // Translate gender options
  const translatedGender = GENDER_OPTIONS.map(gender => ({
    value: gender.value,
    label: t(gender.label),
  }));

  useEffect(() => {
    fetchSubjects();
    fetchClasses();

    if (teacherId) {
      setIsEdit(true);
      fetchTeacherData(teacherId);
    }
  }, [teacherId]);

  const fetchTeacherData = async (id: string) => {
    try {
      const response = await getTeacher(id);
      const teacherData = response.data;
      const formattedClasses = teacherData.classSubject?.map((cs: any) => cs.class.id) || [];

      const initialData = {
        user: {
          firstName: teacherData.user?.firstName || "",
          lastName: teacherData.user?.lastName || "",
          email: teacherData.user?.email || "",
          phoneNumber: teacherData.user?.phoneNumber || "",
          birthDate: parseSQLDate(teacherData.user?.birthDate),
          gender: teacherData.user?.gender || null,
          cityOfBirth: teacherData.user?.cityOfBirth || "",
          zipCode: teacherData.user?.zipCode || "",
          address: teacherData.user?.address || "",
          city: teacherData.user?.city || "",
        },
        subjectId: teacherData.subjectId || null,
        classIds: formattedClasses,
      };

      form.setFieldsValue(initialData);
      setSelectedClasses(formattedClasses);
    } catch (error) {
      message.error(t("failed_to_load_teacher_data"));
    }
  };

  const fetchSubjects = async () => {
    try {
      const response = await getSubjects();
      setSubjects(response.map((subject: any) => ({ value: subject.id, label: subject.name })));
    } catch (error) {
      message.error(t("failed_to_load_subjects"));
    }
  };

  const fetchClasses = async () => {
    try {
      const response = await getClasses();
      setClasses(response.map((classItem: any) => ({ value: classItem.id, label: classItem.name })));
    } catch (error) {
      message.error(t("failed_to_load_classes"));
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      const teacherData = {
        ...values,
        user: {
          ...values.user,
          birthDate: values.user.birthDate ? formatToSQLDate(values.user.birthDate) : null, // Ensure SQL format
        },
        classIds: selectedClasses,
      };

      if (isEdit) {
        await updateTeacher(teacherId!, teacherData);
        message.success(t("teacher_updated_successfully"));
      } else {
        await addTeacher(teacherData);
        message.success(t("teacher_added_successfully"));
      }

      navigate(routes.teacherList);
    } catch (error) {
      message.error(t("failed_to_save_teacher_data"));
    }
  };

  // ✅ Define Sections
  const sections = [
    {
      title: t("personal_information"),
      content: (
        <div className="row">
          <div className="col-md-4">
            <Form.Item label={t("last_name")} name={["user", "lastName"]} rules={[{ required: true, message: t("last_name_required") }]}>
              <Input placeholder={t("enter_last_name")} />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label={t("first_name")} name={["user", "firstName"]} rules={[{ required: true, message: t("first_name_required") }]}>
              <Input placeholder={t("enter_first_name")} />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label={t("email_address")} name={["user", "email"]} rules={[{ required: true, type: "email", message: t("email_required") }]}>
              <Input placeholder={t("enter_email")} />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label={t("gender")} name={["user", "gender"]} rules={[{ required: true, message: t("gender_required") }]}>
              <CommonSelect
                options={translatedGender}
                defaultValue={translatedGender.find(opt => opt.value === form.getFieldValue(["user", "gender"]))}
                onChange={(option) => form.setFieldsValue({ user: { ...form.getFieldsValue().user, gender: option?.value ?? "" } })}
              />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label={t("phone_number")} name={["user", "phoneNumber"]} rules={[{ required: true, message: t("phone_number_required") }]}>
              <Input placeholder={t("enter_phone_number")} />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label={t("birth_date")} name={["user", "birthDate"]} rules={[{ required: true, message: t("birth_date_required") }]}>
              <DatePicker className="form-control datetimepicker" format="YYYY-MM-DD" placeholder={t("select_date")} />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label={t("city_of_birth")} name={["user", "cityOfBirth"]} rules={[{ required: true, message: t("city_of_birth_required") }]}>
              <Input placeholder={t("enter_city_of_birth")} />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label={t("address")} name={["user", "address"]} rules={[{ required: true, message: t("address_required") }]}>
              <Input placeholder={t("enter_address")} />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label={t("zip_code")} name={["user", "zipCode"]} rules={[{ required: true, message: t("zip_code_required") }]}>
              <Input placeholder={t("enter_zip_code")} />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label={t("city")} name={["user", "city"]} rules={[{ required: true, message: t("city_required") }]}>
              <Input placeholder={t("enter_city")} />
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      title: t("assignments"),
      content: (
        <div className="row">
          <div className="col-md-6">
            <Form.Item label={t("assign_subject")} name="subjectId" rules={[{ required: true, message: t("subject_required") }]}>
              <CommonSelect options={subjects} defaultValue={subjects.find(subject => subject.value === form.getFieldValue(["subjectId"])) || undefined}/>
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item label={t("assign_classes")} name="classIds" rules={[{ required: true, message: t("class_required") }]}>
              <CommonMultiSelect options={classes} value={selectedClasses} onChange={setSelectedClasses} />
            </Form.Item>
          </div>
        </div>
      ),
    },
  ];

  return <CommonFormLayout title={isEdit ? t("edit_teacher") : t("add_teacher")} form={form} onFinish={handleSubmit} sections={sections} cancelRoute={routes.teacherList} submitText={t("add")} isEdit={isEdit} />;
};

export default TeacherForm;
