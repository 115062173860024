import axiosInstance from './axiosInstance';
import { ClassData, StudentData } from '../types/classTypes';

// Fetch all classes
export const getClasses = async (
  level?: string | undefined // Add `level` as an optional parameter
): Promise<ClassData[]> => {
  const params: any = {}; // Initialize an empty params object

  if (typeof level !== 'undefined') {
    params.level = level;
  }

  const response = await axiosInstance.get<ClassData[]>('/classes', { params });
  return response.data; // Return only the data array
};

// Fetch a single class by ID
export const getClassById = async (id: string): Promise<ClassData> => {
  const response = await axiosInstance.get<ClassData>(`/classes/${id}`);
  return response.data;
};

// Create a new class
export const addClass = async (classData: Partial<ClassData>): Promise<ClassData> => {
  const response = await axiosInstance.post<ClassData>('/classes', classData);
  return response.data;
};

// Update an existing class
export const updateClass = async (id: string, classData: Partial<ClassData>): Promise<ClassData> => {
  const response = await axiosInstance.put<ClassData>(`/classes/${id}`, classData);
  return response.data;
};

// Delete a class
export const deleteClass = async (id: string): Promise<void> => {
  await axiosInstance.delete(`/classes/${id}`);
};

// Assign students to a class
export const assignStudentsToClass = async (classId: string, studentIds: string[]): Promise<void> => {
  await axiosInstance.post(`/classes/${classId}/assign-students`, { studentIds });
};

export const getAssignedStudents = async (classId: string) => {
    const response = await axiosInstance.get<StudentData[]>(`/classes/${classId}/students`);
    return response.data
  };
