import { useTranslation } from "react-i18next";
import React from 'react';
import { Card, Descriptions, Typography, Divider, Tag, Avatar } from 'antd';
import { formatDateTime } from '../../../../utils/dateUtils';
import { UserOutlined } from '@ant-design/icons';
import { Message } from '../../../../types/messageTypes';

interface MessageDetailProps {
  message: Message;
  onBack: () => void;
}

const { Title, Paragraph } = Typography;

const MessageDetail: React.FC<MessageDetailProps> = ({ message, onBack }) => {
  const { t } = useTranslation();

  return (
    <Card
      title={
        <div>
          <button className="btn btn-primary" onClick={onBack}>{t("back")}</button>
        </div>
      }
      style={{ width: '100%' }}
    >
      <div className="d-flex align-items-center mb-3">
        <Avatar icon={<UserOutlined />} className="me-3" />
        <div>
          <Title level={5}>{message.sender?.name}</Title>
          {/* <Tag color={message.read ? 'green' : 'red'}>
            {message.read ? t("read") : t("unread")}
          </Tag> */}
        </div>
      </div>
      <Divider />

      <Descriptions column={1} bordered>
        <Descriptions.Item label={t("from")}>
          {message.sender?.name} ({message.sender.email})
        </Descriptions.Item>
        <Descriptions.Item label={t("to")}>
          {message.recipients.map((recipient) => (
            <Tag key={recipient.id} color="blue" className="me-1">
              {recipient.name}
            </Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label={t("message_subject")}>{message.subject}</Descriptions.Item>
        <Descriptions.Item label={t("sent_at")}>
          {formatDateTime(message.timestamp)}
        </Descriptions.Item>
      </Descriptions>

      <Divider />
      <Title level={5}>{t("message_body")}</Title>
      <Paragraph>{message.body}</Paragraph>
    </Card>
  );
};

export default MessageDetail;
