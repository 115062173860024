import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Modal, message } from "antd";
import Table from "../../Common/dataTable/index";
import ActionsDropdown, { Action } from "../../Common/ActionsDropdown";
import CustomModal from "../../Common/CustomModal";
import TableFilter, { FilterField } from "../../Common/TableFilter/TableFilter";
import { getSubjects, addSubject, updateSubject, deleteSubject } from "../../../services/subjectService";
import { all_routes } from "../../../router/all_routes";

const SubjectList = () => {
  const { t } = useTranslation();
  const routes = all_routes;
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [editingSubject, setEditingSubject] = useState<any>(null);
  const [modalType, setModalType] = useState<"add" | "edit">("add");
  const [filters, setFilters] = useState<{ [key: string]: string | number | null }>({});

  const fetchSubjects = async () => {
    try {
      const response = await getSubjects();
      setData(response);
      setFilteredData(response);
    } catch (error) {
      message.error(t("failed_to_fetch_subjects"));
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  // ✅ Handle Filters
  const handleFilterApply = (newFilters: { [key: string]: string | number | null }) => {
    setFilters(newFilters);
    let filtered = data;

    if (newFilters.name) {
      filtered = filtered.filter((subject: any) =>
        subject.name.toLowerCase().includes((newFilters.name as string).toLowerCase())
      );
    }
    if (newFilters.code) {
      filtered = filtered.filter((subject: any) =>
        subject.code.toLowerCase().includes((newFilters.code as string).toLowerCase())
      );
    }

    setFilteredData(filtered);
  };

  const handleFilterReset = () => {
    setFilters({});
    setFilteredData(data);
  };

  const filterFields: FilterField[] = [
    { key: "name", label: t("subject_name"), type: "text" },
    { key: "code", label: t("subject_code"), type: "text" },
  ];

  const handleEditClick = (subject: any) => {
    setEditingSubject(subject);
    setModalType("edit");
  };

  const handleDeleteClick = (id: string) => {
    Modal.confirm({
      title: t("confirm_delete"),
      content: t("delete_subject_warning"),
      okText: t("delete"),
      cancelText: t("cancel"),
      okType: "danger",
      onOk: async () => {
        try {
          await deleteSubject(id);
          message.success(t("subject_deleted_successfully"));
          fetchSubjects();
        } catch (error) {
          message.error(t("failed_to_delete_subject"));
        }
      },
    });
  };

  const handleSubmit = async () => {
    const subjectData = {
      name: editingSubject?.name,
      description: editingSubject?.description,
      code: editingSubject?.code,
    };

    try {
      if (modalType === "edit" && editingSubject?.id) {
        await updateSubject(editingSubject.id, subjectData);
        message.success(t("subject_updated_successfully"));
      } else {
        await addSubject(subjectData);
        message.success(t("subject_added_successfully"));
      }
      setEditingSubject(null);
      fetchSubjects();
    } catch (error) {
      message.error(t("failed_to_save_subject"));
    }
  };

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: t("code"),
      dataIndex: "code",
      sorter: (a: any, b: any) => a.code.localeCompare(b.code),
    },
    {
      title: t("description"),
      dataIndex: "description",
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (text: string) => (
        <span className={`badge ${text === "Active" ? "badge-soft-danger" : "badge-soft-success"}`}>
          <i className="ti ti-circle-filled fs-5 me-1"></i> {t(text.toLowerCase())}
        </span>
      ),
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
    },
    {
      title: t("action"),
      dataIndex: "action",
      render: (_: any, record: any) => {
        const actions: Action[] = [
          {
            label: t("edit"),
            icon: "ti ti-edit-circle",
            actionType: "modal",
            onClick: () => handleEditClick(record),
          },
          {
            label: t("delete"),
            icon: "ti ti-trash-x",
            actionType: "modal",
            onClick: () => handleDeleteClick(record.id),
          },
        ];
        return <ActionsDropdown actions={actions} />;
      },
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
          <h1 className="page-title mb-1">{t("subjects_list")}</h1>
          <button
            className="btn btn-primary"
            onClick={() => {
              setEditingSubject({ name: "", description: "", code: "" });
              setModalType("add");
            }}
          >
            <i className="ti ti-square-rounded-plus-filled me-2" /> {t("add_subject")}
          </button>
        </div>

        <div className="card">
          {/* ✅ Add Filter Component */}
          <TableFilter fields={filterFields} filters={filters} onApply={handleFilterApply} onReset={handleFilterReset} />
          
          <div className="card-body p-0">
            <Table columns={columns} dataSource={filteredData} />
          </div>
        </div>

        {/* ✅ Replace Bootstrap Modal with CustomModal */}
        <CustomModal
          isOpen={!!editingSubject}
          title={modalType === "add" ? t("add_subject") : t("edit_subject")}
          onClose={() => setEditingSubject(null)}
          onSave={handleSubmit}
          saveText={modalType === "add" ? t("add_subject") : t("save_changes")}
        >
          <input
            type="text"
            className="form-control mb-3"
            placeholder={t("subject_name")}
            value={editingSubject?.name || ""}
            onChange={(e) => setEditingSubject({ ...editingSubject, name: e.target.value })}
            required
          />
          <input
            type="text"
            className="form-control mb-3"
            placeholder={t("subject_code")}
            value={editingSubject?.code || ""}
            onChange={(e) => setEditingSubject({ ...editingSubject, code: e.target.value })}
            required
          />
          <textarea
            className="form-control"
            placeholder={t("description")}
            value={editingSubject?.description || ""}
            onChange={(e) => setEditingSubject({ ...editingSubject, description: e.target.value })}
          />
        </CustomModal>
      </div>
    </div>
  );
};

export default SubjectList;
