import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../../components/Common/dataTable/index";
import TableFilter, { FilterField } from "../../../../components/Common/TableFilter/TableFilter";
import AssignStudentsModal from "../components/AssignStudentsModal";
import AddEditClassModal from "../components/AddEditClassModal";
import ActionsDropdown, { Action } from "../../../../components/Common/ActionsDropdown";
import useClassManagement from "../hooks/useClassManagement";
import { ClassData } from "../../../../types/classTypes";

const ClassList: React.FC = () => {
  const { t } = useTranslation();
  const {
    classes,
    assignedStudents,
    allStudents,
    isAssignModalOpen,
    isAddEditModalOpen,
    editingClass,
    currentClassId,
    openAssignModal,
    openAddEditModal,
    handleAssignStudentsSave,
    handleSaveClass,
    handleDeleteClass,
    setAssignModalOpen,
    setAddEditModalOpen
  } = useClassManagement();

  const [filters, setFilters] = useState<{ [key: string]: string | number | null }>({});
  const [filteredClasses, setFilteredClasses] = useState<ClassData[]>(classes); 

  // ✅ Update filtered data when `classes` change
  useEffect(() => {
    setFilteredClasses(classes);
  }, [classes]);

  // ✅ Handle Filter Apply
  const handleFilterApply = (newFilters: { [key: string]: string | number | null }) => {
    setFilters(newFilters);
    let filtered = classes;

    if (newFilters.className) {
      filtered = filtered.filter((cls: ClassData) =>
        cls.name.toLowerCase().includes((newFilters.className as string).toLowerCase())
      );
    }
    if (newFilters.level) {
      filtered = filtered.filter((cls: ClassData) => cls.level === newFilters.level);
    }
    if (newFilters.status) {
      filtered = filtered.filter((cls: ClassData) => cls.status === newFilters.status);
    }

    setFilteredClasses(filtered);
  };

  // ✅ Handle Filter Reset
  const handleFilterReset = () => {
    setFilters({});
    setFilteredClasses(classes); // Ensure table repopulates
  };

  // ✅ Define Filter Fields
  const filterFields: FilterField[] = [
    { key: "className", label: t("class"), type: "text" },
    { key: "level", label: t("level"), type: "text" },
    { 
      key: "status", 
      label: t("status"), 
      type: "select",
      options: [
        { label: t("active"), value: "Active" },
        { label: t("inactive"), value: "Inactive" }
      ]
    },
  ];

  const columns: Array<{
    title: string;
    dataIndex?: keyof ClassData;
    sorter?: (a: ClassData, b: ClassData) => number;
    render?: (text: any, record: ClassData) => JSX.Element;
  }> = [
    { title: t("class"), dataIndex: "name", sorter: (a, b) => a.name.localeCompare(b.name) },
    { title: t("level"), dataIndex: "level", sorter: (a, b) => a.level.localeCompare(b.level) },
    { title: t("no_of_students"), dataIndex: "noOfStudents" },
    {
      title: t("status"),
      dataIndex: "status",
      render: (text) => (
        <span className={`badge ${text === "Active" ? "badge-soft-danger" : "badge-soft-success"}`}>
          <i className="ti ti-circle-filled fs-5 me-1"></i>{text}
        </span>
      ),
    },
    {
      title: t("action"),
      render: (_, record) => {
        const actions: Action[] = [
          {
            label: t("assign_students"),
            icon: "ti ti-users",
            actionType: "modal",
            onClick: () => openAssignModal(record.id),
          },
          {
            label: t("edit"),
            icon: "ti ti-edit-circle",
            actionType: "modal",
            onClick: () => openAddEditModal(record),
          },
          {
            label: t("delete"),
            icon: "ti ti-trash",
            actionType: "modal",
            onClick: () => handleDeleteClass(record.id),
          },
        ];

        return <ActionsDropdown actions={actions} />;
      },
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
          <h1 className="page-title mb-1">{t("classes_list")}</h1>
          <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
            <div className="mb-2">
              <Link to="#" className="btn btn-primary" onClick={() => openAddEditModal()}>
                <i className="ti ti-square-rounded-plus me-2" />{t("add_class")}
              </Link>
            </div>
          </div>
        </div>

        {/* Filters */}
        <div className="card">
          <div className="card-body p-0">
            <TableFilter fields={filterFields} filters={filters} onApply={handleFilterApply} onReset={handleFilterReset} />
            <Table columns={columns} dataSource={filteredClasses} pagination={true} />
          </div>
        </div>

        <AssignStudentsModal 
        classId={currentClassId || ""} 
        isOpen={isAssignModalOpen} 
        allStudents={allStudents} 
        assignedStudents={assignedStudents} 
        onClose={() => setAssignModalOpen(false)} 
        onSave={handleAssignStudentsSave} />

        <AddEditClassModal 
        classData={editingClass} 
        isOpen={isAddEditModalOpen} 
        onSave={handleSaveClass} 
        onClose={() => setAddEditModalOpen(false)} />
      </div>
    </div>
  );
};

export default ClassList;
