import { useState, useEffect, useCallback } from "react";
import { getDailySchedule } from "../../../../services/absenceService";
import { Schedule } from "../../../../types/planningTypes";

export const useAttendanceData = (teacherId: string) => {
  const [sessions, setSessions] = useState<Schedule[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch the schedule (reusable function)
  const fetchSchedule = useCallback(async () => {
    if (!teacherId) return; // Prevent execution without a teacherId
    try {
      setLoading(true);
      setError(null); // Reset error state before fetching
      const response = await getDailySchedule(teacherId);
      setSessions(response.data); // Update sessions
    } catch (err: any) {
      setError(err.message || "Failed to fetch schedule.");
    } finally {
      setLoading(false);
    }
  }, [teacherId]);

  // Automatically fetch schedule on teacherId change
  useEffect(() => {
    fetchSchedule();
  }, [fetchSchedule]);

  return { sessions, loading, error, refresh: fetchSchedule }; // Expose refresh function
};
