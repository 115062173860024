import { useState, useCallback } from "react";
import { getSchedules, createPlanning, updatePlanning, deletePlanning } from "../../../../services/planningService";
import { getClasses } from "../../../../services/classService";
import { message } from "antd";
import { PlanningClassData, Schedule, Subject } from "../../../../types/planningTypes";

export const useWeeklyPlanner = () => {
  const [classes, setClasses] = useState<PlanningClassData[]>([]);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState<{
    visible: boolean;
    type: "add" | "edit";
    data: Partial<Schedule> | null;
  }>({ visible: false, type: "add", data: null });

  // Fetch classes by level
  const fetchClassesByLevel = useCallback(async (level: string | undefined) => {
    setLoading(true);
    try {
      const rawClasses = await getClasses(level); // Fetch generic ClassData
      const filteredClasses: PlanningClassData[] = rawClasses.map((cls) => ({
        ...cls,
        classSubject: cls.classSubject || [], // Ensure classSubjects exist
      }));
      setClasses(filteredClasses);
    } catch (error) {
      console.error("Error fetching classes by level:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch schedules by class IDs
  const fetchSchedulesByClassIds = useCallback(async (classIds: string[]) => {  
    setLoading(true);
    try {
      const relevantSchedules = await getSchedules(classIds);
      setSchedules(relevantSchedules);
    } catch (error) {
      console.error("Error fetching schedules:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Updated handleSave: after saving, refetch schedules for all classes.
  const handleSave = async (schedule: Schedule & { classId?: string; subjectId?: string }) => {
    try {
      // Map classId and subjectId to classSubjectId if necessary
      const classSubjectId = schedule.classSubjectId
        ? schedule.classSubjectId
        : classes
            .find((cls) => cls.id === schedule.classId)
            ?.classSubject.find((cs) => cs.subject.id === schedule.subjectId)?.id;
  
      if (!classSubjectId) {
        console.error("classSubjectId could not be determined");
        return;
      }
  
      const scheduleToSave: Schedule = {
        id: schedule.id,
        classSubjectId,
        dayOfTheWeek: schedule.dayOfTheWeek,
        startTime: schedule.startTime,
        endTime: schedule.endTime,
      };
  
      if (modalState.type === "add") {
        const newSchedule = await createPlanning(scheduleToSave);
        // Optionally update state locally:
        setSchedules((prev) => [...prev, newSchedule]);
      } else if (modalState.type === "edit" && schedule.id) {
        const updatedSchedule = await updatePlanning(scheduleToSave);
        setSchedules((prev) =>
          prev.map((s) => (s.id === updatedSchedule.id ? updatedSchedule : s))
        );
      }
      // Refetch schedules for all classes
      if (classes.length > 0) {
        await fetchSchedulesByClassIds(classes.map((cls) => cls.id));
      }
      setModalState({ visible: false, type: "add", data: null });
    } catch (error) {
      console.error("Error saving schedule:", error);
    }
  };
  
  // Updated handleDelete: refetch schedules for all classes after deletion.
  const handleDelete = async (planningIds: string[], classId?: string) => {
    try {
      await deletePlanning(planningIds);
      message.success("Planning deleted successfully");
      if (classes.length > 0) {
        await fetchSchedulesByClassIds(classes.map((cls) => cls.id));
      }
      setModalState({ visible: false, type: "add", data: null });
    } catch (error: any) {
      const errorMessage = error.response?.data?.error || "Failed to delete planning";
      message.error(errorMessage);
    }
  };

  return {
    classes,
    schedules,
    subjects,
    modalState,
    setModalState,
    setSchedules,
    fetchClassesByLevel,
    fetchSchedulesByClassIds,
    handleSave,
    handleDelete,
    loading,
  };
};
