import React from "react";
import { Card } from "antd";
import { useTranslation } from "react-i18next";
import { CountWidgetProps } from "../../../types/widgetTypes";
import ImageWithBasePath from "../../../components/Common/imageWithBasePath";

const CountWidget: React.FC<CountWidgetProps> = ({ title, count, iconPath }) => {
  const { t } = useTranslation();

  return (
    <Card className="count-widget shadow-sm border-primary">
      <div className="d-flex align-items-center">
        {/* Icon Container */}
        <div className={`avatar avatar-xl bg-primary-transparent me-3 p-2`}>
        <ImageWithBasePath src={iconPath} />
        </div>

        {/* Content Section */}
        <div className="overflow-hidden flex-fill">
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="counter text-primary fw-bold">
              {count}
            </h2>
          </div>
          <p className="text-muted">{t(title)}</p>
        </div>
      </div>
    </Card>
  );
};

export default CountWidget;
