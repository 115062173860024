import React from "react";
import { Card, List } from "antd";
import { useTranslation } from "react-i18next";
import { PlanningWidgetProps } from "../../../types/widgetTypes";
import NoDataComponent from "../../../components/Common/dataTable/NoData";

const PlanningWidget: React.FC<PlanningWidgetProps> = ({ title, schedule }) => {
  const { t } = useTranslation();

  return (
    <Card className="planning-widget shadow-sm border-primary">
      <h4 className="mb-3 text-primary">{t(title)}</h4>
      <div className="event-wrapper event-scroll">
        { schedule.length > 0 ? (
          schedule.map((item) => (
          <div key={item.id} className="border-start border-primary border-3 shadow-sm p-3 mb-3">
            {/* Class Name & Teacher */}
            <div className="d-flex align-items-center mb-3 pb-3 border-bottom">
              <span className="avatar p-1 me-2 bg-primary-transparent flex-shrink-0">
                <i className="ti ti-book fs-20" /> {/* Icon for Subject */}
              </span>
              <div className="flex-fill">
                <h6 className="mb-1">{t(item.class)}</h6>
                <p className="d-flex align-items-center">
                  <i className="ti ti-user me-1" /> {t(item.teacher)}
                </p>
              </div>
            </div>

            {/* Subject & Time */}
            <div className="d-flex align-items-center justify-content-between">
              <p className="mb-0">
                <i className="ti ti-calendar me-1" /> {t(item.subject)}
              </p>
              <p className="mb-0">
                <i className="ti ti-clock me-1" /> {item.startTime} - {item.endTime}
              </p>
            </div>
          </div>
        ))
        ): <NoDataComponent/>
        }
      </div>
    </Card>
  );
};

export default PlanningWidget;
