import { useState, useEffect, useCallback } from "react";
import { getStudentMarks } from "../../../../services/markService";
import { StudentMarksResponse } from "../../../../types/markTypes";
import { message } from "antd";

export const useStudentMarks = (studentId: string) => {
  const [marksData, setMarksData] = useState<StudentMarksResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch Marks Data
  const fetchStudentMarks = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getStudentMarks(studentId);
      setMarksData(response.data);
    } catch (err) {
      setError("Failed to fetch marks.");
      message.error("Error fetching student marks.");
    } finally {
      setIsLoading(false);
    }
  }, [studentId]);

  useEffect(() => {
    fetchStudentMarks();
  }, [fetchStudentMarks]);

  return {
    marksData,
    isLoading,
    error,
    fetchStudentMarks,
  };
};
