import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";
import CountWidget from "./CountWidget";
import ListWidget from "./ListWidget";
import PlanningWidget from "./PlanningWidget";

// Define props for different widgets
import {
  CountWidgetProps,
  ListWidgetProps,
  PlanningWidgetProps
} from "../../../types/widgetTypes";
import { DashboardData } from "../hooks/useDashboarData";

interface RoleWidgetsProps {
  role: "pedagogy-director" | "teacher" | "student";
  data: DashboardData;
}

// Define the widget item with generic types
interface WidgetItem<T> {
  id: string;
  component: React.FC<T>;
  props: T;
}

const RoleWidgets: React.FC<RoleWidgetsProps> = ({ role, data }) => {
  const { t } = useTranslation();
  const widgets: Array<WidgetItem<any>> = [];

  if (role === "pedagogy-director") {
    widgets.push(
      { id: "students", component: CountWidget, props: { title: t("total_students"), iconPath: "assets/img/icons/student.svg", count: data.counts.students } as CountWidgetProps },
      { id: "teachers", component: CountWidget, props: { title: t("total_teachers"), iconPath: "assets/img/icons/teacher.svg", count: data.counts.teachers ?? 0 } as CountWidgetProps },
      { id: "classes", component: CountWidget, props: { title: t("total_classes"), iconPath: "assets/img/icons/subject.svg", count: data.counts.classes } as CountWidgetProps },
      { id: "dailyPlanning", component: PlanningWidget, props: { title: t("todays_planning"), schedule: data.todaysPlanning } as PlanningWidgetProps },
      { id: "dailyAbsences", component: ListWidget, props: { title: t("daily_absences"), data: data.dailyAbsences ?? [] } as ListWidgetProps }
    );
  }

  if (role === "teacher") {
    widgets.push(
      { id: "teacherClasses", component: CountWidget, props: { title: t("teacher_classes"), iconPath: "assets/img/icons/subject.svg", count: data.counts.classes } as CountWidgetProps },
      { id: "teacherStudents", component: CountWidget, props: { title: t("total_students"), iconPath: "assets/img/icons/student.svg", count: data.counts.students } as CountWidgetProps },
      { id: "teacherPlanning", component: PlanningWidget, props: { title: t("todays_planning"), schedule: data.todaysPlanning } as PlanningWidgetProps },
      { id: "homework", component: ListWidget, props: { title: t("upcoming_homework"), data: data.incomingHomework ?? [] } as ListWidgetProps }
    );
  }

  if (role === "student") {
    widgets.push(
      { id: "studentPlanning", component: PlanningWidget, props: { title: t("todays_schedule"), schedule: data.todaysPlanning } as PlanningWidgetProps },
      { id: "pendingAbsences", component: ListWidget, props: { title: t("pending_absences"), data: data.pendingAbsences ?? [] } as ListWidgetProps },
      { id: "homework", component: ListWidget, props: { title: t("upcoming_homework"), data: data.incomingHomework ?? [] } as ListWidgetProps },
      { id: "exams", component: ListWidget, props: { title: t("upcoming_exams"), data: data.incomingExamSessions ?? [] } as ListWidgetProps }
    );
  }

  return (
    <Row gutter={[16, 16]}>
      {widgets.map(({ id, component: WidgetComponent, props }) => (
        <Col key={id} xs={24} sm={12} md={8} lg={8}>
          <WidgetComponent {...props} />
        </Col>
      ))}
    </Row>
  );
};

export default RoleWidgets;
