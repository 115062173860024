import React from "react";
import { useParams } from "react-router-dom";
import { Spin, Card, Typography, Space } from "antd";
import { useClassMarks } from "../hooks/useClassMarks";
import MarkForm from "../components/MarkForm";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

const ClassMarksPage: React.FC = () => {
  const { classId = "", examSessionId = "" } = useParams<{ classId: string; examSessionId: string }>();
  const { t } = useTranslation();
  const { students, examSession, loading, submitting, handleMarkChange, handleSubmitMarks } =
    useClassMarks(classId, examSessionId);

  if (loading) return <Spin tip={t("loading")} />;

  return (
    <div className="page-wrapper">
      <div className="content">
        {examSession && (
          <Card className="mb-4 shadow-sm rounded p-4 bg-white">
            <Title level={4} className="mb-3 text-primary">
              {examSession.name}
            </Title>
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mb-2">
                <i className="ti ti-book me-2 text-primary"></i>
                <span>
                  <span className="fw-bold ">{t("subject")}:</span> {examSession.subject?.name ?? t("not_available")}
                </span>
              </div>
              <div className="d-flex align-items-center mb-2">
                <i className="ti ti-calendar me-2 text-primary"></i>
                <span>
                  <span className="fw-bold">{t("semester")}:</span> {examSession.semester}
                </span>
              </div>
              <div className="d-flex align-items-center">
                <i className="ti ti-clock me-2 text-primary"></i>
                <span>
                  <span className="fw-bold">{t("date_range")}:</span> {examSession.startDate} - {examSession.endDate}
                </span>
              </div>
            </div>
          </Card>

        )}
        <Card>
          <Title level={4}>
            {t("enter_marks")}
          </Title>
          <MarkForm
            students={students}
            submitting={submitting}
            handleMarkChange={handleMarkChange}
            handleSubmitMarks={handleSubmitMarks}
          />
        </Card>
      </div>
    </div>
  );
};

export default ClassMarksPage;
