import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { message, Spin } from "antd";
import AbsenceFilters from "./AbsenceFilters";
import AbsenceTable from "./AbsenceTable";
import useTableFilters from "../../../../components/Common/TableFilter/useTableFilters";
import { useAbsences } from "../hooks/useAbsences";
import { updateAbsence } from "../../../../services/absenceService";
import { Absence } from "../../../../types/absenceTypes";
import { formatToSQLDate } from "../../../../utils/dateUtils";

interface AbsenceListProps {
  role: "pedagogy-director" | "student" | "parent";
  studentId?: string;
}

const AbsenceList: React.FC<AbsenceListProps> = ({ role, studentId }) => {
  const { absences: originalAbsences, loading, refresh } = useAbsences({ role, studentId });
  const [filteredAbsences, setFilteredAbsences] = useState<Absence[]>([]);
  const { filters, setFilters, resetFilters } = useTableFilters({
    studentName: "",
    class: "",
    date: null,
    status: "",
  });

  const { t } = useTranslation();
  const applyFilters = (
  newFilters: any) => setFilters(newFilters);

  useEffect(() => {
    setFilteredAbsences(
      originalAbsences.filter((absence) => {
        const studentFullName = String(`${absence.student?.user?.firstName ?? ""} ${absence.student?.user?.lastName ?? ""}`).toLowerCase();
        const className = String(absence.student?.class?.name ?? "").toLowerCase();
        const absenceDate = formatToSQLDate(absence.dateTime);
        const status = String(absence.status); // Ensure it's a string before comparison
  
        return (
          (!filters.studentName || studentFullName.includes(String(filters.studentName).toLowerCase())) &&
          (!filters.class || className.includes(String(filters.class).toLowerCase())) &&
          (!filters.date || absenceDate === filters.date) &&
          (!filters.status || status === String(filters.status))
        );
      })
    );
  }, [filters, originalAbsences]);

  const handleUpdate = async (absenceId: string) => {
    try {
      await updateAbsence(absenceId, { status: "excused" });
      refresh();
      message.success(t("absence_marked_as_excused"));
    } catch {
      message.error(t("failed_to_update_absence"));
    }
  };

  return (
    <div className="card">
      {role === "pedagogy-director" && <AbsenceFilters filters={filters} onApply={applyFilters} onReset={resetFilters} />}
      <div className="card-body p-0 py-3">
        {loading ? <Spin size="large" className="d-flex justify-content-center my-5" /> : <AbsenceTable absences={filteredAbsences} role={role} onUpdateStatus={handleUpdate} />}
      </div>
    </div>
  );
};

export default AbsenceList;
