import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { Form, Select, TimePicker, Button, Space } from "antd";
import CustomModal from "../../../../../components/Common/CustomModal";
import { usePlanningModal } from "../../hooks/usePlanningModal";
import { findClassSubjectId } from "../../utils/planningUtils";
import { PlanningClassData, Schedule, UISchedule } from "../../../../../types/planningTypes";
import dayjs from "dayjs";
import { formatTimeToHHmm } from "../../../../../utils/dateUtils";

interface PlanningModalProps {
  visible: boolean;
  type: "add" | "edit";
  initialData?: Partial<UISchedule> | null;
  onSave: (data: Schedule) => void;
  onDelete?: (planningIds: string[], classId : string) => void;
  onClose: () => void;
  classes: PlanningClassData[];
  days: { label: string; value: string }[];
}

const PlanningModal: React.FC<PlanningModalProps> = ({
  visible,
  type,
  initialData,
  onSave,
  onDelete,
  onClose,
  classes,
  days,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { filteredSubjects, handleClassChange } = usePlanningModal({
    initialData,
    classes,
    form,
  });

  useEffect(() => {
    if (visible && initialData) {
      form.setFieldsValue({
        classId: initialData.classSubject?.class?.id || initialData.classId || undefined,
        subjectId: initialData.classSubject?.subject?.id || initialData.subjectId || undefined,
        dayOfTheWeek: initialData.dayOfTheWeek || undefined,
        timeRange: initialData.startTime && initialData.endTime
          ? [dayjs(initialData.startTime, "HH:mm"), dayjs(initialData.endTime, "HH:mm")]
          : undefined,
      });
    } else if (!visible) {
      form.resetFields();
    }
  }, [visible, initialData, type, form]);
  const handleSave = async (): Promise<void> => {
    try {
      const values = await form.validateFields();
      const classSubjectId = findClassSubjectId(classes, values.classId, values.subjectId);

      if (!classSubjectId) {
        console.error("classSubjectId could not be determined");
        throw new Error("CLASS_SUBJECT_ID_NOT_FOUND");
      }

      const scheduleToSave: Schedule = {
        ...initialData,
        classSubjectId,
        dayOfTheWeek: values.dayOfTheWeek,
        startTime: values.timeRange[0].format("HH:mm"),
        endTime: values.timeRange[1].format("HH:mm"),
      };

      onSave(scheduleToSave);
    } catch (err) {
      console.error("Validation Failed:", err);
    }
  };

  return (
    <CustomModal
      title={t("edit_schedule")}
      isOpen={visible}
      onClose={onClose}
      onSave={handleSave}
      onDelete={
        initialData?.id
          ? () => {
              onDelete?.([initialData.id ?? ""], initialData.classSubject?.class.id ?? "");
              return Promise.resolve();
            }
          : undefined
      }
      saveText={t("save_changes")}
      deleteText={t("remove_schedule")}
    >
      <Form 
        form={form} 
        layout="vertical"
      >
        <Form.Item
          name="classId"
          label={t("class")}
          rules={[{ required: true, message: t("class_required") }]}
        >
          <Select onChange={handleClassChange}>
            {classes.map((cls) => (
              <Select.Option key={cls.id} value={cls.id}>
                {cls.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="subjectId"
          label={t("subject")}
          rules={[{ required: true, message: t("subject_required") }]}
        >
          <Select>
            {filteredSubjects.map((sub) => (
              <Select.Option key={sub.subjectId} value={sub.subjectId}>
                {`${sub.subjectName} - ${sub.teacherName}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="dayOfTheWeek"
          label={t("day")}
          rules={[{ required: true, message: t("day_required") }]}
        >
          <Select>
            {days.map((day) => (
              <Select.Option key={day.value} value={day.value}>
                {t(day.label)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="timeRange"
          label={t("time_range")}
          rules={[
            { required: true, message: t("time_range_required") },
            {
              validator: (_, value) => {
                if (value && value[0] && value[1]) {
                  const diff = dayjs(value[1], "HH:mm").diff(dayjs(value[0], "HH:mm"), "minute");
                  if (diff < 60) {
                    return Promise.reject(t("range_must_be_at_least_1h"));
                  }
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <TimePicker.RangePicker
            format="HH:mm"
            showNow={false}
            hideDisabledOptions
            hourStep={1}
            placeholder={[t("start"), t("end")]}
            disabledTime={(date, type) => ({
              disabledHours: () => {
                const allowed = [8, 9, 10, 11, 12, 14, 15, 16, 17, 18];
                return Array.from({ length: 24 }, (_, hour) => hour).filter(hour => !allowed.includes(hour));
              },
              disabledMinutes: (selectedHour) => {
                const allowed = [0, 15, 30, 45];
                return Array.from({ length: 60 }, (_, minute) => minute).filter(minute => !allowed.includes(minute));
              },
            })}
          />
        </Form.Item>

      </Form>
    </CustomModal>
  );
};

export default PlanningModal;
