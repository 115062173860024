import React from "react";
import { useNavigate } from "react-router-dom";
import { Result, Button } from "antd";

const NoAccess: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-3);
  };

  const handleGoHome = () => {
    navigate("/dashboard");
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="w-100">
        <Result
          status="403"
          title={<span className="text-danger">Access Denied</span>}
          subTitle="Sorry, you do not have permission to view this page."
          extra={
            <div className="d-flex justify-content-center gap-3">
              <Button type="primary" size="large" onClick={handleGoBack}>
                🔙 Go Back
              </Button>
              <Button size="large" onClick={handleGoHome}>
                🏠 Go Home
              </Button>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default NoAccess;
