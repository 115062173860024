import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Button, message } from "antd";
import TaskList from "../components/TaskList";
import TaskModal from "../components/TaskModal";
import { useTasks } from "../hooks/useTasks";
import Loader from "../../../../components/Common/loader";
import { ClassTask } from "../../../../types/classTaskTypes";
import { useSelector } from "react-redux";
import TableFilter, { FilterField } from "../../../../components/Common/TableFilter/TableFilter";

const TeacherTaskPage: React.FC = () => {
  const teacherId = useSelector((state: any) => state.authSlice.user?.roleId);
  const [isModalVisible, setModalVisible] = useState(false);
  const [editingTask, setEditingTask] = useState<Partial<ClassTask> | null>(null);
  const [filters, setFilters] = useState<{ [key: string]: string | number | null }>({});

  const { tasks, loading, deleteTask, createTask, updateTask, fetchTasks } = useTasks({ teacherId });
  const { t } = useTranslation();

  // Define filter fields
  const filterFields: FilterField[] = [
    { key: "title", label: t("task_title"), type: "text" },
    { key: "subject", label: t("subject"), type: "text" },
    { key: "dueDate", label: t("due_date"), type: "date" },
  ];

  // Apply filters
  const handleApplyFilters = (newFilters: { [key: string]: string | number | null }) => {
    setFilters(newFilters);
  };

  // Reset filters
  const handleResetFilters = () => {
    setFilters({});
  };

  // Filter tasks dynamically
  const filteredTasks = tasks.filter((task) =>
    Object.entries(filters).every(([key, value]) => {
      if (!value) return true;
      return String(task[key as keyof ClassTask]).toLowerCase().includes(String(value).toLowerCase());
    })
  );

  // Handle Edit Task
  const handleEditTask = (taskId: string) => {
    const taskToEdit = tasks.find((task) => task.id === taskId);
    if (!taskToEdit) {
      message.error(t("task_not_found"));
      return;
    }
    setEditingTask(taskToEdit);
    setModalVisible(true);
  };

  // ✅ Handle Delete Task
  const handleDeleteTask = async (taskId: string) => {
    try {
      await deleteTask(taskId);
      message.success(t("task_deleted_successfully"));
      fetchTasks({ teacherId }); // Refresh tasks
    } catch (error) {
      message.error(t("failed_to_delete_the_task"));
    }
  };

  // ✅ Handle Create/Update Task Submission
  const handleSubmitTask = async (values: ClassTask) => {
    try {
      if (editingTask) {
        await updateTask(editingTask.id!, values);
        message.success(t("task_updated_successfully"));
      } else {
        await createTask({ ...values, teacherId });
        message.success(t("task_created_successfully"));
      }
      fetchTasks({ teacherId }); // Refresh tasks
      setModalVisible(false);
    } catch (error) {
      console.error("Error saving task:", error);
      message.error(t("failed_to_save_the_task"));
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
          <h1 className="page-title mb-1">{t("home_work")}</h1>
          <Button
            className="btn btn-primary d-flex align-items-center me-3"
            type="primary"
            onClick={() => {
              setEditingTask(null);
              setModalVisible(true);
            }}
          >
            <i className="ti ti-square-rounded-plus me-2" /> {t("create_task")}
          </Button>
        </div>

        <div className="card">
            <TableFilter fields={filterFields} filters={filters} onApply={handleApplyFilters} onReset={handleResetFilters} />
          <div className="card-body p-0 py-3">
            <TaskList tasks={filteredTasks} onEdit={handleEditTask} onDelete={handleDeleteTask} />
          </div>
        </div>

        <TaskModal
          visible={isModalVisible}
          onClose={() => setModalVisible(false)}
          onSubmit={handleSubmitTask}
          teacherId={teacherId}
          initialValues={editingTask || undefined}
        />
      </div>
    </div>
  );
};

export default TeacherTaskPage;
