import { useTranslation } from "react-i18next";
import React from "react";
import CustomModal from "../../../../components/Common/CustomModal";

interface TaskDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedTask: any | null;
  loading: boolean;
}

const TaskDetailsModal: React.FC<TaskDetailsModalProps> = ({ isOpen, onClose, selectedTask, loading }) => {
  const { t } = useTranslation();

  return (
    <CustomModal title={t("details")} isOpen={isOpen} onClose={onClose} loading={loading}>
      {selectedTask ? (
        <div className="table-responsive">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th className="w-25">{t("title")}</th>
                <td>{selectedTask.topic}</td>
              </tr>
              <tr>
                <th className="w-25">{t("subject")}</th>
                <td>{selectedTask.subject}</td>
              </tr>
              <tr>
                <th className="w-25">{t("description")}</th>
                <td>{selectedTask.description}</td>
              </tr>
              <tr>
                <th className="w-25">{t("due_date")}</th>
                <td>{selectedTask.dueDate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-muted">{t("no_task_selected")}</p>
      )}
    </CustomModal>
  );
};

export default TaskDetailsModal;
