import { AbilityBuilder, Ability } from "@casl/ability";

export type Actions = "create" | "read" | "update" | "delete" | "manage";
export type Modules = "Planner" | "Profile" | "Dashboard" | "Teacher" | "Subject" | "Student" | "Mark" | "ExamSession" | "Class" | "Guardian" | "Absence" | "Task" | "Message" | "all";

export type AppAbilities = [Actions, Modules];

export const Permissions = {
  admin: {
    manage: ["all", "Message"],
  },
  director: {
    manage: ["all", "Message"],
  },
  "pedagogy-director": {
    manage: ["Planner", "Subject", "Class", "Teacher", "Student", "Guardian", "Message"],
    read: ["Dashboard"],
    update: ["Absence"],
    create: ["ExamSession"],
  },
  teacher: {
    read: ["Planner", "ExamSession", "Dashboard"],
    update: ["Mark", "Task"],
    create: ["Absence", "Mark"],
    manage: ["Message"],
  },
  student: {
    read: ["Planner", "Mark", "Absence", "Task", "Dashboard"],
    manage: ["Message"],
  },
  guardian: {
    read: ["Planner", "Mark"],
    manage: ["Message"],
  },
  guest: {

  }
} as const;

export const defineAbilityFor = (role: keyof typeof Permissions): Ability<AppAbilities> => {
  const { can, cannot, build } = new AbilityBuilder<Ability<AppAbilities>>(Ability);

  const rolePermissions = Permissions[role] || {};

  // Iterate over the actions and subjects for the role
  Object.entries(rolePermissions).forEach(([action, subjects]) => {
    if (Array.isArray(subjects)) {
      subjects.forEach((subject) => {
        can(action as Actions, subject as Modules);
      });
    } else {
      console.error(`Subjects for action '${action}' are not an array`, subjects);
    }
  });

  return build();
};
