import { Link } from "react-router-dom";
import useFullscreen from "../hooks/useFullscreen";

const FullscreenToggle = () => {
  const { toggleFullscreen } = useFullscreen();

  return (
    <Link to="#" onClick={toggleFullscreen} className="btn btn-outline-light bg-white btn-icon me-1">
      <i className="ti ti-maximize" />
    </Link>
  );
};

export default FullscreenToggle;
