import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  getClasses,
  getAssignedStudents,
  assignStudentsToClass,
  addClass,
  updateClass,
  deleteClass,
} from "../../../../services/classService";
import { getStudents } from "../../../../services/studentService";
import { Modal, message } from "antd";
import { ClassData, StudentData } from "../../../../types/classTypes";

const useClassManagement = () => {
  const { t } = useTranslation();

  const [classes, setClasses] = useState<ClassData[]>([]);
  const [assignedStudents, setAssignedStudents] = useState<StudentData[]>([]);
  const [allStudents, setAllStudents] = useState<StudentData[]>([]);
  const [selectedStudents, setSelectedStudents] = useState<StudentData[]>([]);
  const [currentClassId, setCurrentClassId] = useState<string | null>(null);
  const [isAssignModalOpen, setAssignModalOpen] = useState(false);
  const [isAddEditModalOpen, setAddEditModalOpen] = useState(false);
  const [editingClass, setEditingClass] = useState<ClassData | null>(null);

  useEffect(() => {
    fetchClasses();
    fetchAllStudents();
  }, []);

  const fetchClasses = async () => {
    try {
      const response = await getClasses();
      setClasses(response);
    } catch (error) {
      message.error(t("failed_to_fetch_classes"));
    }
  };

  const fetchAllStudents = async () => {
    try {
      const response = await getStudents();
      setAllStudents(response.data);
    } catch (error) {
      message.error(t("failed_to_fetch_students"));
    }
  };

  const openAssignModal = async (classId: string) => {
    setCurrentClassId(classId);
    try {
      const response = await getAssignedStudents(classId);
      setAssignedStudents(response);
      setSelectedStudents(response);
      setAssignModalOpen(true);
    } catch (error) {
      message.error(t("failed_to_fetch_assigned_students"));
    }
  };

  const openAddEditModal = (classData?: ClassData) => {
    setEditingClass(classData || null);
    setAddEditModalOpen(true);
  };

  const handleAssignStudentsSave = async (classId: string, students: StudentData[]) => {
    if (!classId) {
      message.error(t("invalid_class_id"));
      return;
    }
    try {
      await assignStudentsToClass(classId, students.map((s) => s.id));
      message.success(t("students_assigned_successfully"));
      setAssignModalOpen(false);
      fetchClasses();
    } catch (error) {
      message.error(t("failed_to_assign_students"));
    }
  };
  const handleDeleteClass = async (classId: string) => {
    Modal.confirm({
      title: t("confirm_delete"),
      content: t("delete_class_warning"),
      okText: t("delete"),
      okType: "danger",
      cancelText: t("cancel"),
      async onOk() {
        try {
          await deleteClass(classId);
          message.success(t("class_deleted_successfully"));
          fetchClasses();
        } catch (error) {
          message.error(t("failed_to_delete_class"));
        }
      },
    });
  };

  const handleSaveClass = async (classData: Partial<ClassData>) => {
    try {
      if (classData.id) {
        await updateClass(classData.id, classData);
        message.success(t("class_updated_successfully"));
      } else {
        await addClass(classData);
        message.success(t("class_added_successfully"));
      }
      fetchClasses();
      setAddEditModalOpen(false);
    } catch (error) {
      message.error(t("failed_to_save_class"));
    }
  };

  return {
    classes,
    assignedStudents,
    allStudents,
    selectedStudents,
    currentClassId,
    isAssignModalOpen,
    isAddEditModalOpen,
    editingClass,
    openAssignModal,
    openAddEditModal,
    handleAssignStudentsSave,
    handleSaveClass,
    handleDeleteClass,
    setAssignModalOpen,
    setAddEditModalOpen
  };
};

export default useClassManagement;
