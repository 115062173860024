import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Load saved language from localStorage, default to "en"
const initialLanguage = localStorage.getItem("language") || "fr";

const languageSlice = createSlice({
  name: "language",
  initialState: {
    language: initialLanguage,
  },
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
      localStorage.setItem("language", action.payload); // Persist in localStorage
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
