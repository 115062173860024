import { useTranslation } from "react-i18next";
import React, { useState } from 'react';
import MessagesTable from '../components/MessagesTable';
import MessageDetail from '../components/MessageDetail';
import MessageSidebar from '../components/MessagesSideBar';
import ComposeMessage from '../components/ComposeMessage';
import { useMessages } from '../hooks/useMessages';
import { Message } from '../../../../types/messageTypes';
import { Spinner, Button } from 'react-bootstrap';

const MessagesPage: React.FC = () => {
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);
  const [selectedType, setSelectedType] = useState<'inbox' | 'sent'>('inbox');
  const [isComposing, setIsComposing] = useState<boolean>(false); // Track compose mode

  // Fetch messages using the custom hook
  const { messages, pagination, loading, error, setPage, setLimit } = useMessages(selectedType);

  const { t } = useTranslation();
  const renderSidebar = (
) => (
    <MessageSidebar
      selectedType={selectedType}
      onTypeChange={setSelectedType} // Switch between Inbox and Sent
    />
  );

  const renderMessagesTable = () => (
    <MessagesTable
      messages={messages}
      pagination={pagination}
      onPageChange={(page, pageSize) => {
        setPage(page);
        setLimit(pageSize);
      }}
      onMessageSelect={setSelectedMessage} // Select a message for detail view
    />
  );

  const renderMessageDetail = () => (
    <MessageDetail
      message={selectedMessage as Message}
      onBack={() => setSelectedMessage(null)} // Return to the table view
    />
  );

  const renderComposeEmail = () => (
    <ComposeMessage
      onBack={() =>  setIsComposing(false)} 
    />
  );

  const renderContent = () => {
    if (isComposing) {
      return renderComposeEmail(); // Render ComposeEmail if composing
    }

    if (loading) {
      return <Spinner animation="border" />;
    }

    if (error) {
      return <p className="text-danger">{error}</p>;
    }

    return selectedMessage ? renderMessageDetail() : renderMessagesTable();
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
          <h1 className="mb-1">{t("messaging")}</h1>
          <Button onClick={() => setIsComposing(true)} variant="primary">
            {t("compose")}
          </Button>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-12">{renderSidebar()}</div>
          <div className="col-lg-9 col-md-12">
            <div className="card">
              <div className="card-body">
                {renderContent()} {/* Render content based on state */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesPage;
