import axios from "./axiosInstance";
import { ClassTask, ClassTaskFilters, ClassTaskResponse } from "../types/classTaskTypes";

// Fetch all class tasks with optional filters
export const getClassTasks = async (
  filters: ClassTaskFilters
): Promise<ClassTaskResponse> => {
  const response = await axios.get<ClassTaskResponse>("/class-tasks", {
    params: filters,
  });
  return response.data;
};

// Fetch a single class task by ID
export const getClassTaskById = async (
  taskId: string
): Promise<ClassTask> => {
  const response = await axios.get<ClassTask>(`/class-tasks/${taskId}`);
  return response.data;
};

// Create a new class task
export const createClassTask = async (
  data: ClassTask
): Promise<ClassTask> => {
  const response = await axios.post<ClassTask>("/class-tasks", data);
  return response.data;
};

// Update an existing class task
export const updateClassTask = async (
  taskId: string,
  updateFields: Partial<ClassTask>
): Promise<void> => {
  const response = await axios.put<void>(`/class-tasks/${taskId}`, updateFields);
  return response.data;
};

// Delete a class task
export const deleteClassTask = async (taskId: string): Promise<void> => {
  const response = await axios.delete<void>(`/class-tasks/${taskId}`);
  return response.data;
};
