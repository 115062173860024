import i18n from "i18next"; // ✅ Import i18n instance
import axiosInstance from "./axiosInstance";
import Swal from "sweetalert2";
import { login,logout ,updateAccessToken, updateRefreshToken} from "../redux/slicers/authSlice";
import  {store}  from "../redux/store";
import { NavigateFunction } from 'react-router-dom';


// Login Service - Stores Access Token in Redux and Refresh Token in Local Storage
export const loginService = async (email: string, password: string) => {
  try {
    const response = await axiosInstance.post("/auth/login", { email, password });

    if (response.status === 200) {
      const { user, accessToken, refreshToken } = response.data;

      // Dispatch login action to store user and tokens in Redux
      store.dispatch(login({ user, accessToken, refreshToken }));

      return { user, accessToken, refreshToken };
    }
  } catch (error: any) {
    if (error.response && error.response.status !== 200) {
      Swal.fire({
        icon: "error",
        title: i18n.t("login_failed"),
        text: i18n.t("invalid_credentials"),
      });
    }
    throw error;
  }
};

// Refresh Access Token - Uses Refresh Token to Get a New Access Token
export const refreshAccessToken = async () => {
  try {
    // Get the current refresh token from Redux state
    const refreshToken = store.getState().authSlice.refreshToken;

    if (!refreshToken) {
      throw new Error("No refresh token found.");
    }

    // Call /auth/refresh with the refresh token in the request body
    const response = await axiosInstance.post("/auth/refresh", { refreshToken });

    if (response.status === 200) {
      const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data;

      // Update Redux with the new tokens
      store.dispatch(updateAccessToken(newAccessToken));
      store.dispatch(updateRefreshToken(newRefreshToken));

      return response.data;
    }
  } catch (error: any) {
    Swal.fire({
      icon: "error",
      title: i18n.t("session_expired"),
      text: i18n.t("please_login_again"),
    });
    store.dispatch(logout()); // Clear Redux state on error
    throw error;
  }
};

// Logout Service
export const logoutService = async (navigate: NavigateFunction) => {
  store.dispatch(logout()); // Clear authentication data
  navigate("/login"); // Redirect to login page
};
