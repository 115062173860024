import { useMemo } from "react";
import { useParams } from "react-router-dom";
import GenericPlanner from "../../../../feature-module/academic/planning/components/GenericPlanner/GenericPlanner";
import useGenericPlanner from "../../../../feature-module/academic/planning/hooks/useGenericPlanner";
import Loader from "../../../Common/loader";

const TeacherRoutine = () => {
  const { id } = useParams<{ id: string }>(); 
  const teacherIds = useMemo(() => (id ? [id] : []), [id]); // Memoize teacherIds
  const { data, isLoading, error } = useGenericPlanner({ role: "teacher", teacherIds });

  if (isLoading) return <Loader/>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="card">
      <div className="card-body">
        <GenericPlanner role="teacher" data={data} />
      </div>
    </div>
  );
};

export default TeacherRoutine;
