import { useTranslation } from "react-i18next";
import React from "react";
import { Card } from "antd";
import { PlannerData } from "../../hooks/useGenericPlanner";
import { days } from "../../../../../constants/planningContants";

interface GenericPlannerGridProps {
  role: string;
  data: PlannerData[];
}

const GenericPlannerGrid: React.FC<GenericPlannerGridProps> = ({ role, data }) => {
  const { t } = useTranslation();

  return (
    <div className="planner-horizontal-container">
      {days.map(({ label, value }) => (
        <div key={value} className="planner-day-column">
          {/* Translate day labels dynamically */}
          <h3 className="planner-day-heading">{t(label)}</h3>
          <div className="planner-schedules">
            {data
              .filter((slot) => slot.day === value) // Match backend day value with day.value
              .map((slot, index) => (
                <Card key={index} className="schedule-card">
                  {role === "student" || role === "parent" ? (
                    <>
                      <p><strong>{t("subject")}:</strong> {slot.subjectName || "N/A"}</p>
                      <p><strong>{t("teacher")}:</strong> {slot.teacherName || "N/A"}</p>
                    </>
                  ) : role === "teacher" ? (
                    <>
                      <p><strong>{t("class")}:</strong> {slot.className || "N/A"}</p>
                    </>
                  ) : null}
                  <p><strong>{t("time")}:</strong> {`${slot.startTime} - ${slot.endTime}`}</p>
                </Card>
              ))}
            {/* Display message if no schedules are available for this day */}
            {data.filter((slot) => slot.day === value).length === 0 && (
              <p className="no-schedule-text">{t("no_schedules_for_this_day")}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default GenericPlannerGrid;
