import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Card, List, Button, message } from "antd";
import AttendanceModal from "./AttendanceModal";
import { recordAttendance } from "../../../../services/absenceService";
import { Schedule } from "../../../../types/planningTypes";
import { formatTimeToHHmm } from "../../../../utils/dateUtils";

interface DailyScheduleProps {
  sessions: Schedule[];
  refreshSessions: () => void;
}

const DailySchedule: React.FC<DailyScheduleProps> = ({ sessions, refreshSessions }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedSession, setSelectedSession] = useState<Schedule | null>(null);

  const { t } = useTranslation();
  const handleOpenModal = (
session: Schedule) => {
    setSelectedSession(session);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedSession(null);
  };

  const handleSubmitAttendance = async (attendanceData: any[], session: Schedule) => {
    try {
      const allPresent = attendanceData.every((entry) => entry.status === "present");

      if (allPresent) {
        // Submit a single "All Present" record
        await recordAttendance(session.id ?? "", [
          {
            studentId: null,
            dateTime: `${new Date().toISOString().split("T")[0]}T${session.startTime}Z`,
            isAllPresent: true,
          },
        ]);
        message.success(t("attendance_recorded_all_students_are_present"));
      } else {
        // Submit only absent students
        const absentStudents = attendanceData.filter((entry) => entry.status === "absent");
        await recordAttendance(session.id ?? "", absentStudents);
        message.success(t("attendance_recorded_successfully"));
      }

      handleCloseModal();
      refreshSessions(); // Refresh schedule after submission
    } catch (err) {
      console.error("Error recording attendance:", err);
      message.error(t("failed_to_record_attendance"));
    }
  };

  const getStatus = (startTime: string, endTime: string): string => {
    const now = new Date();
    const currentMinutes = now.getHours() * 60 + now.getMinutes();
  
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);
    const startMinutes = startHour * 60 + startMinute;
    const endMinutes = endHour * 60 + endMinute;
  
    if (currentMinutes < startMinutes) return t("upcoming");
    if (currentMinutes > endMinutes) return t("completed");
    return t("ongoing");
  };
  
  const renderActionButton = (isOngoing: boolean, session: Schedule) => {
    if (!isOngoing) {
      return session.attendanceSubmitted ? (
        <Button className="btn-primary" type="default" disabled>
          <i className="ti ti-check me-2" /> {t("attendance_report_submitted")}
        </Button>
      ) : (
        <Button className="btn-primary" type="primary" disabled>
          <i className="ti ti-calendar-due me-2" /> {t("attendance_report_not_submitted")}
        </Button>
      );
    }
    return session.attendanceSubmitted ? (
      <Button className="btn-primary" type="default" disabled>
        <i className="ti ti-check me-2" /> {t("attendance_report_submitted")}
      </Button>
    ) : (
      <Button className="btn-primary" type="primary" onClick={() => handleOpenModal(session)}>
        <i className="ti ti-calendar-due me-2" /> {t("mark_attendance")}
      </Button>
    );
  };
  
  const renderStatusBadge = (status: string) => {
    const badgeClass = {
      [t("completed")]: "badge-soft-success",
      [t("ongoing")]: "badge-soft-info",
      [t("upcoming")]: "badge-soft-warning",
    }[status];
  
    return (
      <span className={`badge d-inline-flex align-items-center ${badgeClass}`}>
        <i className="ti ti-circle-filled fs-5 me-1" />
        {status}
      </span>
    );
  };
  
  return (
    <div className="content">
      <List
        grid={{ gutter: 12, column: 2 }}
        dataSource={sessions}
        renderItem={(session) => {
          const status = getStatus(session.startTime, session.endTime);
          const isOngoing = status === t("ongoing");
  
          return (
            <List.Item>
              <Card
                key={session.id}
                title={`${session.classSubject?.subject.name} - ${session.classSubject?.class.name}`}
                extra={renderActionButton(isOngoing, session)}
                className={`schedule-item ${status.toLowerCase()}`}
              >
                <p><strong>{t("time")}:</strong> {formatTimeToHHmm(session.startTime)} - {formatTimeToHHmm(session.endTime)}</p>
                <p><strong>{t("status")}:</strong> {renderStatusBadge(status)}</p>
              </Card>
            </List.Item>
          );
        }}
      />
  
      {selectedSession && (
        <AttendanceModal
          session={selectedSession}
          visible={modalVisible}
          onClose={handleCloseModal}
          onSubmit={(attendanceData) =>
            handleSubmitAttendance(attendanceData, selectedSession)
          }
        />
      )}
    </div>
  );
}  

export default DailySchedule;
