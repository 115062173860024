import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, DatePicker, message } from "antd";
import { all_routes } from "../../../router/all_routes";
import { addStudent, updateStudent, getStudent } from "../../../services/studentService";
import { GENDER_OPTIONS } from "../../../constants/userConstants";
import CommonSelect from "../../Common/CommonSelect";
import { parseSQLDate, formatToSQLDate } from "../../../utils/dateUtils";
import CommonFormLayout from "../../Common/CommonFormLayout";

const StudentForm = () => {
  const { t } = useTranslation();
  const routes = all_routes;
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>(); 
  const studentId = id ?? "";
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [form] = Form.useForm();
  const translatedGender = GENDER_OPTIONS.map(gender => ({
    ...gender,
    label: t(gender.label), // Translate dynamically
  }));

  useEffect(() => {
    if (studentId) {
      setIsEdit(true);
      fetchStudentData(studentId);
    }
  }, [studentId]);

  const fetchStudentData = async (id: string) => {
    try {
      const response = await getStudent(id);
  
      const initialData = {
        user: {
          firstName: response.data.user?.firstName || "",
          lastName: response.data.user?.lastName || "",
          email: response.data.user?.email || "",
          phoneNumber: response.data.user?.phoneNumber || "",
          birthDate: response.data.user?.birthDate ? parseSQLDate(response.data.user.birthDate) : null,
          gender: response.data.user?.gender || null, // ✅ Ensure gender is correctly assigned
          cityOfBirth: response.data.user?.cityOfBirth || "",
          zipCode: response.data.user?.zipCode || "",
          address: response.data.user?.address || "",
          city: response.data.user?.city || "",
        },
      };
  
      form.setFieldsValue(initialData);
  
      // ✅ Force a state update to ensure form values are available before rendering
      setTimeout(() => {
        form.setFieldsValue({ user: { ...initialData.user } });
      }, 0);
    } catch (error) {
      message.error(t("failed_to_load_student_data"));
    }
  };
  
  const handleSubmit = async (values: any) => {
    try {
      const payload = {
        ...values,
        user: {
          ...values.user,
          birthDate: values.user.birthDate ? formatToSQLDate(values.user.birthDate) : null,
        },
      };

      if (isEdit) {
        await updateStudent(studentId, payload);
        message.success(t("student_updated_successfully"));
      } else {
        await addStudent(payload);
        message.success(t("student_added_successfully"));
      }

      navigate(routes.studentList);
    } catch (error) {
      message.error(t("failed_to_save_student_data"));
    }
  };

  // ✅ Define the single "Personal Information" section
  const sections = [
    {
      title: t("personal_information"),
      content: (
        <div className="row">
          <div className="col-md-4">
            <Form.Item label={t("first_name")} name={["user", "firstName"]} rules={[{ required: true, message: t("first_name_required") }]}>
              <Input placeholder={t("enter_first_name")} />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label={t("last_name")} name={["user", "lastName"]} rules={[{ required: true, message: t("last_name_required") }]}>
              <Input placeholder={t("enter_last_name")} />
            </Form.Item>
          </div>
          <div className="col-md-4">
          <Form.Item label={t("gender")} name={["user", "gender"]} rules={[{ required: true, message: t("gender_required") }]}>
            <CommonSelect
              options={translatedGender}
              defaultValue={translatedGender.find(opt => opt.value === form.getFieldValue(["user", "gender"]))}
              onChange={(option) => form.setFieldsValue({ user: { ...form.getFieldsValue().user, gender: option?.value ?? "" } })}
            />
          </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label={t("email")} name={["user", "email"]} rules={[{ required: true, type: "email", message: t("email_required") }]}>
              <Input placeholder={t("enter_email")} />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label={t("phone_number")} name={["user", "phoneNumber"]} rules={[{ required: true, message: t("phone_number_required") }]}>
              <Input placeholder={t("enter_phone_number")} />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label={t("birth_date")} name={["user", "birthDate"]} rules={[{ required: true, message: t("birth_date_required") }]}>
              <DatePicker className="form-control datetimepicker" format="YYYY-MM-DD" placeholder={t("select_date")} />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label={t("city_of_birth")} name={["user", "cityOfBirth"]} rules={[{ required: true, message: t("city_of_birth_required") }]}>
              <Input placeholder={t("enter_city_of_birth")} />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label={t("address")} name={["user", "address"]} rules={[{ required: true, message: t("address_required") }]}>
              <Input placeholder={t("enter_address")} />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label={t("zip_code")} name={["user", "zipCode"]} rules={[{ required: true, message: t("zip_code_required") }]}>
              <Input placeholder={t("enter_zip_code")} />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label={t("city")} name={["user", "city"]} rules={[{ required: true, message: t("city_required") }]}>
              <Input placeholder={t("enter_city")} />
            </Form.Item>
          </div>
        </div>
      ),
    },
  ];

  return (
    <CommonFormLayout
      title={isEdit ? t("edit_student") : t("add_student")}
      form={form}
      onFinish={handleSubmit}
      sections={sections}
      cancelRoute={routes.studentList}
      submitText={t("add_student")}
      isEdit={isEdit}
    />
  );
};

export default StudentForm;
