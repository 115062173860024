import { useTranslation } from "react-i18next";
import React from "react";
import { InputNumber, Button, Table } from "antd";
import { StudentData } from "../../../../types/classTypes";

interface MarkFormProps {
  students: StudentData[];
  submitting: boolean;
  handleMarkChange: (studentId: string, value: string) => void;
  handleSubmitMarks: () => void;
}

const MarkForm: React.FC<MarkFormProps> = ({
  students,
  submitting,
  handleMarkChange,
  handleSubmitMarks,
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("student_name"),
      dataIndex: "name",
      key: "name",
      render: (_: any, record: StudentData) =>
        `${record.user.firstName} ${record.user.lastName}`,
    },
    {
      title: t("mark"),
      dataIndex: "mark",
      key: "mark",
      render: (_: any, record: StudentData) => (
        <InputNumber
          min={0}
          max={20}
          onChange={(value) =>
            handleMarkChange(record.id, value?.toString() ?? "")
          }
          disabled={submitting}
        />
      ),
    },
  ];

  return (
    <div>
      <Table dataSource={students} columns={columns} rowKey="id" pagination={false} />
      <div style={{ marginTop: "20px", textAlign: "right" }}>
        <Button
          type="primary"
          className="btn-primary"
          onClick={handleSubmitMarks}
          loading={submitting}
          disabled={students.length === 0}
        >
          {t("submit")}
        </Button>
      </div>
    </div>
  );
};

export default MarkForm;
