import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useTasks } from "../hooks/useTasks";
import { useSelector } from "react-redux";
import CalendarCard from "../components/CalendarCard";
import TaskListCard from "../components/TaskListCard";
import TaskDetailsModal from "../components/TaskDetailsModal";

const StudentTaskPage: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any | null>(null);
  const studentId = useSelector((state: any) => state.authSlice.user?.roleId);

  // Fetch tasks using hook
  const { tasks, loading, error, setFilters } = useTasks({ studentId });

  const { t } = useTranslation();
  const handleDateSelect = (
date: any) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    setSelectedDate(formattedDate);
    setFilters({
      studentId,
      ...(formattedDate && { startDate: formattedDate, endDate: formattedDate }),
    });
  };

  const handleTaskClick = (task: any) => {
    setSelectedTask(task);
    setModalVisible(true);
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <h1 className="page-title mb-3">{t("homework_diary")}</h1>
        <div className="row">
          <CalendarCard onDateSelect={handleDateSelect} />
          <TaskListCard selectedDate={selectedDate} tasks={tasks} loading={loading} error={error} onTaskClick={handleTaskClick} />
        </div>
      </div>

      {/* Homework Details Modal */}
      <TaskDetailsModal isOpen={modalVisible} onClose={() => setModalVisible(false)} selectedTask={selectedTask} loading={loading} />
    </div>
  );
};

export default StudentTaskPage;
