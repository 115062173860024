import React, { createContext, useContext, useEffect, useState } from "react";
import { Ability, AbilityBuilder } from "@casl/ability";
import { AppAbilities, defineAbilityFor,Permissions} from "./permissions";

export const AbilityContext = createContext<Ability<AppAbilities>>(new Ability());

interface AbilityProviderProps {
  role: Role;
  children: React.ReactNode;
}
export type Role = keyof typeof Permissions;

export const AbilityProvider: React.FC<AbilityProviderProps> = ({ role, children }) => {
  const [ability, setAbility] = useState<Ability<AppAbilities>>(new Ability());

  useEffect(() => {
    const userAbility = defineAbilityFor(role);
    setAbility(userAbility);
  }, [role]);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

export const useAbility = () => {
  return useContext(AbilityContext);
};

export const createAbility = (role: Role): Ability<AppAbilities> => {
  const { can, cannot, build } = new AbilityBuilder<Ability<AppAbilities>>(Ability);

  const permissions = Permissions[role] || {};

  Object.entries(permissions).forEach(([action, subjects]) => {
    // Ensure `subjects` is an array before calling forEach
    if (Array.isArray(subjects)) {
      subjects.forEach((subject) => {
        can(action as any, subject); // Cast `action` as `any` if needed
      });
    } else {
      console.error(`Subjects for action '${action}' are not an array`, subjects);
    }
  });

  return build();
};
