import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { Form, message, Spin } from "antd";
import { useTasks } from "../hooks/useTasks";
import TaskForm from "./TaskForm";
import { formatToSQLDate } from "../../../../utils/dateUtils";
import CustomModal from "../../../../components/Common/CustomModal";
import { ClassTask } from "../../../../types/classTaskTypes";

interface TaskModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (values: Partial<ClassTask>) => void;
  teacherId: string;
  initialValues?: Partial<ClassTask>;
}

const TaskModal: React.FC<TaskModalProps> = ({
  visible,
  onClose,
  onSubmit,
  teacherId,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const { classes, fetchClasses, loading } = useTasks({ teacherId });
  const isEditing = !!initialValues;
  const { t } = useTranslation();

  useEffect(() => {
    if (visible) {
      fetchClasses(teacherId);
    }
  }, [visible, teacherId]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const formattedValues = {
        ...values,
        date: values.date ? formatToSQLDate(values.date.toDate()) : undefined,
        dueDate: values.dueDate ? formatToSQLDate(values.dueDate.toDate()) : undefined,
      };

      // Compare modified fields with initialValues
      const modifiedFields = Object.keys(formattedValues).reduce((changes, key) => {
        const typedKey = key as keyof ClassTask;
        if (formattedValues[typedKey] !== initialValues?.[typedKey]) {
          changes[typedKey] = formattedValues[typedKey];
        }
        return changes;
      }, {} as Partial<ClassTask>);

      if (Object.keys(modifiedFields).length === 0) {
        message.info(t("no_changes_detected"));
        return;
      }

      onSubmit(modifiedFields);
      form.resetFields();
    } catch (err) {
      console.error("Form validation failed:", err);
    }
  };

  return (
    <CustomModal
      title={isEditing ? t("edit_task") : t("create_task")}
      isOpen={visible}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      onSave={handleSave}
      saveText={isEditing ? t("update") : t("create")}
    >
      {loading ? <Spin /> : <TaskForm form={form} initialValues={initialValues} classes={classes} isEditing={isEditing} />}
    </CustomModal>
  );
};

export default TaskModal;
