import axios from "./axiosInstance";
import { Schedule } from "../types/planningTypes";

export const createPlanning = async (schedule: Schedule): Promise<Schedule> => {
  const response = await axios.post("/plannings", schedule);
  return response.data;
};

export const updatePlanning = async (schedule: Schedule): Promise<Schedule> => {
  const response = await axios.put(`/plannings`, schedule);
  return response.data;
};

export const getSchedules = async (
  classIds?: string[],
  teacherIds?: string[]
): Promise<Schedule[]> => {
  const params: any = {};

  if (classIds?.length) {
    params.classIds = classIds;
  }

  if (teacherIds?.length) {
    params.teacherIds = teacherIds;
  }

  const response = await axios.get<Schedule[]>("/plannings", { params });
  return response.data;
};


export const deletePlanning = async (planningIds: string[]): Promise<void> => {
  const response = await axios.delete("/plannings", { data: { planningIds } });
  return response.data;
};