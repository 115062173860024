import { useCallback, useEffect, useState } from "react";
import { ExamSession } from "../../../../types/markTypes";
import { getTeacherExamSessions } from "../../../../services/markService";
import { message } from "antd";

export const useTeacherExamSessions = (teacherId: string) => {
    const [examSessions, setExamSessions] = useState<ExamSession[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);
  
    const fetchExamSessions = useCallback(async () => {
      setIsLoading(true);
      try {
        const response = await getTeacherExamSessions(teacherId);
        setExamSessions(response.data);
      } catch (err) {
        setError(err as Error);
        message.error("Failed to fetch teacher's exam sessions.");
      } finally {
        setIsLoading(false);
      }
    }, [teacherId]);
  
    useEffect(() => {
      fetchExamSessions();
    }, [fetchExamSessions]);
  
return { examSessions, isLoading, error, fetchExamSessions };
};