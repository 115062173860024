import { useTranslation } from "react-i18next";
import i18n from "i18next";
import React from "react";
import { Modal, Typography } from "antd";

interface CustomModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSave?: () => Promise<void>;
  onDelete?: () => Promise<void>;  // ✅ NEW: Delete function
  children: React.ReactNode;
  loading?: boolean;
  loadingDelete?: boolean; // ✅ NEW: Loading state for delete action
  saveText?: string;
  deleteText?: string; // ✅ NEW: Custom delete button text
}

const CustomModal: React.FC<CustomModalProps> = ({
  title,
  isOpen,
  onClose,
  onSave,
  onDelete,
  children,
  loading = false,
  loadingDelete = false, // Default to false
  saveText = i18n.t("save"),
  deleteText = i18n.t("delete"), // Default delete text
}) => {
  const { t } = useTranslation();
  
  return (
    <Modal
      title={<Typography.Title level={3}>{title}</Typography.Title>}
      open={isOpen}
      onCancel={onClose}
      footer={null} 
      zIndex={1050}
      destroyOnClose
    >
      {children}

      {/* Custom footer buttons */}
      <div className="modal-footer d-flex align-items-center mt-4">
        {/* ✅ DELETE BUTTON (Only appears if onDelete exists) */}
        {onDelete && (
          <button
            type="button"
            className="btn btn-outline-danger me-auto"
            onClick={onDelete}
            disabled={loadingDelete}
            aria-label={loadingDelete ? "Deleting..." : deleteText}
          >
            {loadingDelete ? t("deleting") : deleteText}
          </button>
        )}

        {/* CANCEL & SAVE BUTTONS */}
        <div className="ms-auto">
          <button
            type="button"
            className="btn btn-secondary me-2"
            onClick={onClose}
            aria-label={t("cancel")}
          >
            {t("cancel")}
          </button>

          {onSave && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={onSave}
              disabled={loading}
              aria-label={loading ? t("saving...") : saveText}
            >
              {loading ? t("saving") : saveText}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
