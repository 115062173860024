import { useTranslation } from "react-i18next";
import React from "react";
import { Button } from "antd";
import Table from "../../../../components/Common/dataTable/index";
import { Absence } from "../../../../types/absenceTypes";
import { formatDateTime, formatTimeDuration } from "../../../../utils/dateUtils";

interface AbsenceTableProps {
  absences: Absence[];
  role: "pedagogy-director" | "student" | "parent";
  onUpdateStatus: (absenceId: string) => void;
}

const AbsenceTable: React.FC<AbsenceTableProps> = ({ absences, role, onUpdateStatus }) => {
  const canEdit = role === "pedagogy-director";
  const { t } = useTranslation();

  const columns = [
    {
      title: t("student_name"),
      dataIndex: "studentName",
      key: "studentName",
      render: (_: any, record: Absence) => `${record.student?.user?.firstName || ""} ${record.student?.user?.lastName || ""}`,
      hidden: role !== "pedagogy-director",
    },
    {
      title: t("class"),
      dataIndex: "class",
      key: "class",
      render: (_: any, record: Absence) => record.student?.class?.name || "-",
      hidden: role !== "pedagogy-director",
    },
    {
      title: t("subject"),
      dataIndex: "subject",
      key: "subject",
      render: (_: any, record: Absence) => record.planning?.classSubject?.subject?.name || "-",
    },
    {
      title: t("date"),
      dataIndex: "dateTime",
      key: "dateTime",
      render: (dateTime: string) => formatDateTime(dateTime),
    },
    {
      title: t("duration"),
      dataIndex: "duration",
      key: "duration",
      render: (_: any, record: Absence) => formatTimeDuration(record.duration ?? 0),
    },
    // { title: t("reason"), dataIndex: "reason", key: "reason" },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <span className={`badge ${status === "excused" ? "badge-soft-success" : "badge-soft-warning"}`}>
          <i className="ti ti-circle-filled fs-5 me-1" />
          {t(status)}
        </span>
      ),
    },
    ...(canEdit
      ? [
          {
            title: t("actions"),
            key: "actions",
            render: (_: any, record: Absence) =>
              record.status !== "excused" ? (
                <Button type="link" className="text-primary" onClick={() => onUpdateStatus(record.id ?? "")}>
                  {t("mark_excused")}
                </Button>
              ) : (
                "-"
              ),
          },
        ]
      : []),
  ];

  return <Table columns={columns.filter((col) => !col.hidden)} dataSource={absences} pagination={true} />;
};

export default AbsenceTable;
