import { useState, useEffect, useCallback } from "react";
import { Modal, message } from "antd";
import { useTranslation } from "react-i18next";
import {
  ExamSession,
  CreateExamSessionPayload,
  UpdateExamSessionPayload,
} from "../../../../types/markTypes";
import { ClassData } from "../../../../types/classTypes";
import {
  getExamSessions,
  createExamSession,
  updateExamSession,
  deleteExamSession,
  getTeacherExamSessions,
  publishMarksByExamSession,
} from "../../../../services/markService";
import { getClassesBySubject } from "../../../../services/subjectService";

export const useExamSessions = () => {
  const { t } = useTranslation();
  const [examSessions, setExamSessions] = useState<ExamSession[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [classes, setClasses] = useState<ClassData[]>([]);
  const [isClassesLoading, setIsClassesLoading] = useState<boolean>(false);
  const [classesError, setClassesError] = useState<Error | null>(null);
  const [isPublishing, setIsPublishing] = useState<boolean>(false);

  const fetchExamSessions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getExamSessions();
      setExamSessions(response.data);
    } catch (err) {
      setError(err as Error);
      message.error(t("fetch_exam_sessions_error"));
    } finally {
      setIsLoading(false);
    }
  }, [t]);

  const fetchTeacherExamSessions = useCallback(
    async (teacherId: string) => {
      setIsLoading(true);
      try {
        const response = await getTeacherExamSessions(teacherId);
        setExamSessions(response.data);
      } catch (err) {
        setError(err as Error);
        message.error(t("fetch_teacher_exam_sessions_error"));
      } finally {
        setIsLoading(false);
      }
    },
    [t]
  );

  const fetchClassesBySubject = useCallback(
    async (subjectId: string) => {
      setIsClassesLoading(true);
      try {
        const response = await getClassesBySubject(subjectId);
        setClasses(response.data);
      } catch (err) {
        setClassesError(err as Error);
        message.error(t("fetch_classes_error"));
      } finally {
        setIsClassesLoading(false);
      }
    },
    [t]
  );

  const handleCreateExamSession = useCallback(
    async (payload: CreateExamSessionPayload) => {
      setIsSubmitting(true);
      try {
        await createExamSession(payload);
        await fetchExamSessions();
        message.success(t("exam_session_created"));
      } catch (err) {
        setError(err as Error);
        message.error(t("exam_session_create_error"));
      } finally {
        setIsSubmitting(false);
      }
    },
    [t]
  );

  const handleUpdateExamSession = useCallback(
    async (payload: UpdateExamSessionPayload) => {
      setIsSubmitting(true);
      try {
        await updateExamSession(payload);
        message.success(t("exam_session_updated"));
        await fetchExamSessions();
      } catch (err) {
        message.error(t("exam_session_update_error"));
      } finally {
        setIsSubmitting(false);
      }
    },
    [fetchExamSessions, t]
  );

  const handleDeleteExamSession = useCallback(
    async (id: string) => {
      Modal.confirm({
        title: t("delete_exam_session_title"),
        content: t("delete_exam_session_confirmation"),
        okText: t("delete_confirm"),
        okType: "danger",
        cancelText: t("cancel"),
        async onOk() {
          try {
            await deleteExamSession(id);
            message.success(t("exam_session_deleted"));
            fetchExamSessions();
          } catch (error) {
            message.error(t("exam_session_delete_error"));
          }
        },
      });
    },
    [fetchExamSessions, t]
  );

  const handlePublishMarks = useCallback(
    async (examSessionId: string) => {
      setIsPublishing(true);
      try {
        await publishMarksByExamSession(examSessionId);
        message.success(t("marks_published"));
        setExamSessions((prev) =>
          prev.map((session) =>
            session.id === examSessionId
              ? {
                  ...session,
                  classes: session.classes?.map((cls) => ({
                    ...cls,
                    submissionStatus: "published",
                  })),
                }
              : session
          )
        );
      } catch (error) {
        message.error(t("marks_publish_error"));
      } finally {
        setIsPublishing(false);
      }
    },
    [t]
  );

  useEffect(() => {
    fetchExamSessions();
  }, [fetchExamSessions]);

  return {
    examSessions,
    classes,
    isLoading,
    isClassesLoading,
    isSubmitting,
    isPublishing,
    error,
    classesError,
    fetchExamSessions,
    fetchTeacherExamSessions,
    fetchClassesBySubject,
    handleCreateExamSession,
    handleUpdateExamSession,
    handleDeleteExamSession,
    handlePublishMarks,
  };
};
