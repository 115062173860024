import { useTranslation } from "react-i18next";
import React from "react";
import AbsenceList from "../components/AbsenceList";
import { useSelector } from "react-redux";

const MyAbsencesPage: React.FC = () => {
  const studentId = useSelector((state: any) => state.authSlice.user?.roleId);
  const { t } = useTranslation();

  return (
    <div className="page-wrapper">
        <div className="content">
        <h1 className="mb-3">{t("my_absences")}</h1>
        <AbsenceList role="student" studentId={studentId} />
        </div>
    </div>
  );
};

export default MyAbsencesPage;
