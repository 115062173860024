import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { Link, generatePath } from "react-router-dom";
import { all_routes } from "../../../../router/all_routes";
import { ExamSession } from "../../../../types/markTypes";

interface TeacherExamSessionListProps {
  examSessions: ExamSession[];
}

const TeacherExamSessionList: React.FC<TeacherExamSessionListProps> = ({ examSessions }) => {

  const { t } = useTranslation();
  const getStatusIcon = (
submissionStatus: string) => {
    switch (submissionStatus) {
      case "submitted":
        return <i className="ti ti-lock-square-rounded text-danger" style={{ marginRight: 8 }}></i>; // Locked (Red)
      case "incomplete":
        return <i className="ti ti-alert-circle text-warning" style={{ marginRight: 8 }}></i>; // Warning (Yellow)
      default:
        return <i className="ti ti-edit-circle text-success" style={{ marginRight: 8 }}></i>; // Editable (Green)
    }
  };

  const columns = [
    { title: t("session"), dataIndex: "name", key: "name" },
    { title: t("semester"), dataIndex: "semester", key: "semester" },
    { title: t("start_date"), dataIndex: "startDate", key: "startDate" },
    { title: t("end_date"), dataIndex: "endDate", key: "endDate" },
    {
      title: t("subject"),
      key: "subject",
      render: (_: any, record: ExamSession) =>
        record?.subject ? record.subject.name : record?.subSubject ? record.subSubject.name : "N/A",
    },
    {
      title: t("classes"),
      key: "classes",
      render: (_: any, record: ExamSession) =>
        record?.classes?.length
          ? record.classes.map((cls) => cls.name).join(", ")
          : "N/A",
    },
    {
      title: t("status"),
      key: "status",
      render: (_: any, record: ExamSession) => (
        record?.classes?.length
        ? record.classes.map((cls) => (
            <Link 
              to={generatePath(all_routes.ExamSessionClass, { 
                examSessionId: record.id, 
                classId: cls.id 
              })} 
              key={cls.id} 
              className="d-block"
              style={{ pointerEvents: cls.submissionStatus === "submitted" ? "none" : "auto", opacity: cls.submissionStatus === "submitted" ? 0.5 : 1 }}
            >
              {getStatusIcon(cls.submissionStatus)}
              {cls.name}
            </Link>
        ))
        : "N/A"
      ),
    },
  ];

  return (
      <Table dataSource={examSessions} columns={columns} rowKey="id" />
  );
};

export default TeacherExamSessionList;
