import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../../router/all_routes";
import { logoutService } from "../../../../services/authService";
import { useTranslation } from "react-i18next";

const UserDropdown = () => {
  const routes = all_routes;
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logoutService(navigate);
  };
  const { t } = useTranslation();
  return (
    <div className="dropdown ms-1">
      {/* User Icon as Dropdown Toggle */}
      <Link to="#" className="btn btn-outline-light bg-white btn-icon me-1" data-bs-toggle="dropdown">
        <i className="ti ti-user" /> {/* Profile Icon Instead of Image */}
      </Link>

      {/* Dropdown Menu */}
      <div className="dropdown-menu dropdown-menu-end">
        {/* Logout Option */}
        <Link className="dropdown-item d-inline-flex align-items-center p-2" to={routes.login} onClick={handleLogout}>
          <i className="ti ti-logout me-2" /> {t('logout')}
        </Link>
      </div>
    </div>
  );
};

export default UserDropdown;
