import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "../locales/en.json";
import frTranslation from "../locales/fr.json";
import {store }from "../redux/store"; // Import Redux store

const getLanguage = () => store.getState().language.language; // Get language from Redux

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      fr: { translation: frTranslation },
    },
    lng: getLanguage(), // Use Redux state for initial language
    debug: false,
    fallbackLng: "fr",
    interpolation: { escapeValue: false },
  });

export default i18n;
